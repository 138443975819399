import {getCookie,setCookie} from '../services/cookieService'
import {get,set} from '../services/localStorageService'
import {formValidator} from '../services/formValidator'
import {isSet,getterSetter,connectionCheck} from '../utils/common'
import {otpService} from '../services/otpService'
import {dataService} from '../services/dataService'
import {mailService} from '../services/mailService'
import {urlBuilderService} from '../services/urlBuilderService'
import {loginService} from '../services/loginService'
import {windowWidthService} from '../services/windowWidthService'
import {requirementService} from '../services/requirementService'
import {eventTriggerQH,eventTriggeranalatyic} from '../services/gtmService'
require('./otpController');
window.EnquiryController = function () {
    var factory = {};
    var error = {};
    error['otpRequired'] = "OTP is required.";
    error['otpLength'] = "Enter 4 digit otp";
    error['otpVerify'] = "Please Wait...Verifying OTP";
    error['otpExp'] = "Wrong or expired OTP, Please re-enter";
    var deferred = $.Deferred();
    factory.projectSnippet = globals.projectSnippet ? JSON.parse(atob(globals.projectSnippet)) : {};
    factory.builderSnippet = globals.builderSnippet || '';

    factory.prefer_city_id = getCookie('prefer_city_id') ? getCookie('prefer_city_id') : "" 
    factory.ePhone = get("autofill_no") || '';
    factory.enquiryForm = {};
    factory.enquiryForm.enquiry_phone = {};
    factory.enquiryForm.invalid = {};
    factory.mobile = get("autofill_no") || '';
    factory.email = get("autofill_email") || '';
    factory.enquiry_name = get("autofill_contactname") || '';
    factory.isUserLog = false;
    factory.counter = 0;
    factory.toggleClassForMSite = '';
    $("#enquiry_phone").val(factory.mobile);
    factory.callMe = function () {
        if(isNumberValid()) {
            getLoggedInUser();
            if (factory.counter == 0) {
                if( getCookie('prefer_city_id') != '28' ) {
                    submitRequest();
                }
                if(globals.pageType != 'builder'){
                    getCategory();
                }
            }
            if (!isValid()) {
                factory.enquiryForm.enquiry_phone.dirty = true;
                factory.enquiryForm.enquiry_phone.invalid = true;
                return;
            }
            if (!factory.isUserLog) {
                otpController.init.call(this, buildOtpPayload());
                showOTPBox();
                factory.showSuccessMsg = false;
                factory.otpNeeded = true;

            } else {
                factory.otpNeeded = false;
                factory.showSuccessMsg = true;
                showSuccessDiv();
                if( getCookie('prefer_city_id') === '28'){
                    submitRequest();
                }
            }
            factory.counter++;
        }
    };

    function getLoggedInUser() {
        
        if (getterSetter.loginstatus) {
            var details = getterSetter.get('loginData');
            details = details.data.user;
            factory.isUserLog = true;
        } else {
            factory.isUserLog = false;
        }
    }

    factory.verifyOTP = function(){
        if (isSet(otpService.getOtpVariable().otpNumber)) {
            otpController.verifyOTP().done(function (data) {
                if (otpService.getOtpVariable().showVerifiedSuccessSection){
                    showSuccessDiv();
                }
            }).fail(function (data) {
                showErrorMsg(data);
            });
        }
    }
    function getCategory() {
      var cityId = (globals.pageType == 'builder')?factory.prefer_city_id:factory.projectSnippet.address.cityId;
      var source = (globals.pageType == 'builder')?"IM_INTERESTED_IN_BUILDER":"IM_INTERESTED_IN_PROJECT";
      if(globals.pageType=='builder'){
        var attributesMap = {"city":[globals.builderSnippet.address.city],"builder_id":[globals.builderSnippet.id]};
      }
      else{
        var attributesMap = {"locality":[globals.projectSnippet.address.locality],"minPrice":[globals.projectSnippet.priceRange.low],"maxPrice":[globals.projectSnippet.priceRange.high]};
      }
      var alertPayload = {
          "email": factory.email,
          "mobile": factory.mobile,
          "alertType": "buy",
          "alertEntityType": 0,
          "attributesMap":attributesMap,
          "cityId": cityId,
          "alertCreateType": "implicit",
          "source": source,
          "metaCatGlobalId":20,
          "subCatGlobalId":33,
          "alertStatus":1
        };
      $.ajax({
          url: "/homes/re2/createAlertNew?aj=1",
          type: "POST",
          data: alertPayload,
          success: function(){

          }
      });
    }
    function isNumberValid () {
        var $form = $("form[name='enquiryForm']");
        var mobileElement = $form.find(":input[name='enquiry_phone']");
        var mobileValid = formValidator.isMobileNoValid.call(mobileElement);
        return mobileValid;

    };

    function showSuccessDiv(){
        $('.call-me-wrap .bgcolorchnage').addClass('animation');
        $('.call-me-wrap .awesome').removeClass('hide');
        $('.call-me-wrap .interstedin').addClass('hide');
        $('.call-me-wrap .mobileenters b').html('+'+ $('.stdcode select').val()+' - '+factory.mobile);
        $('.call-me-wrap .mobileenters').removeClass('hide');
        $('.call-me-wrap .input-design-ch').addClass('hide');
        $('.call-me-wrap button').addClass('hide');
        $('.call-me-wrap #otpEnquiryBox').removeClass('show');
        $('.call-me-wrap #otpEnquiryBox').addClass('hide');
        $('.call-me-wrap #resendOtpEnquiry').addClass('hide');
        $('.call-me-wrap #otpEnquiryBox .errormsg').hide();
        $('.call-me-wrap .enquiry-city').hide();
        
    }

    function showErrorMsg(data){
        $('.call-me-wrap #otpEnquiryBox .errormsg').text("OTP verification failed");
    }


    function showOTPBox(){
        var $formBox = $('#otpEnquiryBox');
        $formBox.removeClass('hide');
        $formBox.addClass('show');
        $('#showinputbox').addClass('pullleft');
        otpController.setOtpErrorClass(error.otpRequired);
    };

    factory.showInputBox = function(){
        $('#showinputbox').removeClass('pullleft');
        $('#otpEnquiryBox').removeClass('show');
    };

    factory.isNumber = function (evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    factory.setMobileNo = function(){
        var x = document.getElementById("enquiry_phone");
        x.value = x.value;
        factory.mobile = x.value;
    }

    factory.toggleMSiteClass = function(){

        if(!isSet(factory.hideEnquiryClassForMsite)){
            $('.call-me-wrap').removeClass('hidden-xs');
            $('.call-me-wrap').removeClass('hidden-sm');
        }
        else{
            $('.call-me-wrap').addClass('hidden-xs');
            $('.call-me-wrap').addClass('hidden-sm');
        }
        factory.hideEnquiryClassForMsite = factory.hideEnquiryClassForMsite ? '' : 'hidden-xs hidden-sm';
    }
    function submitRequest() {
        factory.isCallRequest = true;
        if (isValid()) {
            $.ajax({
                url: "/homes/re2/sendenquiry?aj=1",
                type: "POST",
                data: buildLeadPayload(),
                success: function (data) {
                    deferred.resolve(data);
                    factory.isCallRequest = false;
                    var gtmData = contactDetailPayload();
                    //gtmService.gtmContactButton('ENQUIRY_SUCCESS',gtmData);
                    sendSmsToUser();
                    var gtmData = {
                        'event':'interested_callback',
                        'mobile': factory.mobile
                    };
                    gtmData['user_db_id'] = getCookie('__u');
                    if(connectionCheck()){
                        eventTriggerQH(gtmData);
                    } else {
                        eventTriggeranalatyic(gtmData);
                    }
                    //sendMailSmsToCampaignUsers();
                    //dataService.saveLead(buildLeadPayload());
                },
                error: function (data) {
                    deferred.reject(err);
                }
            });

            return deferred.promise;
        }
    };

    function isValid() {
        return !(!factory.mobile || !factory.prefer_city_id);
    }
    function buildLeadPayload() {
        var leadPayload = {
            'userName': factory.enquiry_name,
            'phone': $('.stdcode select').val()+'-'+ factory.mobile,
            'emailId': factory.email,
            'channel': windowWidthService.channel
        };

        if (isSet(factory.projectSnippet) && isSet(factory.projectSnippet.id)) {
            leadPayload.source = "IM_INTERESTED_IN_PROJECT";
            leadPayload.projectId = factory.projectSnippet.id;
            leadPayload.cityId = factory.projectSnippet.address.cityId;
            if (isSet(factory.projectSnippet.priceRange)) {
                leadPayload.priceMin = factory.projectSnippet.priceRange.low || '';
                leadPayload.priceMax = factory.projectSnippet.priceRange.high || '';
                leadPayload.builderId = factory.builderSnippet.id || '';
                factory.name = factory.projectSnippet.name || '';
            }
        }
        else if (factory.builderSnippet.id) {
            leadPayload.source = "IM_INTERESTED_IN_BUILDER";
            leadPayload.builderId = factory.builderSnippet.id;
            leadPayload.cityId = factory.prefer_city_id;
            factory.name = '';
        }
        requirementService.setTrafficGoalConversionParameters(leadPayload);
        return leadPayload;
    }

    function sendSmsToUser() {
        var smsPayload = {
            "id": factory.name || '',
            "enquiry_mob": factory.mobile,
            "enquiryType": "IM_INTERESTED_IN_BUILDER" 
        };

        dataService.enquiryProcess(smsPayload);
    }

    function buildOtpPayload() {
        return {
            sellerEmail: factory.email,
            sellerMobile: factory.mobile,
            sellerName: '',
            userdata: '',
            page: "REPLY"
        };
    }

    var contactDetailPayload = function() {
            var gtmData = {
                'userName': factory.enquiry_name,
                'phone': $('.stdcode select').val()+'-'+ factory.mobile,
                'emailId': factory.email,
                'channel': windowWidthService.channel
            };
            return gtmData;
        };

    function sendMailSmsToCampaignUsers() {
        var data = dataService.projectCampaignUserDetails;

        if (data) {
            fireMailSmsToCampaignUsers(data.data);
        } else {
            if (factory.projectSnippet) {
                dataService.fetchProjectCampaignUserDetails(factory.projectSnippet.id).then(function (response) {
                    if (isSet(response) && isSet(response.response)) fireMailSmsToCampaignUsers(JSON.parse(response.response).data);
                });
            }
        }
    }

    function fireMailSmsToCampaignUsers(data) {
        var projectUrl = null;
        var mailPayload;
        var phone;
        var budget = 0;
        var lookingFor;
        var address = factory.projectSnippet.address || {};
        var unitType = "";

        lookingFor = factory.projectSnippet.name + " ";
        lookingFor += (address.locality ? address.locality : "") + (address.city ? " " + address.city : "");
        lookingFor = lookingFor.replace(/^\s+|\s+$/g,'');

        projectUrl = urlBuilderService.getProjectUrl({
            "data": {
                "projectName": factory.projectSnippet.name,
                "locality": factory.projectSnippet.address.locality,
                "city": factory.projectSnippet.address.city,
                "projectId": factory.projectSnippet.id,
            }
        });

        var mailObj = {
            "title": factory.projectSnippet.name,
            "redirect": projectUrl,
            "userName": factory.enquiry_name || "",
            "userMobile": factory.selectedIsdCode + factory.ePhone,
            "userEmail": factory.enquiry_email || "",
            "time": (new Date()).toString(),
            "lookingFor": lookingFor,
            "budget": budget
        }

        _.each(data, function(obj) {
            phone = obj.leadPhoneNumber;
            if (phone && phone.length > 10) phone = phone.slice(phone.length - 10);
            mailObj.toEmail = obj.leadEmail;
            mailObj.toName = obj.leadName;
            mailObj.toPhone = phone;
            mailPayload = buildBuilderMailPayload(mailObj);

            if (mailPayload) {
                dataService.sendMail(mailPayload);
            }
            if (phone) {
                dataService.sendSms(buildBuilderSmsPayload(phone, obj.leadName));
            }
        });
    }

    function buildBuilderMailPayload(obj) {
        var payload = {
            "to": obj.toEmail,
            "emailFrom": "RegularQueue",
            "track": "yes",
            "notification_engine_email_context": 23,
            "from_address": "service@quikr.com",
            "from_name": "Quikr Service",
            "replyto": "service@quikr.com",
            "subject": "Good news! we have a lead for you",
            "body": mailService.getPropertyCampaignUserContent(obj)
        };

        return payload;
    }


        function buildBuilderSmsPayload(sendTo, senderName) {
            var sms_content = "Hello " + ((senderName != 'null' && senderName.trim().length>0)  ? senderName : " ") + ",\n\nYou have received an unverified lead " + (factory.projectSnippet.name ? "for Project " + factory.projectSnippet.name : " ") + " from QuikrHomes.\nPlease call +" + $('.stdcode select').val()+'-'+ factory.mobile + " immediately.";

            var smsPayload = {
                "mobile": ["" + sendTo],
                "sms_content": sms_content,
                "smsFrom": "HighPriorityQueue",
                "notification_engine_sms_context": 23
            };

            return smsPayload;
        };

        factory.submitOtp = function(){
            var jsonData = {
                "numEmail" : factory.mobile,
                "password" : '',
                "otpCheck" : 1,
                "otpClientId" : '',
                "page": 'REPLY'
            };
            submitAfterLogin(jsonData);
            set("autofill_no", factory.mobile);
        };

        function submitAfterLogin (jsonData) {
            var emailMatchInAcc,mobileMatchInAcc;
            gaService.addEvent('Button-RealestateResponsiveUI','Mobile_As_Login',"PAP_submit_OTP");
            loginService.login(jsonData)
                .then(function (result) {
                    result = JSON.parse(result)
                    if(result.error == false && result.status == 'AUTH_SUCCESS') {
                        $(document).trigger('authEvent', result.data);
                        getterSetter.set( 'loginData', result);
                        getterSetter.set( 'loginstatus', true);
                        factory.userdata = result.data.user;
                        factory.loginId = result.data.id;
                        factory.login_status = true;
                        factory.showSuccessMsg = true;
                        if ('undefined' !== typeof PUB_SUB) {
                            PUB_SUB.emit('do_login', {name: setLoginName(factory.userdata)});
                        }
                        if("userMobile" in result.data.user) {
                            factory.userMobileList = result.data.user.userMobile;
                            for(var i = 0; i < factory.userMobileList.length; i++) {
                                if(factory.mobile == factory.userMobileList[i].mobileNumber) {
                                    factory.mobile = factory.userMobileList[i].mobileNumber;
                                    mobileMatchInAcc = true;
                                }
                            }
                        }
                        if(!mobileMatchInAcc) {
                            factory.mobile = factory.userMobileList.length > 0 ? factory.userMobileList[0].mobileNumber : ePhone;
                        }
                        showSuccessDiv();
                        if( getCookie('prefer_city_id') === '28'){
                            submitRequest();
                        }
                    }
                    else {
                        factory.alertMessage = result.data.response;
                        showErrorMsg(result.data);
                    }
                });
        }



    return factory;

}();

function setLoginName(user){
    if(user.firstName){
        return user.firstName;
    }else if(user.email){
        return user.email.substring(0, user.email.indexOf("@"));
    }else{
        return '';
    }
}

$(function(){

    $(document).on("click", "#callMe", EnquiryController.callMe);
    // custom login event
    window.addEventListener('loginEvent', function (e) {
        EnquiryController.isUserLog = true;
    }, false);


    $( document ).on( "citySelectPopup", function(event,data) {
        EnquiryController.prefer_city_id = data.id;
    });

    // need to remove angular form below snippet
    /*	if (data.user) {
     $scope.isUserLog = true;
     if (data.user.email) {
     $scope.email = data.user.userEmail;
     userData.email = data.user.email;
     }
     if (data.user.mobile) {
     $scope.userMobileList = data.user.userMobile || [];
     $scope.ePhone = data.user.mobile;
     userData.phone = data.user.mobile;
     }
     if (data.user.firstName) {
     userData.name = data.user.firstName;
     }
     }*/

})
