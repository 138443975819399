import {isSet,ImageLoader, isMobile} from '../utils/common'
require('../services/gaService');
window.Lightbox = function () {

    var Lightbox = {};
    Lightbox.dfpAdVisibale = false;
    Lightbox.prevImageIndex = 0;
    Lightbox.nextImageIndex = 0;

    /**
     * Array of all images to be shown in the lightbox (not `Image` objects).
     * @type     {Array}
     * @name     images
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.images = [];
    Lightbox.enableDate=false;
    /**
     * @param    {*} image An element in the array of images.
     * @return   {String} The URL of the given image.
     * @type     {Function}
     * @name     getImageUrl
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.getImageUrl = function (image, key) {
        return image[key];
    };

    Lightbox.getEnableDate = function(){
        return Lightbox.enableDate;
    }

    Lightbox.setEnableDate = function(value){
        Lightbox.enableDate = value;
    }

    Lightbox.handelOpenModal = function(event){
        var date=$(event.target).data('date');
        var imageLength=Lightbox.images.length;
        
        if(date){
            Lightbox.setEnableDate(date);
        } else {
            Lightbox.enableDate = false;
        }

        var anchorHide = $(".hide-anchor");
        if(imageLength>1){
            if(anchorHide.hasClass('hide')){
                anchorHide.removeClass('hide');
            }
        }else { 
            if (!anchorHide.hasClass('hide')) {
                 anchorHide.addClass('hide');
            }
        }
    }
   

    /**
     * @param    {*} image An element in the array of images.
     * @return   {String} The caption of the given image.
     * @type     {Function}
     * @name     getImageCaption
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.getImageCaption = function (image) {
        return image.caption;
    };

    /**
     * The index in the `Lightbox.images` aray of the image that is currently
     *   shown in the lightbox.
     * @type     {Number}
     * @name     index
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.index = -1;
    Lightbox.lastfinalindex=0;
    Lightbox.initfinalindex=0;

    // set the configurable properties and methods, the defaults of which are
    // defined above
    // Lightbox.templateUrl = this.templateUrl;

    // Lightbox.getImageUrl = this.getImageUrl;
    // Lightbox.getImageCaption = this.getImageCaption;
    // Lightbox.calculateImageDimensionLimits = this.calculateImageDimensionLimits;
    // Lightbox.calculateModalDimensions = this.calculateModalDimensions;

    /**
     * Whether keyboard navigation is currently enabled for navigating through
     *   images in the lightbox.
     * @type     {Boolean}
     * @name     keyboardNavEnabled
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.keyboardNavEnabled = false;

    /**
     * The image currently shown in the lightbox.
     * @type     {*}
     * @name     image
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.image = {};

    /**
     * The UI Bootstrap modal instance. See {@link
        *   http://angular-ui.github.io/bootstrap/#/modal}.
     * @type     {Object}
     * @name     modalInstance
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.modalInstance = null;

    /**
     * The URL of the current image. This is a property of the service rather
     *   than of `Lightbox.image` because `Lightbox.image` need not be an
     *   object, and besides it would be poor practice to alter the given
     *   objects.
     * @type     {String}
     * @name     imageUrl
     * @memberOf bootstrapLightbox.Lightbox
     */

    /**
     * The optional caption of the current image.
     * @type     {String}
     * @name     imageCaption
     * @memberOf bootstrapLightbox.Lightbox
     */

    /**
     * Open the lightbox modal.
     * @param    {Array}  newImages An array of images. Each image may be of
     *   any type.
     * @param    {Number} newIndex  The index in `newImages` to set as the
     *   current image.
     * @return   {Object} The created UI Bootstrap modal instance.
     * @type     {Function}
     * @name     openModal
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.openModal = function (e,newImages, newIndex, key, encode, lindexArray , lindexindCount,lindexType,vtourUrl ) { 
        // Hide the video element
        const elVideo = document.querySelector('.lightbox-image-container video');
        elVideo.pause();
        elVideo.style.display = 'none';

        if (isSet(vtourUrl)) {
            Lightbox.vtourUrl = vtourUrl;
            Lightbox.keyboardNavEnabled = true;
            Lightbox.showVirtualTour(vtourUrl);
            return Lightbox.modalInstance;
        }

        if(Lightbox.dfpAdVisibale){
            Lightbox.hideDfpSlot();
        }
        if (isSet(newImages)) {
            if(encode){
                Lightbox.images = JSON.parse(atob(newImages));
            }else if( typeof newImages == 'string'){
                 Lightbox.images = JSON.parse(newImages)
            }else {
                Lightbox.images = newImages
            }
        
            Lightbox.key = key;
            Lightbox.keyboardNavEnabled = true;

            Lightbox.hideVirtualTour();
            Lightbox.handelOpenModal(e);

            if(lindexArray && lindexType){
                if(typeof lindexArray == 'string'){
                    Lightbox.lindexArray = JSON.parse(lindexArray);
                }else{
                    Lightbox.lindexArray = lindexArray;
                }
                Lightbox.lindexType = lindexType;
                Lightbox.lindexCount = Lightbox.lindexArray[Lightbox.lindexType];
                Lightbox.lindexindCount = lindexindCount;
            }
            Lightbox.lastfinalindex=0;
            Lightbox.initfinalindex=0;
            Lightbox.setImage(newIndex);

            $('#lightbox').on('hidden.bs.modal', function () {
                Lightbox.images = [];
                Lightbox.index = 1;
                Lightbox.image = {};
                Lightbox.imageUrl = null;
                Lightbox.imageCaption = null;

                Lightbox.keyboardNavEnabled = false;

                // complete any lingering loading bar progress
            });
            if(e.target.parentElement.classList.contains('floorpln-img')) {
                document.querySelectorAll("#lightfloorTab")[0].click();
            }
            return Lightbox.modalInstance;
        }
    };

    Lightbox.showVirtualTour = function(vtourUrl) {
        Lightbox.vtourVisible = true;
        $('.lightbox-nav').hide();
        $('.lightbox-image-container img').hide();
        $('.lightbox-image-container iframe').attr('src', vtourUrl).show();
    };

    Lightbox.hideVirtualTour = function() {
        Lightbox.vtourVisible = false;
        $('.lightbox-image-container iframe').hide();
        $('.lightbox-image-container img').show();
        $('.lightbox-nav').show();
    };

    Lightbox.showVideo = function(_, videoUrl, caption, tab) {
        gaService.addEvent('RealEstate', "QH_VAP_Project_Gallery_FeaturedVideo", 'QH_VAP_Project_Gallery_FeaturedVideo_Click');
        if (isSet(videoUrl)) {
            Lightbox.videoUrl = videoUrl;
            Lightbox.keyboardNavEnabled = true;

            const elContainer = document.querySelector('.lightbox-image-container');
            const elVideo = elContainer.querySelector('video');

            // Open the contact popup upon finish
            elVideo.addEventListener('ended', () => {
                document.getElementById('lightboxContact').click();
            });

            // Unhide the video
            elVideo.style.display = 'block';
            
            if (elVideo.src == videoUrl) {
                elVideo.play();
            } else {
                elVideo.setAttribute('src', videoUrl);
                if(globals.pageType == 'project' && isSet(globals.projectSnippet) && isSet(globals.projectSnippet.name)) {
                    elVideo.setAttribute('alt', globals.projectSnippet.name +' video tour. know floor plan, price, amenities, and more');
                }
                elVideo.setAttribute('controls', '');
                elVideo.setAttribute('autoplay', '');
            }

            // Focus on the video
            elVideo.focus();

            // For video hide the navigation arrows and image
            $('.lightbox-nav').hide();
            $(elContainer).find('img').hide();
            $('.lgtlist li').removeClass('active');

            // Activate the tab
            $('.lgtlist li').siblings('#' + tab).addClass('active');
            elContainer.querySelector('.img-label').innerText = caption || 'Video';
        
            return Lightbox.modalInstance;
        }
    };

    /**
     * This method can be used in all methods which navigate/change the
     *   current image.
     * @param    {Number} newIndex The index in the array of images to set as
     *   the new current image.
     * @type     {Function}
     * @name     setImage
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.setImage = function (newIndex) {
        if (!(newIndex in Lightbox.images)) {
            throw 'Invalid image.';
        }

        var success = function () {
            Lightbox.index = newIndex;
            Lightbox.image = Lightbox.images[Lightbox.index];
        };

        if(Lightbox.images[newIndex] == null) {
            document.getElementById('lightboxContact').click(); 
            return false;
        }
        var imageUrl = Lightbox.getImageUrl(Lightbox.images[newIndex], Lightbox.key);
        var imageDate = Lightbox.getImageUrl(Lightbox.images[newIndex], 'udate');
        var imagecaption = Lightbox.getImageUrl(Lightbox.images[newIndex], 'caption');
        imagecaption = (imagecaption)?imagecaption.replace('_',' '):'Image';
        var captionData = '';
        if(Lightbox.lindexArray && Lightbox.lindexType){
            captionData = imagecaption+' '+(Lightbox.lindexindCount+1)+'/'+Lightbox.lindexCount;
        }
        // load the image before setting it, so everything in the view is updated
        // at the same time; otherwise, the previous image remains while the
        // current image is loading
        var imageLoader = new ImageLoader();
        imageLoader.load(imageUrl, imagecaption).then(function () {
            success();

            // set the url and caption
            Lightbox.imageUrl = imageUrl;
            if(globals.pageType == 'project' && isSet(globals.projectSnippet) && isSet(globals.projectSnippet.name)) {
                Lightbox.imagecaption = globals.projectSnippet.name + ' - '+ imagecaption;
            } else {
                Lightbox.imagecaption = imagecaption;
            }
            $('#lightbox').modal();
            $('.lightbox-image-container img').attr('src', Lightbox.imageUrl);
            $('.lightbox-image-container img').attr('alt', Lightbox.imagecaption);
            var checkDate = Lightbox.getEnableDate();
            if(checkDate && imageDate){
                var imgDate=imageDate.split(" ");
                $('.lightbox-image-container .img-label').html('Construction Status as on: '+imgDate['2']+' '+imgDate['1']+' '+imgDate['5']);         
            }else{
                $('.lightbox-image-container .img-label').html(captionData);
            }
        }, function () {
            success();

            // blank image
            Lightbox.imageUrl = '//:0';
            // use the caption to show the user an error
            Lightbox.imageCaption = 'Failed to load image';
        });
    };

    /**
     * Navigate to the first image.
     * @type     {Function}
     * @name     firstImage
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.firstImage = function () {
        Lightbox.setImage(0);
    };
    /**
     * Navigate to the previous image.
     * @type     {Function}
     * @name     prevImage
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.prevImage = function () {

        // If Page is VAP and Images are present
        if(globals.pageType == 'VAP' && Lightbox.images.length > 0 && !isMobile()){
           
            // If DFP is not visible before
            if(!Lightbox.dfpAdVisibale){
                // If Images are more or equal to 10 than show DFP after 10th image
                if(Lightbox.images.length >= 10 && Lightbox.index == 9){
                    Lightbox.prevImageIndex = 9;
                    Lightbox.nextImageIndex = 10;
                    Lightbox.showDfpSlot()
                    return ''
                }
                if(Lightbox.images.length < 10 && Lightbox.index == Lightbox.images.length + 1){
                    Lightbox.showDfpSlot()
                    Lightbox.prevImageIndex = Lightbox.images.length + 1
                    return ''
                }
            }else {
                if(Lightbox.dfpAdVisibale){
                    Lightbox.index = Lightbox.prevImageIndex
                    Lightbox.hideDfpSlot()
                }
            }
        }

        if(Lightbox.vtourUrl && Lightbox.vtourVisible && Lightbox.index == (Lightbox.images.length - 1)) {
            Lightbox.hideVirtualTour(Lightbox.vtourUrl);
        }

        if(Lightbox.lindexArray){
            Lightbox.lastfinalindex=0;
            if(Lightbox.initfinalindex==0){
                Lightbox.lindexindCount--;
                if(Lightbox.lindexindCount == -1 && Lightbox.index != 0){
                    Lightbox.lindexType = Lightbox.previousKey(Lightbox.lindexArray,Lightbox.lindexType);
                    if(Lightbox.lindexType!='undefined'){
                        Lightbox.lindexindCount = Lightbox.lindexArray[Lightbox.lindexType]-1;
                        Lightbox.lindexCount = Lightbox.lindexArray[Lightbox.lindexType];

                        $(".lightheading").removeClass('active');
                        if(Lightbox.lindexType == 'locality') {
                            $("#lightlocalityChildTab").addClass('active');
                        } else {
                            $("#light"+Lightbox.lindexType+"Tab").addClass('active');
                        }
                    }
                }

                if(Lightbox.index != 0 && Lightbox.lindexType!='undefined') {
                   Lightbox.setImage((Lightbox.index - 1 + Lightbox.images.length) %
                    Lightbox.images.length);
                }else{
                    Lightbox.initfinalindex++;
                    document.getElementById('lightboxContact').click(); return false;
                    Lightbox.lindexindCount++;
                }
            } else{
                document.getElementById('lightboxContact').click(); return false;
                Lightbox.lindexindCount++;
            }
        }else {
            Lightbox.setImage((Lightbox.index - 1 + Lightbox.images.length) %
            Lightbox.images.length);
        }
    };

    /**
     * Navigate to the next image.
     * @type     {Function}
     * @name     nextImage
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.nextImage = function () {

        // If Page is VAP and Images are present
        if(globals.pageType == 'VAP' && Lightbox.images.length > 0 && !isMobile()){
            // If DFP is not visible before
            if(!Lightbox.dfpAdVisibale){
                // If Images are more or equal to 10 than show DFP after 10th image
                if(Lightbox.images.length >= 10 && Lightbox.index == 9){
                    Lightbox.prevImageIndex = 9;
                    Lightbox.nextImageIndex = 10;
                    Lightbox.showDfpSlot()
                    return ''
                }
                if(Lightbox.images.length < 10 && Lightbox.index == Lightbox.images.length - 1){
                    Lightbox.showDfpSlot()
                    Lightbox.nextImageIndex = Lightbox.images.length - 1
                    return ''
                }
            }else {
                if(Lightbox.dfpAdVisibale){
                    Lightbox.index = Lightbox.nextImageIndex
                    Lightbox.hideDfpSlot()
                }
            }
        }

        if(Lightbox.vtourUrl && !Lightbox.vtourVisible && Lightbox.index == (Lightbox.images.length - 2)) {
            Lightbox.showVirtualTour(Lightbox.vtourUrl);
            return;
        }

        if(Lightbox.lindexArray){
            Lightbox.initfinalindex=0;
            if(Lightbox.lastfinalindex==0){
                Lightbox.lindexindCount++;
                if(Lightbox.lindexindCount == Lightbox.lindexCount && parseInt(Lightbox.index + 1) != Lightbox.images.length){
                    Lightbox.lindexType = Lightbox.nextKey(Lightbox.lindexArray,Lightbox.lindexType);
                    if(Lightbox.lindexType!='undefined'){
                        Lightbox.lindexindCount = 0;
                        Lightbox.lindexCount = Lightbox.lindexArray[Lightbox.lindexType];

                        $(".lightheading").removeClass('active');
                        if(Lightbox.lindexType == 'locality') {
                            $("#lightlocalityChildTab").addClass('active');
                        } else {
                            $("#light"+Lightbox.lindexType+"Tab").addClass('active');
                        }
                    }
                }

                if(parseInt(Lightbox.index + 1)!=Lightbox.images.length && Lightbox.lindexType!='undefined') {
                    Lightbox.setImage((Lightbox.index + 1) % Lightbox.images.length);
                }else{
                    // Featured video is the last tab
                    var elFeaturedVideoTab = $('.lightbox-topnav').find('#lightFeaturedVideoTab');
                    if (elFeaturedVideoTab) {
                        $(".lightheading").removeClass('active');
                        elFeaturedVideoTab.click();
                    } else {
                        Lightbox.lastfinalindex++;
                        document.getElementById('lightboxContact').click(); return false;
                        Lightbox.lindexindCount--;
                    }
                }
            }else {
                // Featured video is the last tab
                var elFeaturedVideoTab = $('.lightbox-topnav').find('#lightFeaturedVideoTab');
                if (elFeaturedVideoTab) {
                    $(".lightheading").removeClass('active');
                    elFeaturedVideoTab.click();
                } else {
                    document.getElementById('lightboxContact').click(); return false;
                    Lightbox.lindexindCount--;
                }
            }
        }else {
            Lightbox.setImage((Lightbox.index + 1) % Lightbox.images.length);
        }
    };

    Lightbox.closemodalBackdrop = function(){
        // Pause any video that is playing
        const elContainer = document.querySelector('.lightbox-image-container');
        const elVideo = elContainer.querySelector('video');
        elVideo.pause();

        // Finally close the modal
        $('.modal-backdrop').remove();
        $(".lightheading").removeClass('active');
        $($(".lightheading")[0]).addClass('active');
    }
    Lightbox.nextKey = function(obj,key){
        return Lightbox.keyAt(Object.keys(obj),Object.keys(obj).indexOf(key) + 1);
    }

    Lightbox.previousKey = function(obj,key){
        return Lightbox.keyAt(Object.keys(obj),Object.keys(obj).indexOf(key) - 1);
    }


    Lightbox.keyAt = function(obj,key){
        return obj[key];
    }

    /**
     * Navigate to the last image.
     * @type     {Function}
     * @name     lastImage
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.lastImage = function () {
        Lightbox.setImage(Lightbox.images.length - 1);
    };

    /**
     * Call this method to set both the array of images and the current image
     *   (based on the current index). A use case is when the image collection
     *   gets changed dynamically in some way while the lightbox is still
     *   open.
     * @param {Array} newImages The new array of images.
     * @type     {Function}
     * @name     setImages
     * @memberOf bootstrapLightbox.Lightbox
     */
    Lightbox.setImages = function (newImages) {
        Lightbox.images = newImages;
        Lightbox.setImage(Lightbox.index);
    };

    Lightbox.showDfpSlot = function () {
        $('#lightBoxDfpSlot').show()
        $('.lightbox-image-container').hide()
        if(document.getElementById('Desktop_QH_VAP_6').innerHTML.trim() == "") {
            QDFP.getAdForDivId('Desktop_QH_VAP_6', 1);
        }
        Lightbox.dfpAdVisibale = true
    }

    Lightbox.hideDfpSlot = function () {
        $('#lightBoxDfpSlot').hide()
        $('.lightbox-image-container').show()
        Lightbox.dfpAdVisibale = false
    }

    Lightbox.selectImageTab =  function (e) {
        e.preventDefault();
        $(this).siblings().removeClass('active').end().addClass('active');
    }
    // Bind the left and right arrow keys for image navigation. This event
    // handler never gets unbinded. Disable this using the `keyboardNavEnabled`
    // flag. It is automatically disabled when the target is an input and or a
    // textarea. TODO: Move this to a directive.


    //Event handlers
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;
 
    const gestureZone = document.getElementsByClassName('lightbox-nav')[0];
 
    gestureZone.addEventListener('touchstart', function(event) {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
    }, false);
 
    gestureZone.addEventListener('touchend', function(event) {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;
        handleGesture();
    }, false); 
 
    function handleGesture() {
        if (touchendX < touchstartX) {
             console.log('Swiped Left');
            let method = "nextImage"; 
            Lightbox[method]();
        }
        
        if (touchendX > touchstartX) {
            console.log('Swiped right');
            let method = "prevImage"; 
            Lightbox[method]();
        }
    }

    $(document).on('keydown', function (event) {
        if (!Lightbox.keyboardNavEnabled) {
            return;
        }

        // method of Lightbox to call
        var method = null;

        switch (event.which) {
            case 39: // right arrow key
                method = 'nextImage';
                break;
            case 37: // left arrow key
                method = 'prevImage';
                break;
        }

        if (method !== null && ['input', 'textarea'].indexOf(event.target.tagName.toLowerCase()) === -1) {
            // the view doesn't update without a manual digest
            setTimeout(function () {
                Lightbox[method]();
            }, 1);

            event.preventDefault();
        }
    });
    $('.lightbox-topnav').on('click', '.lgtlist li', Lightbox.selectImageTab);
    return Lightbox;
}();



