import {otpService} from './otpService'
export const loginService = function() {

        var loginSvc = {};

        loginSvc.source = function () {
            return  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ? 'MOBILE' : 'DESK';
        }

        loginSvc.init = function () {
            loginSvc.loggedInData = {};
            loginSvc.loggedInData.resolveAuthDiffer = 'Invoke';
        }

        loginSvc.init();

        loginSvc.login = function (jsonData) {

            var loginData = loginJsonData(jsonData);

            var deferred = $.Deferred();

            $.ajax({
                url: "/homes/re2/login?aj=1",
                type: "POST",
                data : loginData,
                success : function (data) {
                    loginSvc.loggedInData = data;
                    deferred.resolve(data);
                },
                error : function (err) {
                    loginSvc.loggedInData = err;
                    deferred.reject(err);
                }
            });

            return deferred.promise();
        }

        function loginJsonData (jsonData) {
            var data = {
                GreetingName : jsonData.numEmail
            }
            var otpData = otpService.getOtpVariable();

            if (jsonData.otpCheck) {
                data.otpRequest = true;
                data.otpId = otpData.otpId;
                data.otpNumber = otpData.otpNumber;
                data.otpRequest = 1;
            } else if (!(jsonData.otpCheck)) {
                data.Password = jsonData.password;
                data.otpRequest = 0;
            }
            data.page = jsonData.page;
            data.source = loginSvc.source();
            return data;
        }

        loginSvc.updateSession = function (jsonData) {
            var deferred = $.Deferred();
            $.ajax({
                url: "/homes/re2/updateSession",
                type: "POST",
                data : jsonData,
            }).success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        loginSvc.getLoggedInUser = function () {
            var deferred = $.Deferred();
            if(loginSvc.loggedInData.resolveAuthDiffer == 'Invoke') {
                loginSvc.loggedInData.resolveAuthDiffer = 'Loading';
                $.ajax({
                    url: "/homes/re2/auth",
                    type: "GET",
                    success: function (data) {
                        loginSvc.loggedInData = data;
                        $(document).trigger("authEvent", loginSvc.loggedInData);
                        if(data.status == 'AUTH_SUCCESS') {
                          var start = new Date().getTime();
                          setTimeout(function(){
                            typeof emitLogin == 'function' && emitLogin(data.data);
                          },0);
                          var end = new Date().getTime();
                        }
                        deferred.resolve(data);
                    }
                });
            } else {
                if(loginSvc.loggedInData.resolveAuthDiffer == 'True') {
                    deferred.resolve(loginSvc.loggedInData);
                } else {
                    deferred.reject(loginSvc.loggedInData);
                }
            }
            return deferred.promise;
        }

        loginSvc.logOutUser = function () {
            var deferred = $.Deferred();

            $.ajax({
                url: "/homes/re2/logout",
                type: "GET"
            }).success(function (data) {
                loginSvc.init();
                deferred.resolve(data);
            }).error(function (err) {
                deferred.reject(err);
            });
            return deferred.promise();
        }

        loginSvc.authCall = function () {
            var deferred = $.Deferred();
            if(loginSvc.loggedInData.resolveAuthDiffer == 'True') {
                deferred.resolve(loginSvc.loggedInData);
            } else {
                deferred.reject(loginSvc.loggedInData);
            }
            return deferred.promise();
        }

        loginSvc.sendEmailVerification = function (payload) {
            var deferred = $.Deferred();
            $.ajax({
                url: "/homes/re2/sendEmailVerification?aj=1",
                type: "POST",
                data : payload,
                success : function (data) {
                    deferred.resolve(data);
                },
                error : function (err) {
                    deferred.reject(err);
                }
            });

            return deferred.promise();
        }

        loginSvc.addEmail = function (payload) {
            var deferred = $.Deferred();

            $.ajax({
                url: "/homes/re2/addEmail?aj=1",
                type: "POST",
                data : payload,
                success : function (data) {
                    deferred.resolve(data);
                },
                error : function () {
                    deferred.reject(err);
                }
            });

            return deferred.promise();
        }

        return loginSvc;


}();
