import {isSet,isMobile} from '../utils/common'
import {projectMapController} from './projectMapController'
export const  googleMap = function () {
    return {
        init: function () {
            var htmlData = _.template($('#projectGoogleMap_Template').html());
            var mapData = projectMapController.init();
            $('.project-map-box').append(htmlData({
                'builder': builder,
                'destinations': mapData.destinations,
                'projectData': mapData.projectData,
                'projectMap': mapData.projectMap
            }));
            if(isMobile){
                $("#vap-map").addClass("mobile_map");
            }
            if(isSet(mapData.destinations[0])){
                $('#des1').hide();
                //$('input[name="destination1"]').hide();
            }
            if(isSet(mapData.destinations[1])){
                $('#des2').hide();
                //$('input[name="destination2"]').hide();
            }

            $('.project-map-box').on('keyup', 'input[name="destination1"]', function () {
                if ($('input[name="destination1"]').val() != '') {
                    $('#project-commute .reset.src').show();
                } else {
                    $('#project-commute .reset.src').hide();
                }
            });

            $('.project-map-box').on('keyup', 'input[name="destination2"]', function () {
                if ($('input[name="destination1"]').val() != '') {
                    $('#project-commute .reset.des').show();
                } else {
                    $('#project-commute .reset.des').hide();
                }
            });

            var getData = function () {
                return function findMatches(q, cb, cb2) {
                    var matches = [];

                    projectMapController.getSuggestedStreetAddress(q).done(function (response) {
                        if (isSet(response)) {
                            //$.each(response, function(i, obj) {
                            //    matches.push(obj.description);
                            //});

                            //console.log(response);
                            cb2(response);
                        }
                    });

                };
            };

            $('input[name="destination1"]').typeahead({
                    hint: false,
                    highlight: true,
                    minLength: 3
                },
                {
                    limit: 10,
                    name: 'destination1',
                    display: 'description',
                    source: getData()
                }).bind('typeahead:select', function (ev, suggestion) {
                //console.log('Selection: ' + suggestion.description);
                projectMapController.streetAddressSelection(suggestion.description, 0, true);
                //$('input[name="destination1"]').hide();
                $('#project-commute .reset.src').hide();
                $('#des1').hide();
                var htmlData = '';
                htmlData += '<li onclick="displayCustomRoute(\'0\')">';
                htmlData += '<i class="icon icon-location"></i> ' + suggestion.description + ' <br/> <span>';
                htmlData += '<span id="time0"></span>';
                htmlData += '</span>';
                htmlData += '<small>';
                htmlData += '<span id="distance0"></span>';
                htmlData += '</small><span class="reset customdes" onclick="reset(this, 0);">×</span>';
                htmlData += '</li>';
                $('#customCommuteList').append(htmlData);
            });

            $('input[name="destination2"]').typeahead({
                    hint: false,
                    highlight: true,
                    minLength: 3
                },
                {
                    limit: 10,
                    name: 'destination1',
                    display: 'description',
                    source: getData()
                }).bind('typeahead:select', function (ev, suggestion) {
                //console.log('Selection: ' + suggestion.description);
                projectMapController.streetAddressSelection(suggestion.description, 1, true);
                //$('input[name="destination2"]').hide();
                $('#project-commute .reset.des').hide();
                $('#des2').hide();
                var htmlData = '';
                htmlData += '<li onclick="displayCustomRoute(\'1\')">';
                htmlData += '<i class="icon icon-location"></i> ' + suggestion.description + ' <br/> <span>';
                htmlData += '<span id="time1"></span>';
                htmlData += '</span>';
                htmlData += '<small>';
                htmlData += '<span id="distance1"></span>';
                htmlData += '</small><span class="reset customdes" onclick="reset(this, 1);">×</span>';
                htmlData += '</li>';
                $('#customCommuteList').append(htmlData);
            });
        }
    };
}();

window.reset = (elm, index)=> {
    event.stopPropagation();
    resetDestination(index);
    $(elm).parent().remove();
   // $('input[name="destination' + index + '"]').val('').show();
    if (index == 1) {
        $('#project-commute .reset.src').hide();
    } else {
        $('#project-commute .reset.des').hide();
    }
}