var carousel={}
carousel.Similarproject = {};
carousel.SimilarSearches = {};
carousel.Similarproject.counter  = 1;
carousel.SimilarSearches.counter  = 1;
window.carouselControl = (angle,length,moveEle,currntclick)=>{

	if(angle == 'right' && carousel[moveEle].counter<=disableCheck(moveEle,length).slotnext){
		currntclick.previousElementSibling.classList.remove('disable');
        carousel[moveEle].translateR = (-100/length)*carousel[moveEle].counter
        document.getElementById(moveEle).style.transform = "translateX("+carousel[moveEle].translateR+"%)";
        carousel[moveEle].counter = carousel[moveEle].counter+1
        if(carousel[moveEle].counter == disableCheck(moveEle,length).disbaleLength){
			currntclick.classList.add('disable')
		}
    }
    if(angle == 'left' && carousel[moveEle].counter>1){
    	currntclick.nextElementSibling.classList.remove('disable');
        carousel[moveEle].translateR = carousel[moveEle].translateR + (100/length)
        document.getElementById(moveEle).style.transform = "translateX("+carousel[moveEle].translateR+"%)";
        carousel[moveEle].counter = carousel[moveEle].counter-1
        if(carousel[moveEle].counter === 1){
			currntclick.classList.add('disable');
		}
    }
}
function disableCheck(ele,length){
	var counterhandel = {};
	switch (ele) {
		case "Similarproject":
			counterhandel.disbaleLength = length-2
			counterhandel.slotnext = length-3
		break;
		case "SimilarSearches":
			counterhandel.disbaleLength = length-1
			counterhandel.slotnext = length-2
		break;
	}
	return counterhandel;
}