import lozad from 'lozad'
import {getterSetter,isSet, app,confirmPopUP,delegate,removeUrl} from '../utils/common';
require('./gaService.js');
const eventLabelForSubmit = "QH_$intent_VAP_discussionBoard_$category_Submit_click";
const eventLabelForLikeDisLike = "QH_$intent_VAP_discussionBoard_$likeDisLike_click"
const eventActionGlobal="QH_$intent_VAP";
const eventCategoryGlobal="QH_$intent";
let propertyDetails = null;
if (globals.pageType == "VAP") {
    if (globals.propertySnippet) {
        propertyDetails = JSON.parse(atob(globals.propertySnippet));
    } else if (globals.projectSnippet) {
        propertyDetails = JSON.parse(atob(globals.projectSnippet));
    }
}

const intentForGA = (globals.pageType == "VAP") ? ((propertyDetails.availableFor ==='sale'|| propertyDetails.availableFor ==='sell') ? "Buy": propertyDetails.availableFor):"project";

var totalCount = 0;
var perPage = 1;
var currentCount = 0;
let commentIdForDelete;
let userIdForDelete;
let parentCommentForDeleteId;
let sourceForDelete = 0;
var source = app.source.QH;
let entityId = globals.Id;
let entityType;
if (globals.pageType == "VAP") {
    entityType = "listing";
} else if (globals.pageType === 'CoworkVAP') {
    entityType = "cowork";
    source = app.source.COWORK;
} else {
    entityType = "project";
}

var forum = {
    init: () => {
        let entityType;
        if (globals.pageType == "VAP") {
            entityType = "listing";
        } else if (globals.pageType === 'CoworkVAP') {
            entityType = "cowork";
            source = app.source.COWORK;
        } else {
            entityType = "project";
        }
        let entityId = globals.Id;
         document.getElementById('drop_down').addEventListener('click',selectCategory);
        document.getElementById('drop_down').addEventListener('click',function () {
            document.getElementById('forum_topic_select_error').classList.add("hide")
        });
        document.getElementById('forum_title').addEventListener('keyup',function(){
            document.getElementById('forum_subject_error').classList.add("hide");
        });
        document.getElementById('forum_description').addEventListener('keyup',function() {
            document.getElementById('forum_message_error').classList.add("hide"); 
        });

        const listingEntryElm = document.getElementById('discussionEntryListing');
        const discussionFormElm = document.getElementById('forumModuleVAP');
        if (listingEntryElm && discussionFormElm) {
            if (listingEntryElm.classList.contains('hide')) {
                listingEntryElm.classList.remove('hide');
            }
        }
        
        document.getElementById('discussionEntryProject') && document.getElementById('discussionEntryProject').addEventListener('click',scrollToForumMainElement);

        document.getElementById('discussionEntryListing') && document.getElementById('discussionEntryListing').addEventListener('click',scrollToForumMainElement);
        
        delegate(document,"click",".button_reply",function(event){
            let commentId = this.dataset.value;
            document.getElementById("forum_reply_textArea"+commentId).classList.remove("hide");   
        });
        
        delegate(document,"click",".cancel",function(event) {
            let commentId = this.dataset.value;
            document.getElementById("forum_reply_textArea"+commentId).classList.add("hide");
        });
    
        delegate(document, "click", ".see_more", function(event) {
            let commentId = this.dataset.comment;
            let parentReplyId = this.dataset.value;
            document.getElementById("forum_see_more"+parentReplyId).classList.add("hide");
            initReplyForum(entityId,entityType,commentId,parentReplyId);
        });
        
        //upvote Forum
        delegate(document, "click", ".icon_thumb_up", function(event) {
            let commentID = this.value;
            let voteCoutId = document.getElementById("upvote"+commentID);
            let userId = loginCheck();
            if (userId) {
                if (!document.getElementById("forum_upvote"+commentID).classList.contains("active")) 
                {   
                    if(document.getElementById("forum_downvote"+commentID).classList.contains("active")){
                        let downvoteId = document.getElementById("downvote"+commentID);
                        downvoteId.innerText = Number(downvoteId.innerText)-1;   
                        document.getElementById("forum_downvote"+commentID).classList.remove("active");
                    }
                    // add user ID here 
                    if(userId) {
                        updateFeedBackAPI("upvote",userId,commentID,null,voteCoutId);
                }
                    document.getElementById("forum_upvote"+commentID).classList.add("active");
                }
                else {
                    if(userId) {
                        updateFeedBackAPI("upvote",userId,commentID,null,voteCoutId,true);
                    }
                    document.getElementById("forum_upvote"+commentID).classList.remove("active");
                }
            }
        });
    
        //down vote
        delegate(document, "click", ".icon_thumb_down", function(event) {
            let commentID = this.value;
            let userId = loginCheck();
            if (userId) {
                let voteCoutId = document.getElementById("downvote"+commentID);
                if(!document.getElementById("forum_downvote"+commentID).classList.contains("active")) {
                    if(document.getElementById("forum_upvote"+commentID).classList.contains("active")) {
                        let upVoteId = document.getElementById("upvote"+commentID);
                        upVoteId.innerText = Number(upVoteId.innerText)-1;   
                        document.getElementById("forum_upvote"+commentID).classList.remove("active");
                    }
                    document.getElementById("forum_downvote"+commentID).classList.add("active");
                    if(userId) {
                        updateFeedBackAPI("downVote",userId,commentID,null,voteCoutId);
                    }
                }
                else {
                    if(userId) {
                        updateFeedBackAPI("downVote",userId,commentID,null,voteCoutId,"reverse");
                    }
                    document.getElementById("forum_downvote"+commentID).classList.remove("active");
                } 
            }
        });
    
        //delete Comment 
        delegate(document,"click",".deleteForumComment",function(event) {
            event.stopPropagation();
            commentIdForDelete = parseInt(this.getAttribute("value"));
            if(this.getAttribute('replyValue') != null) {
                parentCommentForDeleteId = parseInt(this.getAttribute('replyValue'));
            }
            userIdForDelete = loginCheck();
            confirmPopUP(deleteForumComment);
        });


        //feedbackFollow 
        delegate(document, "click", ".forum_follow", function(event) {
            let commentID = this.value; 
            let voteCoutId = document.getElementById("follow"+commentID);
            let userId = loginCheck();
            if(userId) {
                if(!document.getElementById("forum_follow"+commentID).classList.contains("active")) {
                    document.getElementById("forum_follow"+commentID).classList.add("active");
                    updateFeedBackAPI("isFollowed",userId,commentID,null,voteCoutId);
                    }
                else {
                    updateFeedBackAPI("isFollowed",userId,commentID,null,voteCoutId,true);
                    document.getElementById("forum_follow"+commentID).classList.remove("active");
                }
            }
        });
    
        document.getElementById('forum_submit').addEventListener('click',function() {
            let userId = loginCheck(true);
            if(userId) {
                submitForumComment(entityId,entityType,0,userId);
            }
        });
    
        //simillarily write for the cancel button 
    
        delegate(document, "click", ".forum_reply_button", function(event) {
            let parentCommentId = this.value;
            let commentId = this.dataset.comment;
            let userId = loginCheck();
            if(userId) {
                submitForumComment(entityId,entityType,parentCommentId,userId,commentId);
            }
        });
    
        const countFunction  = (event,eventName ="key") => {
            let count = 0;
            if (eventName == "paste") {
                count += event.clipboardData.getData('Text').length;
            }
            count += document.getElementById('forum_description').value.length;
            if(count <=3000) {
                document.getElementById("charCount").innerText = count + "/3000";
            }
        }
        
        document.getElementById('forum_description').addEventListener('keyup',function() {
            countFunction(event);
        });
    
        document.getElementById('forum_description').addEventListener('paste',function() {
            countFunction(event,"paste");
        });
    
    
        delegate(document, "keyup", ".forum_description_reply", function() {
            loginCheck();
            let commentId = this.dataset.value;
            let lengthOfTextArea = document.getElementById('forum_description_reply'+commentId).value.length;
            document.getElementById("charCountReply"+commentId).innerText = lengthOfTextArea + "/3000";
        });
    
    
        delegate(document, "paste", ".forum_description_reply", function() {
            let commentId = this.dataset.value;
            let lengthOfTextArea = document.getElementById('forum_description_reply'+commentId).value.length;
            lengthOfTextArea += event.clipboardData.getData('Text').length;
            // countFunction(event,"forum_reply_comment","paste");
            document.getElementById("charCountReply"+commentId).innerText = lengthOfTextArea + "/3000";
        });

        if(localStorage.getItem("forum_main_comment")) {
            let forumData = JSON.parse(atob(localStorage.getItem("forum_main_comment")));
            if(entityId === forumData['entityId'] && entityType === forumData['entityType']
            && document.getElementById("forumModuleVAP")) {
                 document.getElementById('categorySelect').innerHTML = forumData['category'];
                document.getElementById('forum_description').value = forumData['description'];
                document.getElementById('forum_title').value = forumData['tittle'];
                scrollToForumMainElement();
                localStorage.removeItem('forum_main_comment');
            }
        }
        delegate(document,"click",".advertiserTag",function() {
            if(document.getElementById('advertiserTag') && document.getElementById('advertiserTag').classList.contains('active')) {
                document.getElementById('advertiserTag').classList.remove('active');    
            }
            else if(document.getElementById('advertiserTag')) {
                document.getElementById('advertiserTag').classList.add('active');
            }
        });

        delegate(document,"click",".supportTag",function() {
            if(document.getElementById('supportTag') && document.getElementById('supportTag').classList.contains('active')) {
                document.getElementById('supportTag').classList.remove('active');
            }
            else if(document.getElementById('supportTag') && document.getElementById('supportTag')) {
                document.getElementById('supportTag').classList.add('active');
            }
        });
        
        delegate(document,"click",".advertiserTagReply",function() {
            let commentId = this.dataset.value;
            if(commentId && document.getElementById('advertiserTagReply'+commentId) && document.getElementById('advertiserTagReply'+commentId).classList.contains('active')) {
                document.getElementById('advertiserTagReply'+commentId).classList.remove('active');    
            }
            else if(document.getElementById('advertiserTagReply'+commentId)) {
                document.getElementById('advertiserTagReply'+commentId).classList.add('active');
            }
        });

        delegate(document,"click",".supportTagReply",function() {
            let commentId = this.dataset.value;
            if(document.getElementById('supportTagReply'+commentId) && document.getElementById('supportTagReply'+commentId).classList.contains('active')) {
                document.getElementById('supportTagReply'+commentId).classList.remove('active');
            }
            else if(document.getElementById('supportTagReply'+commentId) && document.getElementById('supportTagReply'+commentId)) {
                document.getElementById('supportTagReply'+commentId).classList.add('active');
            }
        });


    }
};

$(window).on('load', function() {
    if (document.getElementById('forumModuleVAP')) {
        forum.init();
    }
})
/**
 * function to intialize the forum module 
 * @param {projectId or AdId} entityId 
 * @param {project or Ad } entityType 
 */
export function initForum(entityId,entityType, from = 0, reload= 'false') {
    const src = entityType === 'cowork' ? app.source.COWORK : app.source.QH;
    $.ajax({
            url: "/homes/re2/forumInit?aj=1&size="+perPage+"&fromCommentId="+from+"&source="+ src +"&entityType="+entityType+"&entityId="+entityId,
            type : "GET",
            dataType: 'json',
            success : function(response){
                let discussForumEl = document.getElementById('discussionForumNewVap');
                if(discussForumEl){
                    document.getElementById('discussionForumNewVap').classList.remove('hide');
                }
                if (document.getElementById('forumFormCmnt').classList.contains('hide')) {
                    document.getElementById('forumFormCmnt').classList.remove('hide');
                }
                totalCount  = response.totalCount;
                currentCount = perPage  + currentCount;
                if(reload!= 'scroll') {
                    $("#forumModule").html(response.html);
                    if(isSet(totalCount)) {
                        document.getElementById("discussionHeading").classList.remove("hide");
                    }
                } else {
                    $("#forumModule").append(response.html);
                }
                lazyLoadForumComments(entityId,entityType);
            }
        });

}

function lazyLoadForumComments(entityId,entityType) {
    lozad('.scroll-forum', {
        load: function(el) {
            let pageNo = el.id;
            $(".scroll-forum").remove();
            if (currentCount < totalCount) {
                initForum(entityId,entityType, pageNo, 'scroll');
            }
        }
    }).observe();
};

//delete Forum Comment 

function deleteForumComment() {
    sourceForDelete = source;
        $.ajax({
            url : "/homes/re2/forumDelete?aj=1&commentId="+commentIdForDelete+"&userId="+userIdForDelete+"&source="+sourceForDelete,
            type : 'GET',
            success: function(response) {
                if(response.data) {
                    let element = document.getElementById("forum_comment"+commentIdForDelete);
                    let parentNode = element.parentNode;
                    if(parentNode != null) {
                        element.parentNode.removeChild(element);
                    }
                    if ([...document.getElementsByClassName('forum_comments')].length == 0 ) {
                        document.getElementById("discussionHeading").classList.add("hide");
                    }
                    if(parentCommentForDeleteId && document.querySelector('#forumReplyModule'+parentCommentForDeleteId+' .forum_comments') == null) { 
                        document.getElementById('replyHead'+parentCommentForDeleteId) && document.getElementById('replyHead'+parentCommentForDeleteId).classList.add("hide");
                    }
                    
                }
            }
        })
}

function initReplyForum(entityId,entityType,commentId,parentReplyId) {
    const src = entityType === 'cowork' ? app.source.COWORK : app.source.QH;
    let loginUserId = "";
    if(getterSetter.get( 'loginstatus')){
        let user_Details = getterSetter.get('loginData');
        loginUserId = user_Details.data.user.id;
    }
    $.ajax({
        url: "/homes/re2/forumChildComment?aj=1&commentId="+commentId+"&parentReplyId="+parentReplyId+"&source="+src+"&userId="+loginUserId,
        type : "GET",
        success : function(response){
            // console.log(response);
            $("#forumReplyModule"+parentReplyId).prepend(response);
            if(document.getElementById('replyHead'+parentReplyId)) {
                document.getElementById('replyHead'+parentReplyId).classList.remove("hide");
            }
            // functionalityToUser(entityId,entityType,parentReplyId);
        }
    });
}

export function selectCategory() {
    let element = document.getElementById('categorySelect')
    element.innerText = event.target.innerHTML;
}

export function scrollToForumMainElement() {
    let discussionBoardElement = document.getElementById("forumModuleVAP");
    let positionY = 0;
    var positionX = 0    
    while(discussionBoardElement != null){        
        positionX += discussionBoardElement.offsetLeft;        
        positionY += discussionBoardElement.offsetTop - 40;        
        discussionBoardElement = discussionBoardElement.offsetParent;        
        window.scrollTo({
            top: positionY,
            left: positionX,
            behavior: 'smooth'
        });    
    }
}


/**
 * method to add new comment to the forum module 
 * @param {type of the project} entityType 
 * @param {projectId or AdId} entityId 
 * @param {userId of the logged in user} userId 
 */
export  function submitForumComment(entityId,entityType,parentCommentId,userId,replyCommentId = null) {
    let category,forumDescription,forumTitle;
    if(parentCommentId == 0) {
        category = document.getElementById('categorySelect').innerHTML.replace(/^\s+/g, '').replace(/\s+$/g, '');
        forumDescription = document.getElementById('forum_description').value;
        forumTitle = document.getElementById('forum_title').value;
        if ((category != 'Query' && category != 'Review' && category != 'Suggestion' && category != 'Complaint' 
                && category != 'Other') && forumDescription == "" && forumTitle == "") {
            document.getElementById('forum_topic_select_error').classList.remove('hide');
            document.getElementById('forum_subject_error').classList.remove('hide');
            document.getElementById('forum_message_error').classList.remove('hide');
            return false;
        }
        if (category != 'Query' && category != 'Review' && category != 'Suggestion' && category != 'Complaint' 
                && category != 'Other') {
            document.getElementById('forum_topic_select_error').classList.remove('hide');
            return false;
        }
        if (forumDescription == "" && forumTitle == ""){
            document.getElementById('forum_subject_error').classList.remove('hide');
            document.getElementById('forum_message_error').classList.remove('hide');
            return false;
        }
        if (forumTitle == "" && forumDescription != ""){
            document.getElementById('forum_subject_error').classList.remove('hide');
            return false;
        }
        if (forumDescription=="" && forumTitle!="") {
            document.getElementById('forum_message_error').classList.remove('hide');
            return false;
        }
    }
    else{
        if(document.getElementById("forum_description_reply"+parentCommentId).value == "") {
            document.getElementById("forum_reply_error"+parentCommentId).classList.remove("hide");
            return false;
        }
        category = "Reply";
        forumDescription = document.getElementById("forum_description_reply"+parentCommentId).value;
    }
    forumDescription = removeUrl(forumDescription);

    if(parentCommentId ==0 ) {
        forumTitle = removeUrl(forumTitle);
    }

    let requestData = {
        'category': category.toUpperCase(),
        'commentTitle': forumTitle,
        'userId': userId,
        'entityType': entityType,
        'entityId' : entityId,
        'comment':forumDescription,
        "parentCommentId":parentCommentId,
        "source" : source 
    };
    let advertiserTag = "advertiserTag";
    let supportTag = "supportTag";
    if(parentCommentId != 0) {
        advertiserTag = "advertiserTagReply" + parentCommentId;
        supportTag = "supportTagReply" + parentCommentId;
    }
    let tags = 0;
    if(document.getElementById(advertiserTag) && document.getElementById(supportTag)) {
        if(document.getElementById(supportTag).classList.contains('active') && document.getElementById(advertiserTag).classList.contains('active')){
            tags = 3;
        } 
        else if(document.getElementById(advertiserTag).classList.contains('active') && !document.getElementById(supportTag).classList.contains('active')) {
            tags = 2;   
        }
        else if(document.getElementById(supportTag).classList.contains('active') && !document.getElementById(advertiserTag).classList.contains('active')) {
            tags = 1;   
        }
        requestData['tags'] = tags;
    }
    

    let stringForProfanity = forumTitle + " " + forumDescription;
     
    $.ajax({
        url: "/homes/re2/contentCheck?aj=1&text="+stringForProfanity,
        type : "GET",
        dataType:'json',
        beforeSend: function() {
            document.getElementById('forum_submit').disabled = true;
            if (document.getElementById('forum_submit_reply'+parentCommentId)) {
                document.getElementById('forum_submit_reply'+parentCommentId).disabled = true;
            }
        },
        success : function(response){
            document.getElementById('forum_submit').disabled = false;
            let l =response[0];
            if (l['classification'] == 'cleared'){
                addForumComment(requestData).then((resData) => {
                      if (resData) {
                        let entityType = requestData["entityType"];
                        let entityId = requestData["entityId"];
                        let userId = requestData["userId"];
                        let parentCommentId = requestData["parentCommentId"]
                        let eventLabel = eventLabelForSubmit.replace("$intent",intentForGA);
                        eventLabel=eventLabel.replace("$category",category);
                        let eventAction=eventActionGlobal.replace("$intent",intentForGA);
                        let eventCategory=eventCategoryGlobal.replace("$intent",intentForGA);
                        gaService.addEvent(eventCategory,eventAction,eventLabel);
                        
                        if (document.getElementById('forum_submit_reply'+parentCommentId)) {
                            document.getElementById('forum_submit_reply'+parentCommentId).disabled = false;
                        }
                        document.getElementById('categorySelect').innerHTML = "Please Select*";
                        document.getElementById('forum_description').value = "";
                        document.getElementById('forum_title').value = "";
                        if (requestData["parentCommentId"]!=0){
                            document.getElementById("forum_description_reply"+parentCommentId).value = "";
                            document.getElementById("sentmessage"+parentCommentId).classList.remove("hide");
                            setTimeout(() => {
                                document.getElementById("charCount").innerText ="0/3000";
                                document.getElementById("charCountReply"+parentCommentId).innerText =  "0/3000";
                                document.getElementById("sentmessage"+parentCommentId).classList.add("hide");
                                document.getElementById("forum_reply_textArea"+parentCommentId).className += " hide";
                                document.getElementById("forum_see_more"+parentCommentId) ? document.getElementById("forum_see_more"+parentCommentId).classList.add("hide"):"";

                                initReplyForum(entityId,entityType,replyCommentId,parentCommentId);
                            }, 3000);
                            // show the submit button with commentId
                            
                        }
                        else {
                            //show the comment without commentId
                            document.getElementById("setMessage").classList.remove("hide");
                            setTimeout(() => {
                                document.getElementById("charCount").innerText ="0/3000";
                                document.getElementById("setMessage").classList.add("hide");
                            },3000)
                            currentCount = 0;
                            initForum(entityId,entityType);
                        }
                        document.getElementById(advertiserTag).classList.remove("active");
                        document.getElementById(supportTag).classList.remove("active");
                    }
                }).catch((err) => {throw err});
            }else {
                addRejectedComments(requestData);
            }
        }
    });
    
}

/**
 * to add comments to re2_forum_module
 * @param {payload to add comment} requestData 
 */
function addForumComment(requestData) {
    requestData.isApproved = 1;
    return new Promise((res, rej) => {
        $.ajax({
            url: "/homes/re2/forumComment",
            type : "POST",
            data: requestData,
            success: (response) => {
                res(response);
            },
            error: (error) => {
                rej(error);
            }
        });
    });
}


/**
 * ajax to hit rejected comments 
 * @param {payload to add RejectedComment} requestData 
 */
function addRejectedComments(requestData) {
    //add isApproved = 0 
    requestData.isApproved = 0;
    $.ajax ({
        url : "/homes/re2/forumRjectedComments?aj=1",
        type:"POST",
        data:requestData,
        success : function(){
            let entityType = requestData["entityType"];
            let entityId = requestData["entityId"];
            let parentCommentId = requestData["parentCommentId"]
            if (requestData["parentCommentId"]!=0){
                initReplyForum(entityId,entityType,parentCommentId);
                document.getElementById("forum_reply_textArea"+parentCommentId).className += "hide";
            }
            else {
                initForum(entityId,entityType);
            }
        }
    });

}

/**
 * function to enter user feedBack of the user 
 * @param {Category of comment } ratingCategory 
 * @param {UserId of the logged in user} userId 
 * @param {Commnet Id of the current comment} commentId 
 * @param {If the use is about } reportComment 
 * @param {Previous count of the feedback element} countElement 
 */
export function updateFeedBackAPI(ratingCategory,userId,commentId,reportComment,countElement,isReverse = false) {
    let requestData = {
        'userId':userId,
        'commentId':commentId,
        'source': source
    };
    switch(ratingCategory) {
        case "upvote":
            requestData["isLiked"] = isReverse?0:1;
            break;
        case "downVote":
            requestData["isDisliked"] = isReverse?0:1;
            break;
        case "isFollowed":
            requestData["isFollowed"] = isReverse?0:1;
            break;
        case "report":
            requestData["reportFeedbackReason"] = reportComment;
            break;
    }
    $.ajax(
        {
            url : "/homes/re2/forumUpdateFeedback?aj=1",
            type : "POST",
            data : requestData,
            success : function(response) {
                response = JSON.parse(response)[0];
                let eventLabelUpdateFeedBack;
                let eventCategoryUpdateFeedBack;
                let eventActionUpdateFeedBack;
                if(ratingCategory ==='upvote' || ratingCategory === 'downVote') {
                    // propertyDetails.propertyFor = propertyDetails.propertyFor === 'sale' ? 'Buy': propertyDetails.propertyFor.charAt(0).toUpperCase();
                    if(ratingCategory ==='upvote'){
                        eventLabelUpdateFeedBack = eventLabelForLikeDisLike.replace("$likeDisLike","Like");
                    }else if (ratingCategory ==='downVote'){
                        eventLabelUpdateFeedBack = eventLabelForLikeDisLike.replace("$likeDisLike","DisLike");
                    }
                    eventLabelUpdateFeedBack = eventLabelUpdateFeedBack.replace("$intent",intentForGA);
                    eventCategoryUpdateFeedBack = eventCategoryGlobal.replace("$intent",intentForGA);
                    eventActionUpdateFeedBack = eventActionGlobal.replace("$intent",intentForGA);
                    gaService.addEvent(eventCategoryUpdateFeedBack,eventActionUpdateFeedBack,eventLabelUpdateFeedBack);
                }
                if (response.statusCode == 200 && response.message=="success" && countElement!= null) {
                    let count = Number(countElement.innerText);
                    countElement.innerText = isReverse ? count >=0 ?count-1:count:count+1;
                }
            }
        });
}


function loginCheck(isForumMain = false) {
    if(getterSetter.get( 'loginstatus')){
        let user_Details = getterSetter.get('loginData');
        let loginUserId = user_Details.data.user.id;
        return loginUserId;
    } else {
        if(isForumMain) {
            let forumData = {
                'category': document.getElementById('categorySelect').innerHTML.replace(/^\s+/g, '').replace(/\s+$/g, ''),
                'description':document.getElementById('forum_description').value,
                'tittle':document.getElementById('forum_title').value,
                'entityId':entityId,
                'entityType':entityType
            };
            if(localStorage.getItem("forum_main_comment")) {
                localStorage.removeItem("forum_main_comment",btoa(JSON.stringify(forumData)));
            }
            localStorage.setItem("forum_main_comment",btoa(JSON.stringify(forumData)));
        }
        PUB_SUB.emit('login_modal');
        return false;
    }
}