import {isSet, getterSetter, getFormObject, source, isAndroid, isIos } from '../utils/common'
import {formValidator} from '../services/formValidator'
import {getLoggedInUser} from '../loginService'
import {otpService} from '../services/otpService'
import {dataService} from '../services/dataService'
import {windowWidthService} from '../services/windowWidthService'
import {getCookie, setCookieWithExpirationHours, setCookie} from '../services/cookieService'
import {requirementService} from '../services/requirementService'
import COUNTRIES from '../nri/country_code';
import { nriAssistedRequirementPayload } from './payloadBuilder';
require('../services/gaService')

var whatsApp = {};
var formDetails = {}
var clickedElement = '';
var tmpDetail = {};

var propertySnippet = globals.propertySnippet && JSON.parse(atob(globals.propertySnippet));

whatsApp.init = function ()  {
	$("#whatsAppFormChange").removeClass("thankyoup");
	whatsApp.getLoggedInUser();
	otpService.setOtpVariable('', 'otpId');
	whatsApp.triggerGa('init');
}

whatsApp.triggerGa = (type) => {
	let intent = 'buy';
	let pagetype = 'snb';
	if(window.location.href.indexOf("/property/") > -1 && (window.location.href.indexOf("-sale-") > -1 || window.location.href.indexOf("-sell-") > -1)) {
       	pagetype = 'snb';
       	intent = 'buy';
    } else if(window.location.href.indexOf("/project/") > -1) {
    	pagetype = 'vap';
    	intent = 'project';
    } else if(window.location.href.indexOf("/property/") > -1 && window.location.href.indexOf("-projects-") > -1) {
    	pagetype = 'snb';
    	intent = 'project';
    } else if(window.location.href.indexOf("/property/") > -1 && window.location.href.indexOf("-rent-") > -1) {
       	pagetype = 'snb';
       	intent = 'rent';
    } else if(window.location.href.indexOf("/listing/") > -1 && window.location.href.indexOf("-rent-") > -1) {
    	pagetype = 'vap';
       	intent = 'rent';
    } else if(window.location.href.indexOf("/property/") > -1 && window.location.href.indexOf("-pg_flatmates-") > -1) {
    	pagetype = 'snb';
       	intent = 'pg';
    } else if(window.location.href.indexOf("/listing/") > -1 && window.location.href.indexOf("-pg-") > -1) {
    	pagetype = 'vap';
       	intent = 'pg';
    } else {
    	pagetype = 'vap';
    	intent = 'buy';
    }
    if(type == 'init') {
		if (pagetype === 'vap' && propertySnippet.availableFor === 'pg' && (propertySnippet.isQAP || propertySnippet.isGrabHouse)) {
			gaService.addEvent('VAP_Real-QuikrPG_Assured', "VAP_Real-QuikrPG_Assured_whatsapp-button", 'VAP_Real-QuikrPG_Assured_whatsapp-button_init');
		} else {
			gaService.addEvent('RealEstate', "QH_whatsapp_"+pagetype+"_"+intent, 'QH_whatsapp_'+pagetype+'_'+intent+'_click');
		}
	} else {
		gaService.addEvent('RealEstate', "QH_whatsapp_" + pagetype + "_" + intent + "_leadForm", 'QH_whatsapp_' + pagetype + '_' + intent + '_leadForm_click');
	}
}

whatsApp.submitClick = () => {
	if (!whatsApp.valid()) {
		return false;
	}
	whatsApp.viewad()
}

whatsApp.loginSubmitClick = () => {
	let whatsappotp = document.querySelector("#whatsAppFormChange #otp");
	if (!whatsApp.valid()) {
		return false;
	}
	whatsApp.showOtpSuccessSection();
}

whatsApp.verifyOtpSection = () => {
	if (isSet(otpService.getOtpVariable().otpNumber) && isSet(otpService.getOtpVariable().otpId)) {
		whatsApp.verifyOTP()
	} else {
		whatsApp.showOtpSuccessSection();
	}
}

whatsApp.valid = function () {
	let $form = $(clickedElement.closest("form[name='replyForm']"));
	let nameElement = $form.find(":input[name='name']");
	let mobileElement = $form.find(":input[name='mobile']");
	let emailElement = $form.find(":input[name='email']");
	let nameValid = formValidator.isNameValid.call(nameElement);
	let mobileValid = formValidator.isMobileNoValid.call(mobileElement);
	let emailValid = formValidator.isEmailValid.call(emailElement);
	return (nameValid && mobileValid && emailValid);
};

whatsApp.viewad = () => {
	whatsApp.getFormValue();
	whatsApp.showOtpSuccessSection();
}


whatsApp.getFormValue = () => {
	let form = getFormObject(clickedElement.closest("form[name='replyForm']"));
	formDetails.email = form.email ? form.email : formDetails.email;
	formDetails.mobile = form.mobile ? form.mobile : formDetails.mobile;
	formDetails.name = form.name ? form.name : formDetails.name;
	getterSetter.set('email', formDetails.email)
	getterSetter.set('mobile', formDetails.mobile)
	getterSetter.set('name', formDetails.name)
}

whatsApp.getLoggedInUser = () => {
	formDetails.emailList = []
	formDetails.mobileList = []
	getLoggedInUser.then(response => {
		let details = response;
		if (details && details.data && details.data.user) {
			if (!details.error) {
				whatsApp.loginStatus = true;
				getterSetter.set('loginstatus', true);
				getterSetter.set('otpFlag', true);
				whatsApp.setLoggedInData(details.data.user);
			} else {
				whatsApp.loginStatus = false;
				document.getElementById('whatsAppForm').innerHTML = whatsApp.detailTemplate();
				whatsApp.eventListener();
				let formViewTypeWrap = '#whatsAppForm';
				whatsApp.initCountryCodeDropdown(formViewTypeWrap);
			}
		} else {
			whatsApp.loginStatus = false;
			document.getElementById('whatsAppForm').innerHTML = whatsApp.detailTemplate();
			whatsApp.eventListener();
			let formViewTypeWrap = '#whatsAppForm';
			whatsApp.initCountryCodeDropdown(formViewTypeWrap);
		}
	}).catch(() => {
		whatsApp.loginStatus = false;
		document.getElementById('whatsAppForm').innerHTML = whatsApp.detailTemplate();
		whatsApp.eventListener();
		let formViewTypeWrap = '#whatsAppForm';
		whatsApp.initCountryCodeDropdown(formViewTypeWrap);
	});
}

whatsApp.setLoggedInData = (data) => {
	formDetails.name = whatsApp.setLoginName(data)
	getterSetter.set('name', formDetails.name)
	data.userMobile = (typeof data.userMobile === 'undefined') ? [] : data.userMobile;
	data.userEmail = (typeof data.userEmail === 'undefined') ? [] : data.userEmail;
	data.userEmail.length > 0 ? getterSetter.set('email', data.userEmail[0].emailId) : ''
	data.userMobile.length > 0 ? getterSetter.set('mobile', data.userMobile[0].mobileNumber) : ''
	getterSetter.set('loggedInUserId', data.id)
	formDetails.emailList = data.userEmail
	formDetails.mobileList = data.userMobile
	formDetails.email = getterSetter.get('email')
	formDetails.mobile = getterSetter.get('mobile')
	document.getElementById('whatsAppForm').innerHTML = whatsApp.detailTemplate();
	whatsApp.eventListener();
}

whatsApp.setLoginName = (user) => {
	if (user.firstName) {
		return user.firstName;
	} else if (user.email) {
		return user.email.substring(0, user.email.indexOf("@"));
	} else {
		return '';
	}
}

whatsApp.generateOTP = () => {
	let formViewTypeWrap = '#whatsAppForm';
	let phonecode = getterSetter.get('phonecode') || '';
	var payload = {
		"email": (getterSetter.get('email') ? getterSetter.get('email') : ''),
		"mobile": (getterSetter.get('mobile') ? ''+ phonecode + getterSetter.get('mobile') : ''),
		"firstName": (getterSetter.get('name') ? getterSetter.get('name') : ''),
		"source": source(),
		"page": 'REPLY'
	}

	otpService.generateOTP(payload).then(function (result) {
		otpService.setOtpVariable(result.clientId, 'otpClientId');
		document.querySelector(".whatsAppOtpbox").classList.add('otp');
		result = JSON.parse(result.response);
		let alertbox = document.querySelector("#whatsAppFormChange #alertmsgbox");
		alertbox.innerHTML = `OTP sent successfully to ${getterSetter.get('mobile')}`;
		alertbox.classList.remove('hide');
		if (result && result.GenerateOTPApplicationResponse && result.GenerateOTPApplicationResponse.GenerateOTPApplication) {
			let OtpId = result.GenerateOTPApplicationResponse.GenerateOTPApplication.otpId;
			otpService.setOtpVariable(OtpId, 'otpId');
			$("#whatsappMobile").prop('readonly', true);
			$(formViewTypeWrap + " #whatsappSubmit").css({ "display": "none" });
			$(formViewTypeWrap + " #whatsappLoginSubmit").css({ "display": "block" });
		}
	});

	let OTPEl = formViewTypeWrap + ' #otp'
	document.querySelector(OTPEl).addEventListener('keyup', (event) => {
		otpService.setOtpVariable(event.target.value, 'otpNumber');
		$(".otp_required").addClass('hide');
	})

	let resentEL = formViewTypeWrap + ' #resend';
	document.querySelector(resentEL).addEventListener('click', (event) => {
		var resendPayload = {
			"otpId": otpService.getOtpVariable().otpId,
			"otpClientId": otpService.getOtpVariable().otpClientId ? otpService.getOtpVariable().otpClientId : '',
			"source": source(),
			"page": "REPLY"
		}
		let alertbox = document.querySelector("#whatsAppFormChange #alertmsgbox");
		otpService.resendOTP(resendPayload).then(function (result) {
			$(".otp_invalid").addClass('hide');
			document.querySelector("#whatsAppFormChange #otp").value = '';
			alertbox.innerHTML = `OTP resent successfully to ${getterSetter.get('mobile')}`;
			if (result && result.ResendOTPApplicationResponse && result.ResendOTPApplicationResponse.ResendOTPApplication) {
			}
		});
	})
}

whatsApp.verifyOTP = () => {
	var otpData = otpService.getOtpVariable();
	var payload = {
		"otpId": otpData.otpId,
		"otp": otpData.otpNumber,
		"source": source(),
		"page": "REPLY",
		"otpClientId": otpData.otpClientId,
		"enquiry_phone": getterSetter.get('mobile') || ''
	};
	otpService.verifyOTP(payload).then(function (result) {
		var result = result && result.response ? JSON.parse(result.response).VerifyAndDeleteOTPApplicationResponse : {};
		if (result) {
			if (isSet(result.VerifyAndDeleteOTPApplication) && isSet(result.VerifyAndDeleteOTPApplication.success)) {
				$(".otp_invalid").addClass('hide');
				getterSetter.set('otpFlag', true);
				setCookieWithExpirationHours('otp_verified_no', getterSetter.get('mobile'), 1);
				whatsApp.showOtpSuccessSection();
			} else {
				$(".otp_invalid").removeClass('hide');
				$(".otp_required").addClass('hide');
			}
		}
	});
}

whatsApp.showOtpSuccessSection = () => {
	whatsApp.triggerGa('submit')
	document.querySelector("#whatsapp-form-close").click();
	whatsApp.contact();
}

whatsApp.contact = () => {
	let mobileNo = '919513962568';
	let intenttext = 'property to buy ';
	let intent = 'buy';
	let category = "Residential";
	if(window.location.href.indexOf("-sale-") > -1 || window.location.href.indexOf("-sell-") > -1 || window.location.href.indexOf("/project/") > -1
		 || window.location.href.indexOf("-projects-") > -1) {
       	intenttext = 'property to buy ';
       	mobileNo = '919513962568';
       	intent = 'buy';
    } else if(window.location.href.indexOf("-rent-") > -1) {
       	intenttext = 'property for rent ';
       	mobileNo = '919513962569';
       	intent = 'rent_in';
    } else if(window.location.href.indexOf("-pg-") > -1 || window.location.href.indexOf("-pg_flatmates-") > -1) {
       	intenttext = 'for PG ';
       	mobileNo = '91'+globals.qhmrWhatsAppContact;
       	intent = 'pg_in';
    } else if(window.location.href.indexOf("property-assistance") > -1) {
       	intenttext = ' property to buy ';
       	mobileNo = '917406906699';
       	intent = 'buy';
    } else {
    	intenttext = 'property to buy ';
    	mobileNo = '919513962568';
    	intent = 'buy';
    }

	const isNRIAssisted = (window.location.href.indexOf("property-assistance") > -1);
    let reqsource = isNRIAssisted ? 'NRI_ASSISTED_WHATSAPP_FF' : 'WHATSAPP';
	let payloadData = { 
		"emailId": (getterSetter.get('email') ? getterSetter.get('email') : ''),
		"phone": (getterSetter.get('mobile') ? getterSetter.get('mobile') : ''),
		"userName": (getterSetter.get('name') ? getterSetter.get('name') : ''),
		"cityId": getCookie('prefer_city_id') || '',
		"refUrl":location.hostname,
        "verifiedStatus" : 'OTP_VERIFIED',
        "channel": windowWidthService.channel,
        "propertyFor": intent,
        "source": reqsource,
        "category": category
	}

	if (isNRIAssisted) {
		requirementService.addNRIAssistedRequirement(nriAssistedRequirementPayload(reqsource));
	} else {
		requirementService.addRequirement(payloadData);
	}
	if(isSet(getterSetter.get('mobile'))) {
		let currentUrl = window.location.href;
		let msg = `I am looking ${intenttext} in ${getCookie('new_prefer_city')}`;
		let whatsAppUrl = `https://api.whatsapp.com/send?phone=${mobileNo}&text=${encodeURI(msg)} ${encodeURI(window.location.href)}`;
		const sourceDevice = source();
		window.open(whatsAppUrl, '_blank');
	}

}


whatsApp.detailTemplate = function () {
	return `<form name="replyForm" novalidate >
                <div class="input-div">
                    <div class="input-design nameWrapper ${(getterSetter.get('name') ? 'open' : '')}">
                        <input type="name" name="name" value="${(getterSetter.get('name') ? getterSetter.get('name') : '')}">
                        <label>Name*</label>
                        <div class="error-container hide">
                            <small class="name_required">Please enter name</small>
                            <small class="name_pattern">Please enter a valid name</small>
                        </div>
                    </div>
                    <div class="input-design emailWrapper ${(getterSetter.get('email') ? 'open' : '')}">
                        <input type="name" name="email" value="${(getterSetter.get('email') ? getterSetter.get('email') : '')}">
                        <label>Email*</label>
                        <div class="error-container hide">
                            <small class="email_required">Please enter email</small>
                            <small class="email_pattern">Please enter a valid email</small>
                        </div>
                    </div>
                    <div class="otpbox whatsAppOtpbox" id="replyotpshow">
                        <div class="input-design isdcode open">
                            <input class="cu_cd" type="mobile" id="whatsappMobile" name="mobile" value="${(getterSetter.get('mobile') ? getterSetter.get('mobile') : '')}">
                            <label>Mobile*</label>
                            <div class="error-container hide">
                                <small class="mobileNo_required hasError">Please enter mobile number</small>
                            </div>
                        </div>
                        <div class="input-design otp open">
                            <input type="text" maxlength="4" id="otp" placeholder="XXXX">
                            <label>OTP</label>
                            <a href="javascript:void(0)" id="resend">Resend OTP</a>
                            <div class="error-container">
                                <small class="otp_invalid hasError hide">Invalid OTP</small>
                                <small class="otp_required hasError hide">Enter OTP</small>
                            </div>
                        </div>
                         <div class="country_c tp27">
		                    <div class="dropdown-menu-open" dropdown>
		                        <a class="dropdown-toggle" data-toggle="dropdown" href="">
		                            <span data-ref="contact--phone-code">+${(getterSetter.get('phonecode') ? getterSetter.get('phonecode') : '91')}</span>&nbsp;
		                            <i class="icon icon-down-open-mini"></i>
		                        </a>
		                        <input type="hidden" name="phonecode" value="+${(getterSetter.get('phonecode') ? getterSetter.get('phonecode') : '91')}"/>
		                        <div class="dropdown-open-onclick">
		                            <div class="dropdown-search">
		                                <input type="text" autofocus placeholder="Search Country" data-ref="contact--country-search"/>
		                            </div>
		                            <ul class="dropdown-suggestions" data-ref="contact--country-suggestions"></ul>
		                        </div>
		                    </div>
		                </div>
                    </div>
                    <div class="input-design alertmsgbox hide" id="alertmsgbox"></div>
                    <button type="button" class="button button-secondary btnsbt" id="whatsappSubmit">
                        <span>Submit</span>
                    </button>
                    <button type="button" style="display: none;" class="button button-secondary btnsbt" id="whatsappLoginSubmit">
                    	<span  >Login & Submit</span>
                    </button>
                    <div class="qcontent">
                        I authorise Quikr and its business associates, <span class="ReadMore" id="readMore" onclick="readMoreLess(this)">Read More</span> <span class="hide readMoreLess">partners  and/or affiliates to call, sms or email me in connection with my application or for offering other products and services and I agree to the privacy policy and terms of use.</span> <span class="ReadLess hide" id="readLess" onclick="readMoreLess(this)">Read Less</span>
                    </div>
                </div>
            </form>`;
};

// Renders the country suggestions
whatsApp.renderCountrySuggestions = (form, suggestions = []) => {
	const elContactForm = document.querySelector(form);
	const elCountrySuggestions = elContactForm.querySelector('[data-ref=contact--country-suggestions]');

	elCountrySuggestions.innerHTML = suggestions.reduce((html, country) => html + `
		<li tabindex="0" data-value="${country.dial_code}" data-country="${country.code}">${country.dial_code} (${country.name})</li>
	`, '');
};

// Initializes the countries dropdown
whatsApp.initCountryCodeDropdown = (form) => {
	// Event listener for country phone code change
	const elContactForm = document.querySelector(form);
	const elPhoneWrapper = elContactForm.querySelector('#replyotpshow');
	const elPhoneCodeWrapper = elContactForm.querySelector('.country_c');
	const elMobileNumber = elContactForm.querySelector('input[name=mobile]');
	const elMobileDropdown = elContactForm.querySelector('#selectMobile')
	const elPhoneCodeHidden = elContactForm.querySelector('input[name=phonecode]');
	const elPhoneCodeLabel = elContactForm.querySelector('[data-ref=contact--phone-code]');
	const elCountrySearchInput = elContactForm.querySelector('[data-ref=contact--country-search]');
	const elCountrySuggestions = elContactForm.querySelector('[data-ref=contact--country-suggestions]');

	// Remove the country code dropdown if the user doesn't
	// have any mobile associated
	if (elMobileDropdown) {
		elPhoneCodeWrapper.remove();
	} else {
		let cursor = -1;
		let elLastCursorOnSuggestion = null;

		// Render the country suggestions
		whatsApp.renderCountrySuggestions(form, COUNTRIES);

		// Country auto suggest
		elCountrySearchInput.addEventListener('input', ({target: {value}}) => {
			const matchingCountries = COUNTRIES.filter((country) => {
				return country.dial_code.includes(value) || 
					(country.name.toLowerCase().startsWith(value.toLowerCase()));
			});

			// Re-render the country suggestions
			cursor = -1;
			whatsApp.renderCountrySuggestions(form, matchingCountries);
		});

		// Keyboard navigation for country suggestions
		elCountrySearchInput.addEventListener('keydown', (ev) => {
			if (38 === ev.keyCode || 40 === ev.keyCode || 13 === ev.keyCode) {
				const suggestions = elCountrySuggestions.children;
				const max = elCountrySuggestions.childElementCount;

				if (elLastCursorOnSuggestion) {
					elLastCursorOnSuggestion.classList.remove('active');
				}

				// Update the cursor
				if (40 === ev.keyCode && cursor < (max - 1)) {
					cursor += 1;
					elLastCursorOnSuggestion = suggestions.item(cursor);
					elLastCursorOnSuggestion.classList.add('active');

					// Scroll down
					if (elLastCursorOnSuggestion.offsetTop > elCountrySuggestions.clientHeight) {
						elCountrySuggestions.scrollTop = elCountrySuggestions.scrollTop + elLastCursorOnSuggestion.scrollHeight + 2;
					}
				} else if (38 === ev.keyCode && cursor > 0) {
					cursor -= 1;
					elLastCursorOnSuggestion = suggestions.item(cursor);
					elLastCursorOnSuggestion.classList.add('active');

					// Scroll down
					if (elLastCursorOnSuggestion.offsetTop > elCountrySuggestions.clientHeight) {
						elCountrySuggestions.scrollTop = elCountrySuggestions.scrollTop - elLastCursorOnSuggestion.scrollHeight - 2;
					}
				} else if (13 === ev.keyCode) {
					ev.preventDefault();
					suggestions.item(cursor).click();
				}
			}
		});

		// Country selection from suggestions
		elCountrySuggestions.addEventListener('click', ({target: {dataset: {value, country}}}) => {
			if (!value) return;

			elPhoneCodeHidden.value = value;
			elPhoneCodeLabel.innerText = value;
			getterSetter.set('countryCode', country);
			getterSetter.set('phonecode', value.replace('+', ''));
			// Validate the phone number based on the country selected
			formValidator.isMobileNoValid.call(elMobileNumber);
		});
	}
}


whatsApp.thankyouTemplate = function (){
	return `<div class="input-div thankup">
                <div class="thup">
                    <span>Thank You!</span>
                    <small>for sharing your contact details.</small>
                </div>
                <p>The owner will get in touch with you.</p>
                <button type="button" class="button button-secondary btnsbt" data-dismiss="modal" aria-label="Close">
                    <span>OK</span>
                </button>
            </div>`;
};

whatsApp.eventListener = function () {
	// Add the event listener
	document.getElementById('whatsappSubmit').addEventListener('click', function (event) {
		clickedElement = event.currentTarget;
		whatsApp.submitClick();
	});
	document.getElementById('whatsappLoginSubmit').addEventListener('click', function (event) {
		clickedElement = event.currentTarget;
		whatsApp.loginSubmitClick();
	});
};

/* Whatsapp Icon Click */
if (document.getElementById('whatsappInit')) {
	document.getElementById('whatsappInit').addEventListener('click', function () {
		whatsApp.init();
	});
}

window.readMoreLess = (el) => {
	el ? $(el).closest('.qcontent').find('.readMoreLess , .ReadMore, .ReadLess').toggleClass('hide') : '';
}