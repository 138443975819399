import {isSet, makeFormData, getterSetter} from "../utils/common";

export const upVote = (entityType, entityId, userId, voteType)=> {
	let formData = makeFormData({
        'entityType': entityType,
		'entityId': entityId,
		'visitorUserId': userId,
        'voteType': voteType,
        'reason': '',
        'source': 'QH'
    });

    var url = '/homes/re2/set-voting?aj=1';

    postData(url, formData).then(result => {
        //$('.q_cash').addClass('hide');
        //Check user already clicked downvote and update downvote
        if($('.downvote:first').hasClass('active')) {
            let downcountval = $('.downvotecount:first').text();
            downcountval = (!isSet(downcountval)) ? 0 : downcountval;
            downcountval = parseInt(downcountval) - 1;
            downcountval = (downcountval > 0) ? downcountval : '';
            $('.downvote').each(function() {
                $(this).removeClass('active');
                $(this).find('.downvotecount').text(downcountval);
            });
        }

        //update upvote count
        let upcountval = $('.upvotecount:first').text();
            upcountval = (!isSet(upcountval)) ? 0 : upcountval;
        if($('.upvote:first').hasClass('active')) {
            upcountval = parseInt(upcountval) - 1;
        } else {
            upcountval = parseInt(upcountval) + 1;
        }

        $('.upvote').each(function() {
            if($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
            //upcountval = (upcountval > 0) ? upcountval : '';
            $(this).find('.upvotecount').text(upcountval);
        });
    }).catch(err => console.log(err));
}

export const downVote = (entityType, entityId, userId, voteType)=> {
    let reason = ($('#votereason').val().trim().length > 0) ? $('#votereason').val() : '';

    const reasonsDom = document.getElementsByName('votereasonOpt');
    let selectedReasons = [];
    if (reasonsDom && reasonsDom.length > 0) {
        reasonsDom.forEach(el => {
            if (el.checked) {
                selectedReasons.push(el.value);
            }
        });
    }

    let formData = makeFormData({
        'entityType': entityType,
        'entityId': entityId,
        'visitorUserId': userId,
        'voteType': voteType,
        'url': window.location.href,
        'reason': reason,
        'source': 'QH'
    });
    formData.append('options', selectedReasons);

    var url = '/homes/re2/set-voting?aj=1';

    postData(url, formData).then(result => {
        //$('.q_cash').addClass('hide');
        //Check user already clicked upvote and update upvote
        if($('.upvote:first').hasClass('active')) {
            let upcountval = $('.upvotecount:first').text();
            upcountval = (!isSet(upcountval)) ? 0 : upcountval;
            upcountval = parseInt(upcountval) - 1;
            upcountval = (upcountval > 0) ? upcountval : '';
            $('.upvote').each(function() {
                $(this).removeClass('active');
                $(this).find('.upvotecount').text(upcountval);
            });
        }

        //down upvote count
        let downcountval = $('.downvotecount:first').text();
            downcountval = (!isSet(downcountval)) ? 0 : downcountval;
        if($('.downvote:first').hasClass('active')) {
            downcountval = parseInt(downcountval) - 1;
        } else {
            downcountval = parseInt(downcountval) + 1;
        }

        $('.downvote').each(function() {
            if($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
            //downcountval = (downcountval > 0) ? downcountval : '';
            $(this).find('.downvotecount').text(downcountval);
            selectedReasons = [];
            reasonsDom.forEach(el => el.checked = false );
            document.getElementById('votereason').value = '';
        });
    }).catch(err => console.log(err));
}

export const propertySoldOut = function(isDeleted = 0) {
    let url = '/homes/re2/property-soldout?aj=1';
    const reqParams = {
        id: getterSetter.get('ownerPropertyAvlUnqId'),
        isDeleted: isDeleted
    };
    const fd = makeVoteFormData(reqParams);
    return postData(url, fd);
}

function makeVoteFormData(obj = {}) {
    let formData = new FormData();
    for (let key in obj) {
        formData.append(key, obj[key])
    }
    return formData;
}

function postData(url = '', data = '', headers = {}) {
    return fetch(url, {
        method: "POST",
        body: data,
        headers: headers
    }).then(response => response.text() );
}