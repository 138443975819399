export const ngGPlacesPro = function () {
    function onStreetSelection(address, projetLatLon, callback) {
        var defaults = {
            city: "",
            locality: "",
            geocoder: new google.maps.Geocoder(),
            map: null,
            marker: null,
            infowindow: {},
            lat: '',
            long: ''
        };
        defaults.geocoder.geocode({'address': address}, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                defaults.lat = results[0].geometry.location.lat();
                defaults.long = results[0].geometry.location.lng();
                var destLatLon = {
                    'lat': defaults.lat,
                    'lng': defaults.long
                };

                //setMarker(defaults.lat,defaults.long,map, address);
                getTravelDistanceAndTime('search_add', projetLatLon, destLatLon, function (status, res) {
                    res.destLatLon = destLatLon;
                    res.address = address;
                    callback(true, res);
                })
            }
        });
    }

    function getStreetAdresses(keyword, city, locality) {
        var length = keyword.length;
        var deferred = $.Deferred();
        var searchword = '';
        searchword += keyword;
        searchword += locality ? ", " + locality : '';
        searchword += ", " + city;

        function callback(predictions, status) {
            if (status != google.maps.places.PlacesServiceStatus.OK) {
                deferred.reject(status);
            }
            else {
                deferred.resolve(predictions);
            }
        }

        var params = {
            componentRestrictions: {country: 'in'},
            input: searchword,
            offset: length
        };

        var service = new google.maps.places.AutocompleteService();

        service.getPlacePredictions(params, callback);
        return deferred.promise();
    }

    function getTravelDistanceAndTime(key, origin, destination, callback) {
        if (origin.lat && origin.lng && destination.lat && destination.lng) {
            var service = new google.maps.DistanceMatrixService;
            service.getDistanceMatrix({
                origins: [origin],
                destinations: [destination],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false
            }, function (response, status) {
                if (status !== google.maps.DistanceMatrixStatus.OK) {
                    console.log('Error was: ' + status);
                } else {
                    var result = response.rows && response['rows'][0] && response['rows'][0]['elements'] && response['rows'][0]['elements'][0] ? response['rows'][0]['elements'][0] : '';

                    if (result.distance && result.duration) {
                        callback(true, result);
                    }
                }
            });
        }
    }

    function calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB) {
        console.log('calling directions called');
        directionsService.route({
            origin: pointA,
            destination: pointB,
            avoidTolls: true,
            avoidHighways: false,
            travelMode: google.maps.TravelMode.DRIVING
        }, function (response, status) {
            console.log('response ', status);
            if (status == google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
            } else {
                console.log('errr ', status);
            }
        });
    }

    return {
        getStreetAddressPredictions: function (keyword, city, locality) {
            return getStreetAdresses(keyword, city, locality);
        },
        getDistanceAndTime: function (add, projetLatLon, callback) {
            onStreetSelection(add, projetLatLon, callback);
        },
        getTravelDistanceAndTime: function (key, origin, destination, callback) {
            getTravelDistanceAndTime(key, origin, destination, callback);
        }
    };
}();