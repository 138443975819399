import {isSet, getterSetter, _tplRenderPromiss, connectionCheck, parseQueryParams, isValidProjectBannerFormfillSource, isMobile} from '../utils/common'
import {replyInit} from '../controller/replyController'
import {userMapping, intentMapping} from '../reply/replyservices'
import {eventTriggerQH, eventTriggeranalatyic} from '../services/gtmService'
import {getContactInitiateEvents, getEventId, getContactSubCategory} from '../services/gtmEvents'
import {gaLeadform} from '../services/gaLeadFormService';
import {getCookie} from '../services/cookieService'
var entityMapping = {};
export function projectReplyInit() {
    $('#commonpopup').on('show.bs.modal', function (e) {
        $(this).off('shown.bs.modal');
        renderReplyAd(e);
    });
    if(!isMobile()) {
        renderReplyAd('', 'static')
    }
}

export function renderReplyAd(e, formViewType) {
    getterSetter.set('downloadBrochure', false)
    var entity = 'IM_INTERESTED_IN_PROJECT';
    var event = ''
    entityMapping = {}
    entityMapping.emailList = []
    entityMapping.mobileList = []
    if (formViewType === 'static') {
        entityMapping.formViewType = 'static';
    } else {
        entity = e.relatedTarget.getAttribute('data-entity');
        event = e;
    }
    _tplRenderPromiss('replyAdnew').then(function (template) {
        if (isSet(template)) {
            var tpl = _.template(template);
            entityConfig(entity, event, entityMapping);
            if (formViewType !== 'static') {
                var replyModalContainer = document.querySelector('#commonpopup #reply_Container');
                var replyModalPopup = document.querySelector('#commonpopup.reply-modal');
                replyModalContainer.classList.remove('thnkup')
                replyModalPopup.classList.remove('thankyou')
            }
            replyInit(entityMapping, tpl);
        }
    })
}
export function entityConfig(enitytype,currentclick,entityMapping) {
    switch (enitytype) {    
        case "REQUIREMENT_POPUP_PROJECT":
            entityMapping.tilesDetails = globals.projectSnippet;
            entityMapping.tilesDetails.unitId = currentclick.relatedTarget.getAttribute('data-unit-detail')
            var unitFormate = currentclick.relatedTarget.getAttribute('data-unit-formate')
            getterSetter.set('adDetails', entityMapping.tilesDetails)
            entityMapping.showViewpart = true;
            getterSetter.set('isOtpverify', false)
            getterSetter.set('isdetectmobile', false)
            getterSetter.set('reqSource', 'REQUIREMENT_POPUP_PROJECT')
            getterSetter.set('sendEnquiry', false);
            getterSetter.set('formViewType', entityMapping.formViewType ? entityMapping.formViewType : '');
            gtmEvent();
            entityMapping.imageLogo = (entityMapping.tilesDetails.logoImageUrl ? window.CDN_URL + entityMapping.tilesDetails.logoImageUrl : '')
            entityMapping.headingValue = entityMapping.tilesDetails.name
            entityMapping.showText = `<b>Leave your details to know more about the project</b>`
            entityMapping.staticStrong = entityMapping.tilesDetails.address && entityMapping.tilesDetails.address.locality ? `<small>${entityMapping.tilesDetails.address.locality}</small>, ` : ''
            entityMapping.staticStrong += `<small>${entityMapping.tilesDetails.address.city}</small>`
            entityMapping.staticStrong += unitFormate ? `<small>&nbsp;|&nbsp; ${unitFormate}</small>` : ''
            entityMapping.userType = ''
            entityMapping.showBank = true
            entityMapping.chkCommercial = (entityMapping.tilesDetails.category.toLowerCase() === 'commercial')
            entityMapping.showNRI = true
            break;
        case "PROJECT_CONTACT_IN_PROJECT":
            entityMapping.tilesDetails = globals.projectSnippet;
            entityMapping.tilesDetails.unitId = currentclick.relatedTarget.getAttribute('data-unit-detail')
            var unitFormate = currentclick.relatedTarget.getAttribute('data-unit-formate')
			let formSubtype = currentclick.relatedTarget.getAttribute('data-subtype')
			var download = $(currentclick.relatedTarget).data('download');
            var gallery = $(currentclick.relatedTarget).data('gallery');
            getterSetter.set('adDetails', entityMapping.tilesDetails)
            entityMapping.showViewpart = true;
            getterSetter.set('isOtpverify', false)
            getterSetter.set('isdetectmobile', false)
            getterSetter.set('reqSource', 'PROJECT_UNIT_CONTACT_IN_PROJECT')
            getterSetter.set('sendEnquiry', false);
            getterSetter.set('formViewType', entityMapping.formViewType ? entityMapping.formViewType : '');
            entityMapping.tilesDetails.unitId ? getterSetter.set('reqSource', 'PROJECT_UNIT_CONTACT_IN_PROJECT') : getterSetter.set('reqSource', 'PROJECT_CONTACT_IN_PROJECT')
            if (download || gallery) {
                getterSetter.set('downloadBrochure', true)
                getterSetter.set('reqSource', 'PROJECT_CONTACT_IN_PROJECT')
            }
            gtmEvent();
            entityMapping.imageLogo = (entityMapping.tilesDetails.logoImageUrl ? window.CDN_URL + entityMapping.tilesDetails.logoImageUrl : '')
            entityMapping.headingValue = entityMapping.tilesDetails.name
            entityMapping.showText = `<b>Leave your details to know more about the project</b>`
            entityMapping.staticStrong = entityMapping.tilesDetails.address && entityMapping.tilesDetails.address.locality ? `<small>${entityMapping.tilesDetails.address.locality}</small>, ` : ''
            entityMapping.staticStrong += `<small>${entityMapping.tilesDetails.address.city}</small>`
            entityMapping.staticStrong += unitFormate ? `<small>&nbsp;|&nbsp; ${unitFormate}</small>` : ''
            entityMapping.userType = ''
            entityMapping.showBank = true
            entityMapping.chkCommercial = (entityMapping.tilesDetails.category.toLowerCase() === 'commercial')
            entityMapping.showNRI = true
			if(formSubtype == 'OFFER_CONTACT'){
				entityMapping.showText = `<b>Leave your details to know more</b>`
				entityMapping.imageLogo = currentclick.relatedTarget.getAttribute('data-image')
				entityMapping.headingValue = currentclick.relatedTarget.getAttribute('data-heading')
				entityMapping.staticStrong = currentclick.relatedTarget.getAttribute('data-name')
			}
            gaLeadform('contact_unit_init_click');
            break;
        case "CONTACT_IN_LISTING_PROJECT":
            var listingData = $(currentclick.relatedTarget).data('listing');
            entityMapping.tilesDetails = JSON.parse(atob(listingData));
            getterSetter.set('adDetails', entityMapping.tilesDetails)
            entityMapping.showViewpart = true;
            getterSetter.set('isOtpverify', false)
            getterSetter.set('isdetectmobile', false)
            getterSetter.set('reqSource', enitytype)
            getterSetter.set('sendEnquiry', false);
            getterSetter.set('formViewType', entityMapping.formViewType ? entityMapping.formViewType : '');
            gtmEvent();
            entityMapping.headingValue = entityMapping.tilesDetails.adTitle
            entityMapping.showText = `Intrested in this property, please share your details.`
            entityMapping.staticStrong = ` I'm interested! <b>Tell me more...</b>`
            entityMapping.userType = userMapping(entityMapping.tilesDetails)
            entityMapping.showBank = (intentMapping(entityMapping.tilesDetails) == 'buy')
            entityMapping.chkCommercial = (entityMapping.tilesDetails.category.toLowerCase() === 'commercial')
            entityMapping.showNRI = (intentMapping(entityMapping.tilesDetails) == 'buy')
            break;
        case "IM_INTERESTED_IN_PROJECT":
            var listingData = globals.projectSnippet
            entityMapping.tilesDetails = listingData;
            getterSetter.set('adDetails', entityMapping.tilesDetails)
            entityMapping.showViewpart = true;
            getterSetter.set('isOtpverify', false)
            getterSetter.set('isdetectmobile', false)
            
            // Parse the query params to check the source
            const queryParams = parseQueryParams();
            if (queryParams.has('qh_source')) {
                const source = queryParams.get('qh_source').toUpperCase();
                enitytype = isValidProjectBannerFormfillSource(source) ? source : enitytype;
            }
            
            getterSetter.set('reqSource', enitytype)
            getterSetter.set('sendEnquiry', true);
            getterSetter.set('formViewType', entityMapping.formViewType ? entityMapping.formViewType : '');
            gtmEvent();
            entityMapping.headingValue = entityMapping.tilesDetails.name
            entityMapping.staticStrong = entityMapping.tilesDetails.address.city
            entityMapping.showText = `I am interested in this project`

            //entityMapping.userType = userMapping(entityMapping.tilesDetails)
            entityMapping.showBank = true
            entityMapping.showNRI = true
            //entityMapping.chkCommercial = (entityMapping.tilesDetails.category.toLowerCase() === 'commercial') ? true:false
            //gaLeadform('contact_init_click');
            break;
    }
    return entityMapping;
}

export function gtmEvent() {
    var id_type = getEventId(getterSetter.get('reqSource'));
    var data = {};
    data['event'] = getContactInitiateEvents(getterSetter.get('reqSource'));
    data[id_type] = getterSetter.get('adDetails').id;
    data['SubCategory_Name'] = getContactSubCategory(getterSetter.get('reqSource'), getterSetter.get('adDetails'));
    data['user_db_id'] = getCookie('__u');
    if (connectionCheck()) {
        eventTriggerQH(data);
    } else {
        eventTriggeranalatyic(data);
    }
}
projectReplyInit();

