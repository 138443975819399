import {isSet} from '../utils/common'
import {addEvent} from '../utils/gaService'
import {ngGPlacesPro} from '../services/ngGPlacesPro'
export const projectMapController = function () {
	var destinations, showDestination1, showDestination2, projectMap, projectData, nearByPlacesSprite, config, directionsService, directionsDisplay,destination2,destination1;
	var htmlData = '';


	/// WONOBO-CODE-BLOCK: Initialize map for provided propert object on 'wonobo-project-page-map' dom
	function initializeWoNoBoMap(propertyObject) {
		$.getScript('https://s3.ap-south-1.amazonaws.com/resources.wonobo.com/clients/quikr/v0/libraries/wonoboMapService.min.js', function () {
			wonoboMapService.init(propertyObject, [{domID: 'wonobo-project-page-map'}]);
		});
	};
	/// WONOBO-CODE-BLOCK-END

	function initializeMap(lat, lng) {
		// Init map requires 4 steps , which are as follows
		//1- init map load
		//2- create project Marker
		//3- init crime map
		// 4- get commute data to show in commute section
		var mapOptions = {
			zoom: 14,
			center: new google.maps.LatLng(lat, lng),
			scrollwheel: false,
			minZoom: 5,
			disableDefaultUI: true,
			disableAutoPan: true,
			panControl: false,
			zoomControl: true,
			mapTypeControl: false,
			scaleControl: true,
			streetViewControl: false,
			overviewMapControl: false,
			rotateControl: false,
			draggable: true
		};

		$(document).ready(function () {
			config.map = new google.maps.Map(document.getElementById('project-page-map'), mapOptions);
			var project = {
				facilityName: config.data.name,
				location: {
					lat: lat,
					lon: lng,
					lng: lng
				}
			};
			var obj = initNearByMarkers(project, 'project');
			setEventsForNearbyMarkers(obj);
			//projectData.crimeMap.setData();

			$( '#project-locality li' ).on( 'click', function() {
	            $( this ).parent().find( 'li.active' ).removeClass( 'active' );
	            $( this ).addClass( 'active' );
	        });
		});
	}

	function initNearByMarkers(info, types) {
		//info.location.lng = info.location.lon;
		var latLngObj = new google.maps.LatLng(info.location.lat, info.location.lng),
			obj = {
				infoWindow: new google.maps.InfoWindow(),
				title: info.facilityName,
				marker: new google.maps.Marker({
					map: config.map,
					position: latLngObj,
					//title: info.facilityName,
					icon: config.nearByPlacesIcons[types],
					icon_h: config.nearByPlacesIcons[types + '_h'],
					icon_o: config.nearByPlacesIcons[types]
				})
			};
		return obj;
	}

	function initNearByMarkersNew(info, types) {
		//info.location.lng = info.location.lon;
		var latLngObj = new google.maps.LatLng(info.geometry.location.lat, info.geometry.location.lng),
			obj = {
				infoWindow: new google.maps.InfoWindow(),
				title: info.name,
				marker: new google.maps.Marker({
					map: config.map,
					position: latLngObj,
					//title: info.name,
					icon: config.nearByPlacesIcons[types],
					icon_h: config.nearByPlacesIcons[types + '_h'],
					icon_o: config.nearByPlacesIcons[types]
				})
			};
		return obj;
	}

	function setEventsForNearbyMarkers(obj, distance, time) {
		$(".gm-style-iw").parent("div").css({
			"display": "none"
		});

		google.maps.event.addListener(obj.marker, 'mouseout', function () {
			obj.marker.setIcon(obj.marker.icon_o);
			obj.infoWindow.close();
		});

		google.maps.event.addListener(obj.marker, 'mouseover', function () {
			obj.marker.setIcon(obj.marker.icon_h);
			var content = "<div><b>" + obj.title + "</b></div>";
			content = (distance && time) ? content + "<div style='margin-top: 5px;'>" + distance + " | " + time + "</div>" : content;
			obj.infoWindow.setContent(content);
			obj.infoWindow.open(config.map, obj.marker);
			$(".gm-style-iw").next("div").css({
				"display": "none"
			});
		});
	}

	function getLocalityData(catKey) {
		projectMapController.getCommuteAndLocalityData(config.data.latitude, config.data.longitude,catKey);
	}

	function getCommuteAndLocalityData(lat, lng,catKey) {
		// get all commute and locality data for the project
		addEvent('RealEstate','QH_vap_project','QH_vap_project_location_'+catKey+'_click')
		var query_obj = {
            lat: config.data.latitude,
            lon: config.data.longitude,
            distance: 5000,
            types: catKey
        };
        var url = queryBuilder(query_obj);
        $.ajax({
            url: url,
            method : "GET",
            success: function(data) {
            	data = JSON.parse(data)
            	config.facilities = data.results;
	            if (!config.localityItemClicked && config.facilities) {
					config.localityItemClicked = true;
					removeNearbyPlacesMarkers(config.nearByMarkers);
					directionsDisplay.setDirections({ routes: [] });
					config.nearByMarkers = [];
					var projectLatLng = new google.maps.LatLng(config.data.latitude, config.data.longitude);
					config.nearByLatLngBounds = new google.maps.LatLngBounds();
					config.nearByLatLngBounds.extend(projectLatLng);
					//
					if (catKey == 'bankAndAtm') {
					// 	// add bank and atm both
						LocationMarkersHandler(config.facilities.length,config.facilities,"atm");
						LocationMarkersHandler(config.facilities.length,config.facilities,"bank")
					}else if(catKey == 'placesOfWorship'){
						LocationMarkersHandler(config.facilities.length,config.facilities,"hinduTemple");
						LocationMarkersHandler(config.facilities.length,config.facilities,"church")
						LocationMarkersHandler(config.facilities.length,config.facilities,"mosque")
					}else{
						LocationMarkersHandler(config.facilities.length,config.facilities,catKey);
					}
					
					config.localityItemClicked = false;
				}
            }
        });
	}

	function getLocalDataForDestinations() {
		// get cached data for custom destinations available for the city
		if (window.localStorage) {
			var data = localStorage.getItem(config.data.address.cityId);

			if (data) {
				var data = JSON.parse(data);
				destinations[0] = data[0] ? data[0] : undefined;
				destinations[1] = data[1] ? data[1] : undefined;
			}
		}
	}

	function getSuggestedStreetAddress(key) {
		// This function is for autosuggesting places in commute section input
		var res;

		if (key.length % 3 == 0) {
			res = ngGPlacesPro.getStreetAddressPredictions(key, config.data.address.city, '');
		}
		return res;
	}

	function streetAddressSelection(label, btn, isPlotRoute) {
		// on select of auto suggested places
		// we call google services to get other details of the location like lat, lng, time n distance
		// hide input section after autosuggest
		// display route for the selection

		var projectLatLon = {
			'lat': (config && config.data) ? parseFloat(config.data.latitude) : '',
			'lng': (config && config.data) ? parseFloat(config.data.longitude) : ''
		};

		ngGPlacesPro.getDistanceAndTime(label, projectLatLon, function (status, result) {
			if (result.distance && result.duration) {
				var customCommute = {
					'facilityName': label,
					'icon': 'icon-location',
					'distance': result.distance.text,
					'time': result.duration.text,
					'location': result.destLatLon
				};
				// google api's lng conversion to our api's lon
				customCommute.location.lon = customCommute.location.lng;
				destinations[btn] = customCommute;
				addDistanceTime(destinations, btn);

				if (btn == 0) {
					showDestination1 = false;
				} else {
					showDestination2 = false;
				}
				setLocalDataForDestinations(destinations);
				//$scope.$apply();

				// set route between points
				if (isPlotRoute) {
					var pointA = new google.maps.LatLng(config.data.latitude, config.data.longitude);
					var pointB = label;
					displayRoute(pointA, pointB, destinations[btn]);
				}
			}
		});
	}

	function addDistanceTime(destinations, btn) {
		var timeId = "#time" + btn;
		var distanceId = "#distance" + btn;
		$('#customCommuteList ' + timeId).html(destinations[btn].time);
		$('#customCommuteList ' + distanceId).html(destinations[btn].distance);
	}

	function setLocalDataForDestinations(data) {
		// setting cityId as the key for destination1 & destination2
		// check localStorage available
		if (window.localStorage) {
			localStorage.setItem(config.data.address.cityId, JSON.stringify(data));
		}
	}

	function displayRoute(pointA, pointB, data) {
		// displays Route between pointA and pointB
		// remove routes if any
		// remove markers if any
		directionsDisplay.setMap(config.map);
		directionsDisplay.setDirections({ routes: [] });
		removeNearbyPlacesMarkers(config.nearByMarkers);
		config.nearByMarkers = [];
		directionsService.route({
			origin: pointA,
			destination: pointB,
			travelMode: 'DRIVING'
		}, function (response, status) {
			if (status === 'OK') {
				directionsDisplay.setDirections(response);
				var projectLatLng = new google.maps.LatLng(config.data.latitude, config.data.longitude);
				config.nearByLatLngBounds = new google.maps.LatLngBounds();
				config.nearByLatLngBounds.extend(projectLatLng);
				initNearByMarkersHandler(1, [data], 'end');
			} else {
				console.log('Directions request failed due to ' + status);
			}
		});
	}
	function queryBuilder(query_param) {
	        var FULL_URL = '/homes/re2/google_places';
	        FULL_URL += '?location=' + query_param.lat + ',' + query_param.lon;
	        FULL_URL += '&radius=' + query_param.distance;
	        FULL_URL += '&type=' + query_param.types;
	        if ('airport' != query_param.types) {
	            FULL_URL += '&rankBy=distance';
	        }
	        return FULL_URL;
	}
	window.plotLocalityPlaces = (catKey)=> {

		// check for the locality clicked
		// remove all markers from map
		// remove routes if any
		// limit and data should change for bank atm , and places of worship
		// put new markers on the map
		// set events for the markers
		// fit bounds for markers
		getLocalityData(catKey)
	}

	window.resetDestination = (btn)=> {
		// reset custom commute destinations and inout model
		// set local data

		if (btn == 0) {
			destination1 = undefined;
			showDestination1 = true;
			destinations[btn] = null;
			$('#des1').show();
			$('input[name="destination1"]').val('').show();
		} else {
			destination2 = undefined;
			showDestination2 = true;
			destinations[btn] = null;
			$('#des2').show();
			$('input[name="destination2"]').val('').show();
		}

		setLocalDataForDestinations(destinations);
	}

	window.displayCustomRoute = (data, isCustom)=> {

		if(data == 0){
            addEvent('RealEstate','QH_vap_project','QH_vap_project_location_destination1_click')
        }else{
            addEvent('RealEstate','QH_vap_project','QH_vap_project_location_destination2_click')
        }

		if (isCustom) {
			data = JSON.parse(atob(data));
		} else {
			if (data === '0' || data === '1') {
				data = destinations[data];
			} else {
				data = projectMap.commuteOutputObj[data];
			}
		}

		var pointA = new google.maps.LatLng(config.data.latitude, config.data.longitude);
		var pointB = new google.maps.LatLng(data.location.lat, data.location.lon);
		displayRoute(pointA, pointB, data);
	}

	function removeNearbyPlacesMarkers(markersArr) {
		// removes markers available on the map
		for (var c = 0; c < markersArr.length; c++) {
			markersArr[c].setMap(null);
		}
	}

	function initNearByMarkersHandler(limit, locations, catKey) {
		if (limit == 0) return;
		for (var c = 0; c < limit; c++) {
			locations[c].location.lng = locations[c].location.lon;
			var latLngObj = new google.maps.LatLng(locations[c].location.lat, locations[c].location.lng);
			var obj = initNearByMarkers(locations[c], catKey);
			config.nearByLatLngBounds.extend(latLngObj);
			config.nearByMarkers.push(obj.marker);
			setEventsForNearbyMarkers(obj, locations[c].distance, locations[c].time);
		}
	}

	function LocationMarkersHandler(limit, locations, catKey) {
		if (limit == 0) return;
		for (var c = 0; c < limit; c++) {
			//locations[c].geometry.location.lng = locations[c].geometry.location.lon;
			var latLngObj = new google.maps.LatLng(locations[c].geometry.location.lat, locations[c].geometry.location.lng);
			var obj = initNearByMarkersNew(locations[c], catKey);
			config.nearByLatLngBounds.extend(latLngObj);
			config.nearByMarkers.push(obj.marker);
			setEventsForNearbyMarkers(obj);
		}
	}
	var FETCH_PROJECT_FACILITIES = '/homes/re2/projectFacilities';

	function fetchProjectFacilities(postData, callBacks) {
		$.ajax({
			url: FETCH_PROJECT_FACILITIES,
			type: "POST",
			data: postData,
			success: callBacks.success,
			error: callBacks.error
		});
	}

	function createCommuteData(data) {
		// office data is not available in api for commute tab
		var result = {};
		if (data.airport[0]) {
			result.airport = data.airport[0];
			result.airport.icon = config.commuteIcons['airport'];
		}
		if (data.railwayStation[0]) {
			result.railwayStation = data.railwayStation[0];
			result.railwayStation.icon = config.commuteIcons['railwaystation'];
		}
		if (data.metroStation[0]) {
			result.metroStation = data.metroStation[0];
			result.metroStation.icon = config.commuteIcons['metrostation'];
		}
		return result;
	}

	function init() {
		destinations = [];
		showDestination1 = true;
		showDestination2 = true;
		projectMap = {
			commuteOutputObj: {},
			locality: [{
				'key': 'school',
				'name': 'Schools',
				'icon': 'icon-school'
			}, {
				'key': 'hospital',
				'name': 'Hospitals',
				'icon': 'icon-hospitals'
			}, {
				'key': 'bankAndAtm',
				'name': 'Bank & ATMs',
				'icon': 'icon-bank-attached-property'
			}, {
				'key': 'groceryOrSupermarket',
				'name': 'Supermarket',
				'icon': 'icon-supermarket'
			}, {
				'key': 'gym',
				'name': 'Gym',
				'icon': 'icon-gymnasium'
			}, {
				'key': 'placesOfWorship',
				'name': 'Places of worship',
				'icon': 'icon-Temple'
			}]
		};
		projectData = {
			crimeMap: {
				class: 'button-white',
				apiKey: 'quikr-sanbox',
				display: false,
				displayTab: false
			}
		};

		projectData.crimeMap.setData = function () {
			// crime map init configurations
			var cityId = config.data.cityId || config.data.address.cityId || null;
			if ([23, 27].indexOf(parseInt(cityId)) !== -1) {
				projectData.crimeMap.displayTab = true;
			}
		};

		projectData.crimeMap.show = function () {
			// loads crime map with configurations
			projectData.crimeMap.display = !(projectData.crimeMap.display);
			var label = projectData.crimeMap.display ? 'click_crime_data_view_on' : 'click_crime_data_view_off';
			projectData.crimeMap.class = projectData.crimeMap.display ? 'button-active' : 'button-white';
			gaService.addEvent('button-RealestateResponsiveUI', 'Project_Real-Estate', label);
			//if($scope.projectData.crimeMap.display) $scope.addEvent('button-RealestateResponsiveUI', 'Project_Real-Estate', 'api_hit_crime_data');
			projectData.crimeMap.url = 'http://data.getupforchange.com/' + config.data.address.city.toLowerCase() + '/crimemap/' + projectData.crimeMap.apiKey + '/' + parseFloat(config.data.latitude) + '/' + parseFloat(config.data.longitude) + '/14';
		};

		nearByPlacesSprite = staticImageHost+'restatic/image/icon-map-sprite-project.png';
		config = {
			data: sample,
			map: {},
			nearByLatLngBounds: new google.maps.LatLngBounds(),
			facilities: {},
			localityItemClicked: false,
			nearByMarkers: [],
			commuteIcons: {
				'airport': 'icon-airport',
				'school': 'icon-school',
				'hospital': 'icon-hospitals',
				'railwaystation': 'icon-railway-station',
				'metrostation': 'icon-railway-station'
			},
			nearByPlacesIcons: {

				"project": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(82, 369)),
				"project_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(121, 369)),

				"end": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(82, 421)),
				"end_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(121, 421)),

				"school": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(82, 4)),
				"school_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(121, 4)),

				"hospital": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(82, 56)),
				"hospital_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(121, 56)),

				"atm": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(4, 108)),
				"atm_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(44, 108)),


				"bank": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(4, 213)),
				"bank_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(44, 213)),

				"groceryOrSupermarket": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(4, 161)),
				"groceryOrSupermarket_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(44, 161)),

				"gym": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(4, 265)),
				"gym_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(44, 265)),

				"hinduTemple": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(4, 317)),
				"hinduTemple_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(44, 317)),

				"church": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(82, 317)),
				"church_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(121, 317)),

				"mosque": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(4, 369)),
				"mosque_h": new google.maps.MarkerImage(nearByPlacesSprite, new google.maps.Size(37, 38), new google.maps.Point(44, 369))
			}
		};
		directionsService = new google.maps.DirectionsService;
		//directionsDisplay = new google.maps.DirectionsRenderer;
		directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true });

		getLocalDataForDestinations();
		initializeMap(config.data.latitude, config.data.longitude);


		return {
			destinations: destinations,
			showDestination1: showDestination1,
			showDestination2: showDestination2,
			projectMap: projectMap,
			projectData: projectData,
			nearByPlacesSprite: nearByPlacesSprite,
			config: config,
			directionsService: directionsService,
			directionsDisplay: directionsDisplay
		}
		
	}

	return {
		init: init,
		displayCustomRoute: displayCustomRoute,
		resetDestination: resetDestination,
		plotLocalityPlaces: plotLocalityPlaces,
		getSuggestedStreetAddress: getSuggestedStreetAddress,
		streetAddressSelection: streetAddressSelection,
		getLocalityData: getLocalityData,
		getCommuteAndLocalityData: getCommuteAndLocalityData
	};
}();

//$(function () {
//    projectMapController.init();
//});
