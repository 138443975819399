let touchstartX = 0;
let touchendX = 0;
let count = 0 ;
let touchStartY = 0;
let touchEndY = 0;
let min_x = 80;  //min x swipe for horizontal swipe
let max_y = 20;//max y difference for horizontal swipe
export function swiping() {
    let javaScriptData = atob(sessionStorage.getItem("vapUrls"));
    //console.log(javaScriptData);
    javaScriptData = JSON.parse(javaScriptData);
    let urlRedirects = [];
    for (var i =0 ;i<javaScriptData.length; i++){
        urlRedirects.push(javaScriptData[i]['seoVapUrl']);
    }
    //console.log(urlRedirects);
    sessionStorage.setItem('index',urlRedirects.indexOf(window.location.href));

    window.addEventListener('touchstart', function(event) {
        touchstartX = event.changedTouches[0].screenX;
        touchStartY = event.changedTouches[0].screenY;
    }, false);
    
    window.addEventListener('touchend', function(event) {
        if( $(event.target).closest("#lightbox").length > 0 || $(event.target).closest("#sellTab").length > 0 || $(event.target).closest("#rentTab").length > 0
            || $(event.target).closest("#SimilarSearchProject").length > 0 || $(event.target).closest("#projectSimilarSearch").length > 0 
                || $(event.target).closest("#swipe_hint").length > 0 || $(event.target).closest("#similar_serach_link_swipe").length > 0  )
        {
             return false; 
        }
        touchendX = event.changedTouches[0].screenX;
        touchEndY = event.changedTouches[0].screenY;
        handleGesture(count);
    }, false);
    //handleGesture(count);
}

function handleGesture(count) {
    //console.log("x diff "+Math.abs(touchendX - touchstartX ));
    //console.log("Y diff "+Math.abs(touchEndY-touchStartY))
    if ((Math.abs(touchendX - touchstartX ) > min_x) && ((Math.abs(touchEndY-touchStartY) < max_y) && (touchendX > 0)))
    {
    if (touchendX < touchstartX) {
       /**
        * adding behavioiur to the left Swipe
        */
       let index = parseInt(sessionStorage.getItem("index"))+ 1;
        let javaScriptData = atob(sessionStorage.getItem("vapUrls"));
        if (JSON.parse(javaScriptData).length >= index ) {
            updateIndexForTheUrl(index);
            sessionStorage.setItem("index",index);
        }
        
    }
    if (touchendX > touchstartX) {
        /**
         * Adding behaviour to the right swipe 
         */
        let index = parseInt(sessionStorage.getItem("index"))-1;
        if ( index >=0){
            updateIndexForTheUrl(index);
            sessionStorage.setItem("index",index);
        }
    }
    }
}

function updateIndexForTheUrl(count) {
    let javaScriptData = atob(sessionStorage.getItem("vapUrls"));
    let urlRedirect = JSON.parse(javaScriptData)[count]['seoVapUrl'];
    location.href = urlRedirect;
}
