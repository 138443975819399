import {loginService} from '../services/loginService'
import {isSet,getterSetter,propertyType,jsUcfirst} from '../utils/common'
var pageType = globals.pageType
var Id = globals.Id;
var PRIMARY_INP_CTR = '.review-inp-ctr';
var that = window,
	userId = null,
	userPostedData = null,
	entityTypes = ['review', 'update', 'question'],
	userIdArr = [],
	loggedIn = false,
	defaultMsg = 'Add Photos or Drag Photos here',
	usrArdyRw = "User already reviewed",
	entityType, entityId, delIndex, delSecondaryIndex, modalInstance;

var review_primarytype;
that.Math = Math;
that.currDate = Date.now();
that.reviewUser = null;
that.anonymousAction = [];
that.userIdMap = [];
that.commentAs = [];
that.keepAnonymous = [];
that.primaryType = 'review';
that.showWriteReview = false;
that.reviewPosted = false;
that.getName = getName;
that.getAddress = getAddress;
that.getProfileUrl = getProfileUrl;
that.getReviews = getReviews;
that.showEntity = showEntity;
that.postEntityData = window.postEntityData;
that.openLightboxModalReviews = openLightboxModalReviews;
that.postComment = window.postComment;
that.toggleAnonymous = window.toggleAnonymous;
that.showMoreImgs = showMoreImgs;
that.toggleWriteReview = toggleWriteReview;
that.openMainReview = openMainReview;
that.toReviewInput = window.toReviewInput;
that.editPrimaryData = window.editPrimaryData;
that.showDeleteModal = window.showDeleteModal;
that.deleteEntityData = window.deleteEntityData;
that.updateVotes = window.updateVotes;
that.updateCommentVotes = updateCommentVotes;
that.showAllEntityData = window.showAllEntityData;
that.hideWriteReview = window.hideWriteReview;

that.reviewMinLimit = that.updateMinLimit = 50;
that.reviewMaxLimit = that.updateMaxLimit = 4000;
that.questionMinLimit = that.commentMinLimit = 0;
that.questionMaxLimit = that.commentMaxLimit = 2000;
that.isAnony = isAnony;

var Review = {
	init: function () {
		if (showWriteReview) {
			$("#showWriteReview").css('display', 'none');
		} else {
			$("#showWriteReview").css('display', 'block');
		}

		if ((that.primaryType === 'review' ? !that.reviewPosted : true ) && !that.showWriteReview) {

		}
		review_primarytype = 'Write your ' + that.primaryType;
		$("#review_primarytype").html(review_primarytype);
		getLoggedInUserReview();
		if(document.querySelector("#imgupload")) {
		    document.querySelector("#imgupload").addEventListener('click',imageuploadGA);
		}
	}
}

var docCookies = {

	getItem: function (sKey) {
		if (!sKey) {
			return null;
		}
		return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
	},

	setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
		if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
			return false;
		}
		var sExpires = "";
		if (vEnd) {
			switch (vEnd.constructor) {
				case Number:
					sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
					break;
				case String:
					sExpires = "; expires=" + vEnd;
					break;
				case Date:
					sExpires = "; expires=" + vEnd.toUTCString();
					break;
			}
		}
		document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
		return true;
	},

	removeItem: function (sKey, sPath, sDomain) {
		if (!this.hasItem(sKey)) {
			return false;
		}
		document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
		return true;
	},

	hasItem: function (sKey) {
		if (!sKey) {
			return false;
		}
		return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
	},

	keys: function () {
		var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
		for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
			aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
		}
		return aKeys;
	}
};

$(function () {
	Review.init();
});
/* function addEvent(cat, action, label, forComment) {
	if ((forComment === 'comment')) {
		label = entityType[0].toUpperCase() + entityType.slice(1) + '_' + 'Comment' + label;
	} else {
		label = entityType[0].toUpperCase() + entityType.slice(1) + '_' + that.primaryType[0].toUpperCase() + that.primaryType.slice(1) + label;
	}
	window.addEvent(cat, action, label);
} */

window.redirectUrl = ()=> {
    var pathname = window.location.pathname;
    var pathUrl = encodeURIComponent(pathname);
    window.location.href = "http://www.quikr.com/SignIn?redirect_homes=" + pathUrl;
}

function openMainReview() {
	$scope.$parent.$parent.goToSection('reviews');
	that.hideImgUpload && $rootScope.$broadcast('toggleReview');
}
function onLogin(data) {

	if (data && data.data && data.data.user && data.data.user.__user_auth) {
		var user_auth = JSON.parse(data.data.user.__user_auth);
		that.reviewUserId = userId = user_auth.__u;
	}

	var details = data.data.user.__user_auth;
	details && (that.reviewUserId = userId = details.__u);
	loggedIn = true;
	that.editedDataIndex = null;

	if (that.primaryType === 'review') {
		postEntityData();
	} else {
		getEntityData(true);
	}
}

function getName(userId, isAnonymous) {
	if (isAnonymous) {
		return 'Anonymous';
	} else if (userId && that.userIdMap[userId]) {
		if (that.userIdMap[userId].name && that.userIdMap[userId].name.indexOf('@') == -1) {
			return that.userIdMap[userId].name;
		} else {
			if (that.userIdMap[userId].email) {
				return that.userIdMap[userId].email.split('@')[0];
			} else {
				return 'Quikr Homes User';
			}
		}
		return (that.userIdMap[userId] && that.userIdMap[userId].name) ? that.userIdMap[userId].name : '';
	} else {
		return 'Quikr Homes User';
	}
}

function getAddress(userId) {
	if (userId) {
		return (that.userIdMap[userId] && that.userIdMap[userId].locality) ? that.userIdMap[userId].locality : '';
	} else {
		return '';
	}
}

function getProfileUrl(userId) {
	if (userId && that.userIdMap[userId]) {
		return that.userIdMap[userID].profileurl || false;
	} else {
		return false;
	}
}

function disabledTitle() {
	if (that.primaryType === 'review' && (!that.reviewInput)) {
		document.querySelector('.post-review-btn.w-dis-btn').setAttribute('title', 'review text is mandatory.');
	} else if (!that.reviewInput) {
		document.querySelector('.post-review-btn.w-dis-btn').setAttribute('title', that.primaryType + ' text is mandatory.');
	} else {
		document.querySelector('.post-review-btn.w-dis-btn').removeAttribute('title');
	}
}

function tabSet(e, data, enty) {
	var tab = data;
	displayWriteReview();
	showEntity(enty);
}

window.tabSetting = function (val, enty) {
	tabSet('tabSet', val, enty);
	var imgThumbEls = document.querySelectorAll('#imgPreview .dz-image-preview') || [];
	var thumbTitleEls;
	if(imgThumbEls){
		for (var i = 0; i < imgThumbEls.length; i++) {
			imgupload && imgupload.dropzone.previewsContainer.removeChild(imgThumbEls[i]);
		}
		if(document.querySelector(".dz-default.dz-message")){
			document.querySelector(".dz-default.dz-message").innerHTML = '<span>' + defaultMsg + '</span>';
		}
		thumbTitleEls = document.querySelectorAll('.attach-title');
		if(thumbTitleEls.length){
			thumbTitleEls[0].classList.add('hide');
			thumbTitleEls[1].classList.add('hide');
		}
	}
	changeTab(enty);
}

function changeTab(elem) {
	if (elem == "review") {
		$("#reviewtab").addClass("active");
		$("#updatetab").removeClass("active");
		$("#questiontab").removeClass("active");
	}
	else if (elem == "update") {
		$("#reviewtab").removeClass("active");
		$("#updatetab").addClass("active");
		$("#questiontab").removeClass("active");
	}
	else if (elem == "question") {
		$("#reviewtab").removeClass("active");
		$("#updatetab").removeClass("active");
		$("#questiontab").addClass("active");
	}
	else {
		$("#reviewtab").addClass("active");
		$("#updatetab").removeClass("active");
		$("#questiontab").removeClass("active");
	}
}

function getReviews(entity_type, entity_id, sideReview) {
	entityType = entity_type;
	entityId = entity_id;
	getEnitiesCount();

	that.hideImgUpload = sideReview;
	showEntity('review');
	if (!sideReview) {
		// if (!imgupload.dropzone) {
		//  Dropzone.discover();
		//  var ele = document.querySelector(config.IMG_THUMB_CTR);
		//  ele.onclick = function () {
		//      addEvent('button-RealestateResponsiveUI', 'R&R_Real-Estate', 's_AddImages');
		//  };
		// }
	}
}

function startGuestSession() {
	var key = getCookie("guest_key");
	if (key) {
		that.guestKey = key;
	} else {
		$.ajax({
			url: "/homes/re2/guest/startSession?aj=1",
			method: "GET",
			success: function (data) {
				data =  JSON.parse(data);
				if (data.statusCode='200' && data.data) {
					that.guestKey = data.data.sessionKey;
					setCookie('guest_key', data.data.sessionKey);
				}
			}
		});
	}
}

function checkIsIPV4(entry) {
	if (!entry) {
		return '';
	}
	var blocks = entry.split(".");
	if (blocks.length === 4) {
		return blocks.every(function (block) {
			return parseInt(block, 10) >= 0 && parseInt(block, 10) <= 255;
		});
	}
	return false;
}

function getDomain(hostname) {
	if (!hostname) {
		return '';
	}
	var parts = location.hostname.split('.');
	var subdomain = parts.shift();
	var upperleveldomain = parts.join('.');
	return upperleveldomain;
}


function getCookie(key) {
	return docCookies.getItem(key);
}

function setCookie(key, value) {
	var domain = checkIsIPV4(location.hostname) ? location.hostname : ('.' + getDomain(location.hostname));
	return docCookies.setItem(key, value, cookieExpiry(365), '/', domain);
}

function cookieExpiry(days) {
	return new Date(Date.now() + days * 24 * 3600 * 1000);
}

function getLoggedInUserReview() {
	if (getterSetter.loginstatus) {
        var details = getterSetter.get('loginData');
        that.reviewUserId = userId =  details.data.user.id;
    }
    startGuestSession();
    getReviews(pageType, Id);
	tabSetting('r', 'review');
}

function entityCount(data) {
	var pType;
	that.reviewCount = data.Review || 0;
	that.updateCount = data.Update || 0;
	that.questionCount = data.Question || 0;
	$(".reviewCount").html(that.reviewCount);
	$(".updateCount").html(that.updateCount);
	$(".questionCount").html(that.questionCount);
}

function getEnitiesCount() {
	$.ajax({
		url: "/homes/re2/reviews/count?aj=1",
		method: "POST",
		data: {entityType: pageType, entityId: Id},
		success: function (data) {
			data =  JSON.parse(data);
			if (data && !data.error) {
				that.reviewCount = data.Review;
				that.updateCount = data.Update;
				that.questionCount = data.Question;
				entityCount(data);
				//$rootScope.$broadcast('entityCount', data);
			} else {
				that.reviewCount = 0;
				that.updateCount = 0;
				that.questionCount = 0;
			}
			var checkReviews = (that.reviewCount > 1) ? "Reviews" : "Review";
			$("#totalReviewsStr").html(that.reviewCount + ' ' + checkReviews);
		}
	});
}


function showEntity(primaryType) {
	that.primaryType = primaryType;
	that.primaryTypeInp = 'Write your ' + primaryType;
	that.primaryPostAction = false;
	userIdArr = [];

	that.wrtPrimaryData = 'Post ' + primaryType;
	that.reviewPostedMsg = null;

	review_primarytype = 'Write your ' + that.primaryType;
	$("#review_primarytype").html(review_primarytype);

	$(".review_primaryTypeInp").html(that.primaryTypeInp);
	$('#reviewInput').attr("placeholder", that.primaryTypeInp);
	$(".wrtPrimaryData").html(that.wrtPrimaryData);
	getEntityData();
}


window.toReviewInput = function () {
	that.hideImgUpload && anchorSmoothScrollService.scrollTo('review-input');
}
function setUserIdMap() {
	if (userId && (userIdArr.indexOf(userId) === -1)) {
		userIdArr.push(userId);
	}

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	if (that.entityReviews && that.entityReviews.primaryReturnList && that.entityReviews.primaryReturnList.length) {
		that.entityReviews.primaryReturnList.forEach(function (primaryData) {
			(userIdArr.indexOf(primaryData.userID) === -1) && userIdArr.push(primaryData.userID);
			primaryData.replies && primaryData.replies.forEach(function (comment) {
				(userIdArr.indexOf(comment.userID) === -1) && userIdArr.push(comment.userID);
			});
		});
	}

	if (userIdArr.length) {
		$.ajax({
			url: "/homes/re2/reviews/user?aj=1",
			method: "POST",
			data: {userId: userIdArr},
			success: function (data) {
				data =  JSON.parse(data);
				if (data && !data.error) {
					that.userIdMap = data;
					setReviewHtml(that.entityReviews);
				}
			}
		});
	} else {
		setReviewHtml(that.entityReviews);
	}
}

function showUserPostedData(data) {
	var commentText = '',
		el;

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	if (!that.entityReviews.primaryReturnList) {
		that.entityReviews.primaryReturnList = [data];
	} else {
		that.entityReviews.primaryReturnList.forEach(function (primaryData, index) {
			if (data._Id === primaryData._Id) {
				if (primaryData.commentInp) {
					commentText = primaryData.commentInp;
				}
				that.entityReviews.primaryReturnList.splice(index, 1);
			}
		});
		if (commentText) {
			data.commentInp = commentText;
		}
		that.entityReviews.primaryReturnList.unshift(data);
	}
}

function getUserPostedData() {
	userPostedData = null;
	$.ajax({
		url: "/homes/re2/reviews/user?aj=1",
		method: "POST",
		data: {entityType: pageType, entityId: Id, userId: userId, primaryType: that.primaryType},
		success: function (data) {
			data =  JSON.parse(data);
			if (!data.error && data.Status !== 'FAILED') {
				that.reviewPosted = true;
				userPostedData = data;
				showUserPostedData(userPostedData);
			}
		}
	});
}

function getEntityData(isLogin) {
	$.ajax({
		url: "/homes/re2/reviews/show?aj=1",
		method: "POST",
		data: {
			entityType: pageType,
			entityId: Id,
			userId: userId,
			primaryType: that.primaryType,
			page: 0,
			size: 4,
			sortBy: 'date'
		},
		success: function (data) {
			data =  JSON.parse(data);
			if (data && !data.error) {
				that.entityReviews = data;
				if (!isLogin && document.querySelector(PRIMARY_INP_CTR) && !document.querySelector(PRIMARY_INP_CTR).classList.contains('ng-hide')) {
					that.toggleWriteReview({}, true);
				}

				if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
					entityReviews.primaryReturnList.splice(0, 1);
				}

				if (that.entityReviews && that.entityReviews.primaryReturnList && that.entityReviews.primaryReturnList.length) {
					that.entityReviews.primaryReturnList.forEach(function (primaryData) {
						if(primaryData.userID==userId){
							$(".review_primaryTypeInp").hide();
							$("#review_primarytype").hide();
						}
					});
				}

				if (that.primaryType === 'review' && userId) {
					getUserPostedData();
				}
				setUserIdMap();
			}
		}
	});
}


function setReviewHtml(entityReviews) {
	var reviewhtml = '';
	var sideReviewhtml = '';

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}
	
	if (entityReviews.primaryReturnList.length) {
		$("#displaycountReview").css('display', 'block');
		$("#displaycountReview").html(that.primaryType + 's');
	} else {
		$("#displaycountReview").css('display', 'none');
	}

	var pType;
	switch (that.primaryType) {
		case 'review':
			pType = that.reviewCount;
			break;
		case 'update':
			pType = that.updateCount;
			break;
		case 'question':
			pType = that.questionCount;
			break;
	}

	for (var i = 0; i < entityReviews.primaryReturnList.length; i++) {
		var primaryData = entityReviews.primaryReturnList[i];
		reviewhtml += '<div class="r-c-details" data-id="' + primaryData._Id + '">';
		reviewhtml += '<div class="usr-review">';
		reviewhtml += '<div class="usr-comment-box"><div class="usr-details">';
		var profilesrc = '';
		var ProfileClass = '';
		if (isSet(that.userIdMap) && isSet(that.userIdMap[primaryData.userID]) && isSet(that.userIdMap[primaryData.userID].profileurl)) {
			profilesrc = that.userIdMap[primaryData.userID].profileurl;
			ProfileClass = 'profileImg';
		}
		reviewhtml += '<div class="usr-img" class="' + ProfileClass + '">';
		if (profilesrc != '') {
			reviewhtml += '<img src="' + profilesrc + '"></div></div>';
		} else {
			reviewhtml += '<i class="icon-user"></i></div></div>'
		}
		reviewhtml += '<div class="usr-name">' + getName(primaryData.userID) + '<span> ' + getAddress(primaryData.userID) + '</span></div>';

		var timeDiff = Math.round((that.currDate - primaryData.created_at) / 86400000);
		timeDiff = (timeDiff > 1) ? timeDiff + 'days' : timeDiff + 'day';

		var Pd_Created_at = new Date(primaryData.created_at)
		var months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
		var created_at = months[Pd_Created_at.getMonth()] + ' ' + Pd_Created_at.getDate() + ', ' + Pd_Created_at.getFullYear();
		reviewhtml += '<span class="review-time pull-left">' + timeDiff + ' ago /' + created_at + '</span>';
		if (primaryData.created_at !== primaryData.modified_at) {
			reviewhtml += '<span class="edit-lbl pull-right" >Edited</span>';
			reviewhtml += '<span class="separator pull-right">&nbsp;.&nbsp;</span>';
		}
		reviewhtml += '</div>';

		if (that.reviewUserId === primaryData.userID) {
			$(".review_primaryTypeInp").hide();
			$("#review_primarytype").hide();
			sideReviewhtml = reviewhtml;
			reviewhtml += '<a class="data-action-icn right0"  title="Delete" onclick="window.showDeleteModal(' + i + ',null)"><i class="fa icon-trash"></i></a>';
			reviewhtml += '<a class="data-action-icn right27"  title="Edit" onclick="editPrimaryData(' + i + ');"><i class="fa icon-edit"></i></a>';

			sideReviewhtml += '<a class="data-action-icn right0"  title="Delete" ><i class="fa icon-trash"></i></a>';
			sideReviewhtml += '<a class="data-action-icn right27"  title="Edit" ><i class="fa icon-edit"></i></a>';
		}

		reviewhtml += '<div class="review-box"><div class="review-content"><div class="review-text">' + primaryData[that.primaryType + "_Text"]+'</div>';
		sideReviewhtml += '<div class="review-box"><div class="review-content"><div class="review-text">' + primaryData[that.primaryType + "_Text"]+'</div>';
		 
		if (primaryData.hasOwnProperty('attachments')) {
			for (var j = 0; j < primaryData.attachments.length; j++) {
				var attachmentData = primaryData.attachments[j];
				var atachClass = '';
				if (attachmentData.attachment !== null) {
					atachClass = 'review-attachments';
				}
				reviewhtml += '<div class="' + atachClass + '">';
				sideReviewhtml += '<div class="' + atachClass + '">';
				if (attachmentData.hasOwnProperty('attachment') && attachmentData.attachment && attachmentData.attachment.length) {
					for (var k = 0; k < attachmentData.attachment.length; k++) {
						var attachment = attachmentData.attachment[k];
						if (k < 2 && attachment.content !== '') {
							reviewhtml += '<a  onclick="that.LightboxPopup('+i+','+j+','+k+')">';
							reviewhtml += '<img class="review-img" src="' + attachment.content + '" /></a>';

							sideReviewhtml += '<a><img class="review-img" src="' + attachment.content + '" /></a>';
						}
					}
				}

				if (attachmentData.hasOwnProperty('attachment') && attachmentData.attachment && attachmentData.attachment.length > 2) {
					reviewhtml += '<a class="more-review-imgs review-img"  onclick="that.LightboxPopup('+i+','+j+',2)">';
					reviewhtml += '<img class="review-img" src="' + attachmentData.attachment[2].content + '">';
					reviewhtml += '<div class="more-review-img-num">+' + (attachmentData.attachment.length - 2) + '</div>';
					reviewhtml += '</a>';

					sideReviewhtml += '<a class="more-review-imgs review-img">';
					sideReviewhtml += '<img class="review-img" src="' + attachmentData.attachment[2].content + '">';
					sideReviewhtml += '<div class="more-review-img-num">+' + (attachmentData.attachment.length - 2) + '</div>';
					sideReviewhtml += '</a>';
				}
				reviewhtml += '</div>';
				sideReviewhtml += '</div>';
			}
		}
		reviewhtml += '</div>';

		reviewhtml += '<div class="likedislike">';
		reviewhtml += '<span>' + primaryData.upvotes + ' Likes</span>';
		reviewhtml += '<span>' + primaryData.downvotes + ' Dislikes</span>';
		reviewhtml += '<span>' + primaryData.reply_count + ' comments</span>';
		reviewhtml += '</div>';

		sideReviewhtml += '</div>';
		sideReviewhtml += '<div class="likedislike">';
		sideReviewhtml += '<span>' + primaryData.upvotes + ' Likes</span>';
		sideReviewhtml += '<span>' + primaryData.downvotes + ' Dislikes</span>';
		sideReviewhtml += '<span>' + primaryData.reply_count + ' comments</span>';
		sideReviewhtml += '</div>';
		
		var likeclass=''; 
		var dislikeclass='';
		
		if (primaryData.vote === 1) {
			likeclass = 'blue';
			if ($('.thumbs-dn').hasClass('blue')) {
				$('.thumbs-dn').removeClass('blue');
			}
		}
		if (primaryData.vote === -1) {
			dislikeclass = 'blue';
			if ($('.thumbs-up').hasClass('blue')) {
				$('.thumbs-up').removeClass('blue');
			}
		}
	   
		reviewhtml += '<div class="thumbs-up ' + likeclass + '"  onclick="window.updateVotes( true, ' + i + ' );"><i class="thumbs-icn icon-thumbs-up"></i><span class="thumbs-up-cnt">Like</span></div>';
		reviewhtml += '<div class="thumbs-dn ' + dislikeclass + '"  onclick="window.updateVotes( false, ' + i + ');"><i class="thumbs-icn icon-thumbs-down"></i><span class="thumbs-dn-cnt">Dislike</span></div>';
		reviewhtml += '</div>';
		reviewhtml += '</div>';

		reviewhtml += '<div class="usr-comment-panel">';
		reviewhtml += '<div class="comment-box">';
		reviewhtml += '<div class="comments-ctr" id="comment_ctr"' + i + '>';

		sideReviewhtml += '<div class="thumbs-up ' + likeclass + '"  ><i class="thumbs-icn icon-thumbs-up"></i><span class="thumbs-up-cnt">Like</span></div>';
		sideReviewhtml += '<div class="thumbs-dn ' + dislikeclass + '" ><i class="thumbs-icn icon-thumbs-down"></i><span class="thumbs-dn-cnt">Dislike</span></div>';
		sideReviewhtml += '</div>';
		sideReviewhtml += '</div>';

		sideReviewhtml += '<div class="usr-comment-panel">';
		sideReviewhtml += '<div class="comment-box">';
		sideReviewhtml += '<div class="comments-ctr" id="comment_ctr"' + i + '>';

		if (primaryData.replies) {
			for (var cm = 0; cm < primaryData.replies.length; cm++) {
				var comment = primaryData.replies[cm];
				reviewhtml += '<div class="comment" data-comment-id="' + comment.id + '">';
				sideReviewhtml += '<div class="comment" data-comment-id="' + comment.id + '">';
				var Commentsrc = '';
				var CommentClass = '';
				if (!comment.keepAnonymous && that.userIdMap[comment.userID] && that.userIdMap[comment.userID].profileurl && that.userIdMap[comment.userID].profileurl !== 'NA') {
					Commentsrc = that.userIdMap[comment.userID].profileurl;
					CommentClass = 'profileImg';
				}

				reviewhtml += '<div class="comment-usr-img" class="' + CommentClass + '">';
				sideReviewhtml += '<div class="comment-usr-img" class="' + CommentClass + '">';

				if (Commentsrc.length > 1) {
					reviewhtml += '<img src="' + Commentsrc + '"></div>';
					sideReviewhtml += '<img src="' + Commentsrc + '"></div>';
				} else {
					reviewhtml += '<i class="icon-user"></i></div>';
					sideReviewhtml += '<i class="icon-user"></i></div>'
				}

				reviewhtml += '<div class="comment-content">';
				sideReviewhtml += '<div class="comment-content">';

				if (comment.hasOwnProperty('userID') || comment.hasOwnProperty('keepAnonymous') || comment.keepAnonymous) {
					reviewhtml += '<span class="comment-usr-name">' + that.getName(comment.userID, comment.keepAnonymous) + '&nbsp;</span>';
					sideReviewhtml += '<span class="comment-usr-name">' + that.getName(comment.userID, comment.keepAnonymous) + '&nbsp;</span>';
				}
				if (comment.secondary_Text) {
					reviewhtml += '<span class="comment-text">' + comment.secondary_Text + '</span>';
					sideReviewhtml += '<span class="comment-text">' + comment.secondary_Text + '</span>';
				}
				reviewhtml += '<div class="comment-details">';
				sideReviewhtml += '<div class="comment-details">';
				if (comment.created_at) {
					var Com_Created_at = new Date(comment.created_at);
					var months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
					var com_created_at = months[Com_Created_at.getMonth()] + ' ' + Com_Created_at.getDate() + ', ' + Com_Created_at.getFullYear();
					reviewhtml += '<span class="comment-time">' + com_created_at + '</span>';
					sideReviewhtml += '<span class="comment-time">' + com_created_at + '</span>';
				}
				reviewhtml += '<span class="separator">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>';
				sideReviewhtml += '<span class="separator">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>';
				var cmntclass, uncmntclass;
				if (comment.vote === 1) {
					cmntclass = 'blue';
				}
				if (comment.vote === -1) {
					uncmntclass = 'blue';
				}

				//reviewhtml+='<i class="vote-comment thumbs-icn icon-thumbs-up" title="Helpful" class="'+cmntclass+'" onclick="that.updateCommentVotes( $event, true, $parent.$index, $index );"><span class="comment-upVote">'+comment.upvotes+'</span><span class="separator">&nbsp;.&nbsp;</span>';
				//reviewhtml+='<i class="vote-comment thumbs-icn icon-thumbs-down" title="Inappropriate" class="'+uncmntclass+'" onclick="that.updateCommentVotes( $event, true, $parent.$index, $index );"></i><span class="comment-dnVote">'+comment.downvotes+'</span>';
				reviewhtml += '</div>';
				sideReviewhtml += '</div>';

				if (that.reviewUserId === comment.userID) {
					//var deleteCmntimg=(!that.hideImgUpload) ? that.showDeleteModal( $parent.$parent.$index, $parent.$index ) : that.toReviewInput();
					reviewhtml += '<i class="data-action-icn icon icon-trash pull-right right2" title="Delete"  onclick="window.showDeleteModal(' + i + ',' + cm + ')"></i>';
					sideReviewhtml += '<i class="data-action-icn icon icon-trash pull-right right2" title="Delete"></i>';
				}
				reviewhtml += '</div>';
				sideReviewhtml += '</div>';
			}
		}
		reviewhtml += '</div>';
		sideReviewhtml += '</div>';

		if (primaryData.reply_count > 2 && primaryData.replies && primaryData.reply_count === primaryData.replies.length) {
			reviewhtml += '<a class="more-comments" onclick="!that.hideImgUpload ? that.showMoreComments(' + i + ', false) : window.toReviewInput();">Show less comments</a>';
			sideReviewhtml += '<a class="more-comments">Show less comments</a>';
		}
		if (primaryData.replies && primaryData.reply_count > primaryData.replies.length) {
			//var showallcmnt=(!reviewCtrl.hideImgUpload )? that.showMoreComments($index, true) : that.toReviewInput();
			reviewhtml += '<a class="more-comments"  onclick="!that.hideImgUpload ? that.showMoreComments(' + i + ', true) : window.toReviewInput();">' + "Show all comments" + '</a>';
			sideReviewhtml += '<a class="more-comments">' + "Show all comments" + '</a>';
		}
		reviewhtml += '</div>';
		sideReviewhtml += '</div>';

		reviewhtml += '<div class="comment-inp-box">';
		reviewhtml += '<div class="comment-inp-ctr">';

		sideReviewhtml += '<div class="comment-inp-box">';
		sideReviewhtml += '<div class="comment-inp-ctr">';

		var reviewcmntsrc = '';
		var reviewcmntClass = '';
		if (that.userIdMap[that.reviewUserId] && that.userIdMap[that.reviewUserId].profileurl && that.userIdMap[that.reviewUserId].profileurl !== 'NA') {
			reviewcmntsrc = that.userIdMap[that.reviewUserId].profileurl;
			reviewcmntClass = 'profileImg';
		}
		reviewhtml += '<div class="comment-usr-img" class="' + reviewcmntClass + '">';
		sideReviewhtml += '<div class="comment-usr-img" class="' + reviewcmntClass + '">';

		if (reviewcmntsrc.length) {
			reviewhtml += '<img src="' + reviewcmntsrc + '"></div>';
			sideReviewhtml += '<img src="' + reviewcmntsrc + '"></div>';
		} else {
			reviewhtml += '<i class="icon-user"></i></div>';
			sideReviewhtml += '<i class="icon-user"></i></div>';
		}
		reviewhtml += '<textarea class="comment-inp" onkeyup="window.postComment(event, ' + i + ');" maxlength="' + that.commentMaxLimit + '" id="commentInp' + i + '" onclick="window.toReviewInput()" rows="1" placeholder="Write your comment ( max ' + that.commentMaxLimit + ' characters )"></textarea>';
		reviewhtml += '<span class="comment-sub">Press Enter to post.</span>';

		sideReviewhtml += '<textarea class="comment-inp" maxlength="' + that.commentMaxLimit + '" id="commentInp' + i + '"  rows="1" placeholder="Write your comment ( max ' + that.commentMaxLimit + ' characters )"></textarea>';
		sideReviewhtml += '<span class="comment-sub">Press Enter to post.</span>';

		if (!that.hide) {
			var cmnterevfun = '';
			reviewhtml += '<a ImgUpload" class="comment-sub pull-right hyperlink anony" onclick="window.toggleAnonymous(event, ' + i + ');" data-anonymous="0" id="make_anonymous' + i + '">' + "Make Anonymous" + '</a>';
			reviewhtml += '<span class="comment-sub pull-right" id="make_anonymous_comment' + i + '"></span>';
		}
		reviewhtml += '</div>';
		reviewhtml += '</div>';
		reviewhtml += '</div>';
		reviewhtml += '</div>';
		reviewhtml += '</div>';
		reviewhtml += '</div>';

		sideReviewhtml += '</div></div></div></div></div></div>';
	}


	if (pType > 4 && (that.entityReviews && that.entityReviews.primaryReturnList && that.entityReviews.primaryReturnList.length == pType)) {
		reviewhtml += '<div class="all-entity-data"><span onclick="showAllEntityData(false);" id="Show_less">Show less ' + that.primaryType + 's</span></div>';
		sideReviewhtml += '<div class="all-entity-data"><span id="Show_less">Show less ' + that.primaryType + 's</span></div>';
	}

	if (that.entityReviews && that.entityReviews.primaryReturnList && that.entityReviews.primaryReturnList.length < pType) {
		reviewhtml += '<div class="clearfix"></div>';
		reviewhtml += '<div class="all-entity-data" id="Show_all"><span onclick="showAllEntityData(true);">Show all ' + that.primaryType + 's</span></div>';
		sideReviewhtml += '<div class="clearfix"></div>';
		sideReviewhtml += '<div class="all-entity-data" id="Show_all"><span>Show all ' + that.primaryType + 's</span></div>';
	}
	$(".reviewQuestionUpdate").html(reviewhtml);
	$(".sideReview").html(sideReviewhtml);
}
function setReviewInpHeight() {
	var el = document.querySelector('.review-inp');
	el.style.height = '0px';
	el.style.height = Math.max(el.scrollHeight, 74) + 'px'; //74 = auto height of txtarea
}

function LightboxPopup(i,j,k){
	entityReviews=that.entityReviews;
	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}
	var primaryDataImage = entityReviews.primaryReturnList[i];
	var attachmentDataImage = primaryDataImage.attachments[j];
	images = formatImages(attachmentDataImage.attachment);
	var JImage=JSON.stringify(images);
	Lightbox.openModal(JImage, k, 'imageUrl');
}
window.postEntityData = function () {
	/*if (!that.reviewUserId) {
		that.postDataError = 'You must log in to write your '+ that.primaryType;
		var postDataError = 'Error : ' + that.postDataError;
		$("#perror-heading").html(postDataError);
		$("#perror-modal").modal('show');
		that.primaryPostAction = true;
		redirectUrl();
	}*/
	var posttData = 'post'+jsUcfirst(that.primaryType);
	gaReviewforAll(posttData);
	var attachments = [],
		payload;
	//that.editedDataIndex = 0;

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	$("#review-inp-ctr").hide();

	if (that.editedDataIndex != null) {
		console.log("Update", that.entityReviews.primaryReturnList[that.editedDataIndex]._Id);

		payload = {
			id: that.entityReviews.primaryReturnList[that.editedDataIndex]._Id
		};
	} else {
		payload = {
			entity_id: entityId,
			entity_type: entityType,
			userID: userId
		};
	}
	if (fileUrl.length > 0) {
		attachments[0] = {
			type: 'Image',
			attachment: []
		};

		fileUrl.forEach(function (url) {
			attachments[0].attachment.push({
				content: url
			})
		})
	}
	payload.attachments = attachments;
	//payload[that.primaryType + '_Text'] = that.reviewInput;
	payload[that.primaryType + '_Text'] = $("#reviewInput").val();

	var postRUQ = ( that.editedDataIndex !== null ) ? '/homes/re2/reviews/update/' : '/homes/re2/reviews/add/';
	if ($("#reviewInput").val().length > 50 && $("#reviewInput").val().length < 4000) {
		$.ajax({
			url: postRUQ + that.primaryType,
			method: "POST",
			data: {'payload': payload},
			success: function (data) {
				data =  JSON.parse(data);
				if (data && !data.error && data._Id) {
					getEnitiesCount();
					if (that.editedDataIndex != null) {
						that.entityReviews.primaryReturnList[that.editedDataIndex] = data;
					} else {
						that[that.primaryType + 'Count'] += 1;
						showUserPostedData(data);
					}

					if (that.primaryType === 'review') {
						cvrpxService.cvrpxClick('m9IiCNGVmW0Q0Ir8mwM');
						userPostedData = data;
						that.reviewPosted = true;
					}

					that.toggleWriteReview({}, true);
					fileUrl = [];
					if (data.Reason === usrArdyRw) {
						that.reviewPostedMsg = 'You have already posted review for this.';
					}
					setReviewHtml(that.entityReviews);
					
					$("#review-inp-ctr").hide();
					$(".review_primaryTypeInp").hide();
					$("#review_primarytype").hide();
					$("#reviewInput").val("");
				} else {
					that.postDataError = that.primaryType + ' contains bad words';
					var postDataError = 'Error : ' + that.postDataError;
					$("#perror-heading").html(postDataError);
					$("#perror-modal").modal('show');

					$("#review-inp-ctr").hide();
					$(".review_primaryTypeInp").show();
					$("#review_primarytype").show();
				}
			}
		});
	} else {
		return false;
	}
}

function cvrpxClick(cvrLabel) {
	var google_conversion_id = 977653910,
		google_conversion_language = "en",
		google_conversion_format = "3",
		google_conversion_color = "ffffff",
		google_conversion_label = cvrLabel,
		google_remarketing_only = false,

		img = document.createElement("img");
	img.src = "//www.googleadservices.com/pagead/conversion/" + google_conversion_id + "/?guid=ON&script=0&label=" + cvrLabel + "";
	img.width = "1";
	img.height = "1";
	document.body.appendChild(img);
}

function openLightboxModalReviews(images, index) {
	images = formatImages(images);
	Lightbox.openModal(images, index, 'imageUrl');
}

function formatImages(images) {
	var formatImage = [];
	images.forEach(function (i) {
		if (i.content) {
			var image = {
				imageUrl: i.content.replace('80x80', '700x700')
			};
			formatImage.push(image);
		}
	});
	return formatImage;
}

function changeComments(data, index, entityReviews, fromEvent) {
	if (fromEvent) {
		that.entityReviews = entityReviews;
	} else {
		var comments = that.entityReviews.primaryReturnList[index].replies;
		if (!comments) {
			that.entityReviews.primaryReturnList[index].replies = data.replies;
		} else {
			that.entityReviews.primaryReturnList[index].replies.push(data.replies[data.replies.length - 1]);
		}
		that.entityReviews.primaryReturnList[index].reply_count = data.reply_count;
		that.entityReviews.primaryReturnList[index].commentInp = '';
	}
	that.anonymousAction[index] = 'Make Anonymous';
	that.commentAs[index] = '';
	that.keepAnonymous[index] = false;
}

/*$rootScope.$on('modifyComments', function (e, data, index, entityReviews) {
 if (that.hideImgUpload) {
 changeComments(data, index, entityReviews, true);
 }
 });*/

window.postComment = function (event, index) {
	var el = event.currentTarget;
	if ((event.keyCode === 13 || event.which === 13) && !event.shiftKey) {
		if (!that.reviewUserId) {
			//$scope.open('lg', 'post your comment');
			//return;
			redirectUrl();
		}
		var secondary_Text = $("#commentInp" + index).val();
		var keepAnonymous = (that.keepAnonymous[index]) ? 'true' : 'false';
		payload = {
			secondary_Text: secondary_Text,
			userID: userId,
			keepAnonymous: keepAnonymous
		};

		var reviewId = that.entityReviews.primaryReturnList[index]._Id;

		$.ajax({
			url: "/homes/re2/reviews/comment/" + that.primaryType + '/' + reviewId,
			method: "POST",
			data: {'payload': payload},
			success: function (data) {
				data =  JSON.parse(data);
				if (data && !data.error && data._Id) {
					changeComments(data, index);
					setReviewHtml(that.entityReviews);
					el.style.height = '35px';
					el.closest('.comment-inp-ctr').querySelector('.anony').setAttribute('data-anonymous', 0);
				} else {
					that.postDataError = 'comment contains bad words';
					var postDataError = 'Error : ' + that.postDataError;
					$("#perror-heading").html(postDataError);
					$("#perror-modal").modal('show');
				}
			}
		});
	} else {
		el.style.height = '0px';
		el.style.height = Math.max(el.scrollHeight + el.offsetHeight - el.clientHeight, 35) + 'px';
	}
}

window.toggleAnonymous = function (event, index) {
	var el = event.currentTarget,
		isAnonymous = +el.getAttribute('data-anonymous');
	isAnonymous = !isAnonymous;
	el.setAttribute('data-anonymous', +isAnonymous);

	if (isAnonymous) {
		that.anonymousAction[index] = 'Undo';
		that.commentAs[index] = 'You will be posting as anonymous.';
		that.keepAnonymous[index] = true;
	} else {
		that.anonymousAction[index] = 'Make Anonymous';
		that.commentAs[index] = '';
		that.keepAnonymous[index] = false;
	}
	$("#make_anonymous" + index).html(that.anonymousAction[index]);
	$("#make_anonymous_comment" + index).html(that.commentAs[index]);
}

function isAnony(event) {
	var el = event.currentTarget;
	return +el.getAttribute('data-anonymous');
}

function showMoreImgs() {
	var elems = document.getElementById('imgPreview').getElementsByClassName(config.IMG_THUMB),
		i, el;
	for (i = 0; i < elems.length; i++) {
		el = elems[i];
		if (el.classList) {
			el.classList.toggle(config.IMG_MORE_THUMB);
		} else {
			var classes = el.className.split(' ');
			var existingIndex = classes.indexOf(config.IMG_MORE_THUMB);

			if (existingIndex >= 0)
				classes.splice(existingIndex, 1);
			else
				classes.push(config.IMG_MORE_THUMB);

			el.className = classes.join(' ');
		}
	}
	document.querySelector(".more-imgs").innerHTML = ("+" + document.getElementsByClassName(config.IMG_MORE_THUMB).length);
	//that.moreImgsCnt = '+' + document.getElementsByClassName( config.IMG_MORE_THUMB ).length;
}

function resetReviewInput() {
	// that.wrtPrimaryData = 'Post ' + that.primaryType;
	// imgupload && imgupload.dropzone && imgupload.dropzone.removeAllFiles(true);
	// var imgThumbEls = document.querySelectorAll('#imgPreview .dz-image-preview') || [],
	//  thumbTitleEls = document.querySelectorAll(".attach-title"),
	//  i;
	// fileUrl = [];
	// document.querySelector(config.IMG_THUMB_CTR) && (document.querySelector(config.IMG_THUMB_CTR).innerHTML = '<i class="fa icon-camera"></i>&nbsp;Add Photos<span class="hidden-xs hidden-sm">&nbsp;&nbsp;or&nbsp;&nbsp;</span><span class="hidden-xs hidden-sm"><i class="fa icon-picture"></i>&nbsp;Drag Photos here</span>');
	// that.reviewInput = "";
	// document.querySelector(config.PRIMARY_INP).style.height = "74px";
	// document.querySelector('.more-imgs').classList.add('hide');
	// thumbTitleEls[0].classList.add('hide');
	// thumbTitleEls[1].classList.add('hide');
	// for (i = 0; i < imgThumbEls.length; i++) {
	//  imgupload && imgupload.dropzone.previewsContainer.removeChild(imgThumbEls[i]);
	// }
}

function toggleWriteReview(event, isTypeChanged) {
	event && event.preventDefault && event.preventDefault();
	if (isTypeChanged) {
		that.showWriteReview = false;
	} else {
		that.showWriteReview = !that.showWriteReview;
	}
	that.editedDataIndex = null;
	!that.showWriteReview && resetReviewInput();
}

window.hideWriteReview = function () {
	var pos = $("#reviews").offset().top;
	// animated top scrolling
	$('body, html').animate({scrollTop: pos});
	$("#review-inp-ctr").show();
	$(".review_primaryTypeInp").hide();
	$("#review_primarytype").hide();
	$(".review-uploads").show();
	
	if (that.primaryType === 'question') {
		$(".review-uploads").hide();
	}
	//hideshowWriteReview
}

window.displayWriteReview = function () {
	$("#review-inp-ctr").hide();
	$(".review_primaryTypeInp").show();
	$("#review_primarytype").show();
	$("#reviewInput").val("");
	//hideshowWriteReview
}

window.editPrimaryData = (index)=> {
	hideWriteReview();

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	$("#reviewInput").val(that.entityReviews.primaryReturnList[index][that.primaryType + '_Text']);
	$(".wrtPrimaryData").html("Edit review");

	var imgThumbEls = document.querySelectorAll('#imgPreview .dz-image-preview') || [],
		attachments = that.entityReviews.primaryReturnList[index].attachments,
		thumbTitleEls, i;
	that.showWriteReview = true;
	that.primaryType === 'review';
	that.reviewInput = that.entityReviews.primaryReturnList[index][that.primaryType + '_Text'];
	that.wrtPrimaryData = 'Edit ' + that.primaryType;
	if (imgupload) {
		fileUrl = [];
		for (i = 0; i < imgThumbEls.length; i++) {
			imgupload && imgupload.dropzone.previewsContainer.removeChild(imgThumbEls[i]);
		}
		document.querySelector(".dz-default.dz-message").innerHTML = '<span>' + defaultMsg + '</span>';
		thumbTitleEls = document.querySelectorAll('.attach-title');
		thumbTitleEls[0].classList.add('hide');
		thumbTitleEls[1].classList.add('hide');
		if (attachments && attachments.length && attachments[0].attachment && attachments[0].attachment.length) {
			attachments[0].attachment.forEach(function (attachment, index) {
				var file = {
					name: index,
					size: 9999
				};
				imgupload.dropzone.emit('addedfile', file);
				imgupload.dropzone.emit('thumbnail', file, attachment.content);
				imgupload.dropzone.emit('complete', file);
				fileUrl.push(attachment.content);
			});
			document.querySelector("#imgupload .dz-default.dz-message").innerHTML = "<div class='more-btn'>Add More +</div><span class='more-btn-sub text-info hide'>*Maximum 8 photos can be uploaded.</span>";
			if (document.getElementsByClassName("dz-preview").length >= 8) {
				document.getElementsByClassName('more-btn-sub')[0].classList.remove('hide');
				document.querySelector('.more-btn').style.opacity = "0.5";
				imgupload.classList.add('max-files-reached');
			}
		}
		that.editedDataIndex = index;
	}
}

window.showDeleteModal = function (primaryIndex, secondaryIndex) {
	delIndex = primaryIndex;
	if (secondaryIndex !== null) {
		that.delEntity = 'comment';
		delSecondaryIndex = secondaryIndex;
	} else {
		that.delEntity = that.primaryType;
		delSecondaryIndex = null;
	}
	var deleteEntityHeader = 'Delete ' + that.delEntity;
	var delReviewText = 'Are you sure you want to delete ' + that.delEntity + '?';

	$("#deleteEntityHeader").html(deleteEntityHeader);
	$("#delReviewText").html(delReviewText);
	$("#deleteEntityData").html(deleteEntityHeader);

	$("#review-modal").modal('show');
}

function handlePrimaryDataDel(data, index, noSplice) {

	$("#reviewInput").val("");
	$("#review-inp-ctr").hide();
	$(".review_primaryTypeInp").show();
	$("#review_primarytype").show();

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	delIndex = index !== undefined ? index : delIndex;
	if (!noSplice) {
		that.entityReviews.primaryReturnList.splice(delIndex, 1);
	}
	that[that.primaryType + 'Count'] -= 1;
	if (that.primaryType === 'review') {
		userPostedData = null;
		that.reviewPosted = false;
		that.reviewPostedMsg = '';
	}
	var imgThumbEls = document.querySelectorAll('#imgPreview .dz-image-preview') || [];
	if(imgThumbEls){
		for (i = 0; i < imgThumbEls.length; i++) {
			imgupload && imgupload.dropzone.previewsContainer.removeChild(imgThumbEls[i]);
		}
		document.querySelector(".dz-default.dz-message").innerHTML = '<span>' + defaultMsg + '</span>';
		thumbTitleEls = document.querySelectorAll('.attach-title');
		thumbTitleEls[0].classList.add('hide');
		thumbTitleEls[1].classList.add('hide');
	}
}
function handleSecondaryDataDel(data, index) {

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	delIndex = index !== undefined ? index : delIndex;
	var primaryData = that.entityReviews.primaryReturnList[delIndex];
	data.attachments = primaryData.attachments,
		data[that.primaryType + '_Text'] = primaryData[that.primaryType + '_Text'];
	that.primaryType === 'review';
	that.entityReviews.primaryReturnList[delIndex] = data;
}

function broadcastdeleteEntity(data, delIn, delReview, cdata, index) {
	if (that.hideImgUpload) {
		var callback = delReview ? handlePrimaryDataDel : handleSecondaryDataDel;
		if (delIn) {
			that.editedDataIndex = null;
			that.toggleWriteReview();
		}
		callback(cdata, index, true);
		that.entityReviews = data;
	}
}

function deleteEntityData(isDeletion) {

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	var delIn = false,
		delReview = false;
	var primaryDataId = that.entityReviews.primaryReturnList[delIndex]._Id;
	var secondaryDataId = (delSecondaryIndex !== null) ? that.entityReviews.primaryReturnList[delIndex].replies[delSecondaryIndex].id : null;
	if (secondaryDataId !== null) {
		var deleteEntityAPI = '/homes/re2/reviews/comment/delete/' + primaryDataId + '/' + secondaryDataId + '/' + userId;
	} else {
		var deleteEntityAPI = '/homes/re2/reviews/delete/' + primaryDataId;
	}

	$.ajax({
		url: deleteEntityAPI,
		method: "POST",
		data: {primaryDataId: primaryDataId},
		success: function (data) {
			if (data && !data.error) {
				getEnitiesCount();
				if (delSecondaryIndex === null && data.Status === 'SUCCESS') {
					if (delIndex === that.editedDataIndex) {
						delIn = true;
						that.editedDataIndex = null;
						that.toggleWriteReview();
					}
					handlePrimaryDataDel();
					delReview = true;
				} else {
					handleSecondaryDataDel(data);
				}
			}
			setReviewHtml(that.entityReviews);
		}
	});
}

function broadcastupdateVotes(data) {
	that.entityReviews = data;
	setReviewHtml(that.entityReviews);
}

window.updateVotes = function (isUpVote, index) {

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	var primaryData = that.entityReviews.primaryReturnList[index],
		voteType;
	if (!that.reviewUserId) {
		redirectUrl();
	}

	if ((primaryData.vote === 1 && isUpVote) || (primaryData.vote === -1 && !isUpVote)) {
		voteType = 'removeVote';
	} else {
		voteType = isUpVote ? 'upvote' : 'downvote';
	}

	$.ajax({
		url: "/homes/re2/reviews/vote?aj=1",
		method: "POST",
		data: {
			primaryType: that.primaryType,
			primaryId: primaryData._Id,
			voteType: voteType,
			reviewUserId: that.reviewUserId
		},
		success: function (data) {
			data =  JSON.parse(data);
			if (data && !data.error && data.Status !== 'FAILED') {
				that.entityReviews.primaryReturnList[index].upvotes = data.upvotes;
				that.entityReviews.primaryReturnList[index].downvotes = data.downvotes;
				that.entityReviews.primaryReturnList[index].vote = data.vote;
				broadcastupdateVotes(that.entityReviews);
			}
		}
	});
}

function showMoreComments(index, showMore) {

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	var replyCount = that.entityReviews.primaryReturnList[index].reply_count;
	if (showMore) {
		$.ajax({
			url: "/homes/re2/reviews/allcomments?aj=1",
			method: "POST",
			data: {entityId: that.entityReviews.primaryReturnList[index]._Id, userId: that.reviewUserId},
			success: function (data) {
				data =  JSON.parse(data);
				if (data && !data.error) {
					that.entityReviews.primaryReturnList[index].replies = data;
					setReviewHtml(that.entityReviews);
				}
			}
		});
	} else {
		that.entityReviews.primaryReturnList[index].replies.splice(0, replyCount - 2);
		setReviewHtml(that.entityReviews);
	}
}

function updateCommentVotes(event, isUpVote, reviewInd, commentInd) {

	if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
		entityReviews.primaryReturnList.splice(0, 1);
	}

	var el = event.currentTarget,
		primaryData = that.entityReviews.primaryReturnList[reviewInd],
		comment = primaryData.replies[commentInd],
		thumbsEl, count, voteType;

	if (!that.reviewUserId) {
		redirectUrl();
	}

	if ((comment.vote === 1 && isUpVote) || (comment.vote === -1 && !isUpVote)) {
		voteType = 'removeVote';
	} else {
		voteType = isUpVote ? 'upvote' : 'downvote';
	}

	$.ajax({
		url: "/homes/re2/reviews/comment/vote",
		method: "POST",
		data: {voteType: voteType, entityId: primaryData._Id, commentId: comment.id, userId: that.reviewUserId},
		success: function (data) {
			data =  JSON.parse(data);
			if (!data.error) {
				that.entityReviews.primaryReturnList[reviewInd].replies[commentInd].upvotes = data.upvotes;
				that.entityReviews.primaryReturnList[reviewInd].replies[commentInd].downvotes = data.downvotes;
				that.entityReviews.primaryReturnList[reviewInd].replies[commentInd].vote = data.vote;
			}
		}
	});
}

window.showAllEntityData = (showMore) => {
	if (showMore) {
		$.ajax({
			url: "/homes/re2/reviews/show?aj=1",
			method: "POST",
			data: {
				entityType: pageType,
				entityId: Id,
				userId: userId,
				primaryType: that.primaryType,
				page: 0,
				size: 0,
				sortBy: 'date'
			},
			success: function (data) {
				data =  JSON.parse(data);
				if (data && !data.error) {
					that.entityReviews = data;
	
					if (typeof(entityReviews) == 'object' && entityReviews.hasOwnProperty('primaryReturnList') && typeof(entityReviews.primaryReturnList) == 'object' && entityReviews.primaryReturnList.hasOwnProperty(0) && !entityReviews.primaryReturnList[0].hasOwnProperty('_Id')) {
						entityReviews.primaryReturnList.splice(0, 1);
					}

					if (that.entityReviews && that.entityReviews.primaryReturnList && that.entityReviews.primaryReturnList.length) {
						that.entityReviews.primaryReturnList.forEach(function (primaryData) {
							if(primaryData.userID==userId){
								$(".review_primaryTypeInp").hide();
								$("#review_primarytype").hide();
							}
						});
					}
					
					if (that.primaryType === 'review' && userPostedData) {
						that.reviewPosted = true;
						showUserPostedData(userPostedData);
					}
					setUserIdMap();
				}
			}
		});
	} else {
		that.entityReviews.primaryReturnList.splice(4, that[that.primaryType + 'Count'] - 4);
		setReviewHtml(that.entityReviews);
	}
}

window.limitText = (limitField, minNum, maxNum)=> {
	if (limitField.value.length < minNum) {
		$("#minlimit").html('*Mininum 50 characters required');
	} else if (limitField.value.length > minNum && limitField.value.length <= maxNum) {
		$("#minlimit").html('');
		$("#maxlimit").html('*Maximum 4000 characters allowed');
		limitField.value = limitField.value.substring(0, limitField.value.length);
	} else {
		;
		limitField.value = limitField.value.substring(0, maxNum);
	}
}


$("#reviewtabsetnew li a").click(function () {
	$(this).parent().addClass('active').siblings().removeClass('active');
});

$(".sideReview").on('click',function(e) {
	var pos = $("#reviews").offset().top;
	$('body, html').animate({scrollTop: pos});
});

window.gaReviewforAll = function(entity){
	var page_type = globals.pageType.toLowerCase();

    switch(page_type){
        case 'vap':
            var propertySnippet = JSON.parse(atob(globals.propertySnippet));
            var prop_type = propertyType(propertySnippet.propertyFor);
            gaService.addEvent('RealEstate', 'QH_' + page_type+'_'+prop_type , 'QH_'+page_type+'_'+prop_type+'_writeAReview_'+entity+'_click');
            break;
        case 'project':
        case 'builder':
        case 'locality':
        case 'individual':
            gaService.addEvent('RealEstate', 'QH_' + page_type, 'QH_'+page_type+'_writeAReview_'+entity+'_click');
            break;
    }
}

window.textGA = function () {
	var primary_type = 'write'+jsUcfirst(that.primaryType);
	gaReviewforAll(primary_type);
}

function imageuploadGA () {
	var img_upload = that.primaryType+'s_addPhotos';
	gaReviewforAll(img_upload);
}
