//require('../vendor/typehead');
import {isSet,currencyFilter,areaFilter,getFormObject,getterSetter,_tplRender,getutmData,connectionCheck} from '../utils/common'
import wantAdTree from '../constants/wantAdConstant'
import {getCookie} from '../services/cookieService'
import {get,set} from '../services/localStorageService'
import {formValidator} from '../services/formValidator'
import {getSubCategory} from '../services/getSubCategory'
import {getLoggedInUser,login} from '../loginService'
import {windowWidthService} from '../services/windowWidthService'
import {requirementService} from '../services/requirementService'
import {dataService} from '../services/dataService'
import {otpService} from '../services/otpService'
import {errorTrack} from '../utils/errorTrack'
import {eventTriggerQH,eventTriggeranalatyic} from '../services/gtmService'
import {getContactInitiateEvents} from '../services/gtmEvents'
require('./otpController');
//var _ = require('lodash');
window.requirementController = function () {

    window.requirementController = {};
    var user, clickedEl;
    var counter = 0;
    var loanRequirement;
    requirementController.reqSources = '';
    requirementController.otpNeeded = false;
    requirementController.UnitContact = true;
    requirementController.loginCheck = true;

    requirementController.vapProjectSnippet =  (globals.pageType == 'VAP')? JSON.parse(atob(globals.projectSnippet)):globals.projectSnippet;
    var projectId = requirementController.vapProjectSnippet ? requirementController.vapProjectSnippet.id : null;

    requirementController.propertySnippet = (globals.propertySnippet) ? JSON.parse(atob(globals.propertySnippet)) : {};
    requirementController.vapProjectSnippet = globals.projectSnippet || null;
    requirementController.sellerSnippet = (globals.sellerSnippet) ?  JSON.parse(atob(globals.sellerSnippet)) : {};
    if (requirementController.vapProjectSnippet && requirementController.vapProjectSnippet.imageUrl && isSet(requirementController.vapProjectSnippet)) {
        requirementController.vapProjectSnippet = JSON.parse(atob(requirementController.vapProjectSnippet));
        if (isSet(requirementController.vapProjectSnippet.imageUrl)){
            requirementController.vapProjectSnippet.imageUrl = requirementController.vapProjectSnippet.imageUrl.replace('80x80', '700x700');
        }else{
            requirementController.vapProjectSnippet.imageUrl = window.CDNURL+'image/tile-no-photo.jpg';
        }
    }
    /*if(globals.pageType == 'VAP'){
           requirementController.imageUrl = constructUrls(requirementController.propertySnippet.images[0]); 
    }*/
    requirementController.bhkValues = [];
    var deferred = $.Deferred;
    var sendAlertPayload;
    setGaParams();
    requirementController.want_ad_tree = wantAdTree;

    requirementController.emailList = [];
    requirementController.mobileList = [];
    requirementController.userdata = {};
    var alertMessage = '';
    var thankMessage = false;
    requirementController.otpVerifyLogin = false;
    requirementController.saveAdcheck = true;
    //var pageType = isSet(pageType) ? pageType : '';

    function setLoggedInData(data) {
        requirementController.emailList = data.userEmail || [];
        requirementController.mobileList = data.userMobile || [];
        //requirementController.userId =  data.id || ""; //TODO
    }

    requirementController.selectedLocations = [];
    requirementController.allBhks = ['1', '2', '3', '4', '4+'];
    requirementController.propertyTypes = ['Apartment', 'BuilderFloor', 'Land', 'Villa', 'Plot', 'Shop', 'Complex', 'Office', 'Flatmates', 'PG'];
   
    if(globals.pageType === 'project' || globals.pageType === 'qproject'){
        requirementController.topCategoryValue = (isSet(requirementController.vapProjectSnippet)) && requirementController.vapProjectSnippet.category ? requirementController.vapProjectSnippet.category.toLowerCase() : 'residential';
    } else if(globals.pageType === 'VAP'){
        requirementController.topCategoryValue = (isSet(requirementController.propertySnippet)) && requirementController.propertySnippet.category ? requirementController.propertySnippet.category.toLowerCase() : 'residential';
    }

    requirementController.MinPriceDisable = '';
    requirementController.adTypeValue = 'buy';
    requirementController.adType = 'buy';
    requirementController.selectedBHK = '';
    requirementController.selectedUnitTypeTxt = '';
    requirementController.selectedUnitType = {};
    requirementController.locality = {};
    requirementController.showBHKDropDown = true;
    requirementController.thankMessage = '';
    requirementController.selectedIsdCode = $('.stdcode select').val();
    requirementController.furnishing = '';
    requirementController.login = false;
//        user = pScope.user || {};

    requirementController.enquiry_name = get("autofill_contactname") || '';
    requirementController.enquiry_email = get("autofill_email") || '';
    requirementController.enquiry_phone = get("autofill_no") || '';
    /*$rootScope.$on('authSuccess', function(event, data) {
     user = data.user;
     requirementController.userId = data.user ? data.user.id : '';
     requirementController.login = true;
     setUser();
     });*/ //at some time I am getting from $parent AuthSuccess is not not working here.

    /*       $rootScope.$on('loginEvent', function (event, data) {
     user = data.user;
     requirementController.userId = data.user ? data.user.id : '';
     requirementController.login = true;
     requirementController.enquiry_phone = data.user.mobile;
     requirementController.enquiry_email = data.user.email;
     setUser();
     });

     $rootScope.$on('selectedIsdCode', function(e, data) {
     requirementController.selectedIsdCode = data;
     });
     */
    // requirementController.setUser = () => {
    //     user.name = user.firstName || '';
    //     if (user.name) requirementController.enquiry_name = user.name;
    //     if (user.mobile) requirementController.enquiry_phone = user.mobile;
    //     if (user.email) requirementController.enquiry_email = user.email;
    // }

    /*
     requirementController.getAddress = function (address) {
     var complete_address = '';
     if (address.street) {
     complete_address += address.street + ',';`
     }
     if (address.locality) {
     complete_address += address.locality + ',';
     }
     if (address.city) {
     complete_address += ' ' + address.city;
     }
     return complete_address;
     }
     */

    requirementController.getCityName = function () {
        return requirementController.propertySnippet.city || requirementController.vapProjectSnippet.address.city;
    }

    requirementController.getProjectUnits = function () {
        return requirementController.vapProjectSnippet.projectUnits;
    }

    function hasLocation(locationJson) {
        if (requirementController.selectedLocations.length === 0) {
            return false;
        }
        for (var i = 0; i < requirementController.selectedLocations.length; i++) {
            if (requirementController.selectedLocations[i].id == locationJson) {
                return true;
            }
        }
        return false;
    }

    requirementController.locationRemoval = function (locationJson) {
        if (hasLocation(locationJson)) {
            for (var i = 0; i < requirementController.selectedLocations.length; i++) {
                if (requirementController.selectedLocations[i].id == locationJson) {
                    requirementController.selectedLocations.splice(i, 1);
                }
            }
            requirementController.reRenderAlertTopPanel();
        }
    };

    requirementController.locationSelection = function (locationJson) {
        requirementController.locality.values = "";
        if (!hasLocation(locationJson)) {
            requirementController.selectedLocations.push(locationJson);
        }
        requirementController.reRenderAlertTopPanel();
        $("form[name='requirementForm'] input[name='locality']").focus();
    };

    function getLocationIds() {
        var ids = [];
        for (var i = 0; i < requirementController.selectedLocations.length; i++) {
            ids.push(requirementController.selectedLocations[i].id);
        }
        return ids.toString();
    }

    function getLocationName() {
        var name = [];
        for (var i = 0; i < requirementController.selectedLocations.length; i++) {
            name.push(requirementController.selectedLocations[i].title);
        }
        return name.toString();
    }

    /*
     requirementController.getSuggestedLocality = function () {
     var cityId = requirementController.propertySnippet.cityId || globals.projectSnippet.address.cityId;
     var key = $(this).val();
     if (key && cityId) {
     dataService.getLocalitySearchNew({
     'query': key,
     'cityId': cityId,
     'limit': 8
     }).done(function(data){
     console.log(data);
     });
     } else {
     requirementController.selectCityError = true;
     }
     };
     */


    function inhiretAlertPayLoad(payload) {
        this.payload = payload;
        this.buildPaylodDetails = function () {
            return this.payload;
        };
    }

    function showSuccessMessage() {
        gtmEventReqpopup();
        $('#autoShowPropertyInterest_Container .newmodal').addClass('animation');
        $('#successPhone').html(requirementController.enquiry_phone);
        $('#successEmail').html(requirementController.enquiry_email);
        $('.topPanel').hide();
        $('.bottomPanel').hide();
        setTimeout(function () {
            if (console.log($('#alertModal').is(':visible'))) {
                $('#alertModal').modal('toggle');
            }
        }, 4000);
    }

    function gtmEventReqpopup(){
        var gtmData = {
            'event': getContactInitiateEvents(requirementController.reqSources),
            'mobile':requirementController.enquiry_phone,
            'email': requirementController.enquiry_email,
            'Intent':requirementController.adType,
            'Property_type':requirementController.selectedUnitTypeTxt
        };
        if(requirementController.selectedLocations[0]){
            gtmData['Locality_id'] =  requirementController.selectedLocations[0].id;
            gtmData['Locality_name'] =  requirementController.selectedLocations[0].title;
        }
        if(globals.pageType === 'project') {
            gtmData['Bhk']= requirementController.selectedBHK;
            if(requirementController.vapProjectSnippet.priceRange['high']){
                gtmData['Price_max'] = requirementController.vapProjectSnippet.priceRange['high'];
            }
            if(requirementController.vapProjectSnippet.priceRange['low']){
                gtmData['Price_min'] = requirementController.vapProjectSnippet.priceRange['low'];
            }
            gtmData['Project_id'] = requirementController.vapProjectSnippet.id;
        } else if(globals.pageType === 'VAP'){
           gtmData['Bhk'] = requirementController.selectedBHK[0];
           gtmData['Ad_id'] = requirementController.propertySnippet.id;
        }

        var utmResult = getutmData();
        gtmData['utm_campaign'] = utmResult['utm_campaign'];
        gtmData['utm_medium'] = utmResult['utm_medium'];
        gtmData['utm_source'] = utmResult['utm_source'];
        gtmData['HL_intent'] = (loanRequirement && loanRequirement === 'YES') ? 'Y':'N';
        gtmData['user_db_id'] = getCookie('__u');
        if(connectionCheck()){
            eventTriggerQH(gtmData);
        } else {
            eventTriggeranalatyic(gtmData);
        }
    }

    function buildOtpPayload() {
        return {
            sellerEmail: requirementController.enquiry_email,
            sellerMobile: requirementController.enquiry_phone,
            sellerName: requirementController.enquiry_name,
            userdata: requirementController.userdata,
            page: "REPLY"
        };
    }


    requirementController.detectEmailMobileConflict = function () {
        var obj = {
            "emailId": requirementController.enquiry_email || '',
            "mobileNumber": requirementController.enquiry_phone || '',
            "loggedIn": requirementController.login || false,
            "loggedInUserId": requirementController.userId,
            "userDetailsNeeded": true
        };
        if (counter == 0) {
            if (globals.pageType == 'VAP' && requirementController.saveAdcheck) {
                enquiryProcess();
            }
            counter++;
        }
        if (requirementController.login) {
            requirementController.otpVerifyLead = 'OTP_VERIFIED';
            requirementController.addRequirement();
            if (requirementController.projectUnitEnquiry == 'projectUnitEnquiry') {
                $("#thankuEmail").text(requirementController.enquiry_email);
                $("#thankuPhone").text(requirementController.enquiry_phone);
                $(".modal-header").addClass("animation");
                sendMailSmsToCampaignUsers();
                sendSmsToUser();
            } else {
                showSuccessMessage();
            }
        } else {
            dataService.detectEmailMobileConflict(obj).then(function (response) {
                counter++;
                if (!requirementController.otpNeeded) {
                    otpController.init.call(clickedEl, buildOtpPayload());
                }
                if (response && !requirementController.login) {
                    requirementController.otpNeeded = true;
                    if (isSet(otpService.getOtpVariable().otpNumber)) {
                        handleApiErr();
                        requirementController.otpVerifyLogin = true;
                    }
                } else {
                    if (response.status != 200) {
                        requirementController.replyerr = true;
                        replyError(response);
                    } else {
                        requirementController.replyerr = false;
                        if (requirementController.errorLabel == "EMAIL_ALREADY_REGISTERED") {
                            updateSession();
                        }
                        if (requirementController.projectUnitEnquiry == 'projectUnitEnquiry' && requirementController.login) {
                            sendMailSmsToCampaignUsers();
                            sendSmsToUser();
                        } else {
                            showSuccessMessage();
                        }
                        handleApiErr();
                    }
                }
            });
        }
    };

    requirementController.downloadBrochureAndCloseModal = function (unitType) {
        if (!isSet(unitType) && isSet(globals.brochureUrl)) {
            window.open(globals.brochureUrl, '_blank');
        }
        $('#contactModal').modal('toggle');
    }


    function replyError(data) {
        var data = JSON.parse(data);
        /*if (data.response.status == 503 || data.response.status == 500) {
         requirementController.alertMessage = "Due to an Internal error, your request could not be processed. Please try after some time. We regret the inconvenience caused and thank you for your support and patience.";
         } */
        if (isSet(data.DetectEmailMobileConflictApplicationResponse.errors)) {
            var response = data.DetectEmailMobileConflictApplicationResponse;
            var errObj = response.errors || [];
            if (errObj.length > 0) {
                requirementController.errorLabel = errObj[0].code;
                requirementController.userdata.errorLabel = requirementController.errorLabel;
                var errMsg;
                gaService.addEvent('link-RealestateResponsiveUI', 'Mobile_As_Login', requirementController.pageName + "_" + errObj[0].code);
                switch (errObj[0].code) {

                    // 1. Non Login :-  Email exist in acc a verified and any mobile
                    case "EXISTING_USER_EMAIL_VERIFIED_LOGIN_NEEDED" :
                        errMsg = "This Email is registered with us. Please login or use a different email";
                        requirementController.pwdNeeded = true;
                        requirementController.otpNeeded = false;
                        break;

                    // 2. Non Login :- Email exist in acc a unverified and any mobile which doesn't belongs to in account
                    case "MOBILE_NUMBER_CONFLICT" :
                        errMsg = "OTP sent on " + requirementController.enquiry_phone + ".";
                        requirementController.otpNeeded = true;
                        requirementController.pwdNeeded = false;
                        break;

                    // 3. Non Login :- Email doesn't exist and Mobile verified with acc a
                    case "EXISTING_USER_MOBILE_VERIFIED_LOGIN_NEEDED" :
                        errMsg = "We have sent an OTP to " + requirementController.enquiry_phone + ".";
                        requirementController.otpNeeded = true;
                        requirementController.pwdNeeded = false;
                        break;

                    // 4. Non Login :- Email don't exist in db and mobile unverified
                    case "EMAIL_ID_CONFLICT" :
                        errMsg = "We have sent an OTP to " + requirementController.enquiry_phone + ".";
                        requirementController.otpNeeded = true;
                        requirementController.pwdNeeded = false;
                        break;

                    // 5. Login :- Email Exist in logged in acc and Mobile exist in acc b verified or unverified
                    case "MOBILE_ALREADY_REGISTERED" :
                        errMsg = "We have sent an OTP to " + requirementController.enquiry_phone + ".";
                        requirementController.otpNeeded = true;
                        requirementController.pwdNeeded = false;
                        break;

                    // 6. Login :- Mobile Exist in logged in acc and Email exist in acc b verified or unverified
                    case "EMAIL_ALREADY_REGISTERED" :

                        var evPayload = {
                            "userEmail": requirementController.enquiry_email,
                            "userLoggedIn": true,
                            "loggedInUserId": requirementController.userId
                        };

                        errMsg = "Please Wait...Sending Verification link in given Email.";
                        loginService.addEmail(evPayload).then(function (evResult) {
                            if (evResult != 'API_CALL_FAILED') {
                                evResult = evResult.AddEmailToUserAccountApplicationResponse;
                                if (!("errors" in evResult)) {
                                    requirementController.alertMessage = "Verification link has been sent. please verify or change email and submit";
                                    requirementController.alertClass = 'alert-success';
                                } else if (evResult.errors[0].code == "EMAIL_PENDING_VERIFICATION") {
                                    requirementController.alertMessage = "Verification link has been sent. please verify or change email and submit";
                                    scope.alertClass = 'alert-success';
                                } else {
                                    requirementController.alertMessage = evResult.errors[0].code;
                                    requirementController.alertClass = 'alert-danger';
                                }
                            } else {
                                requirementController.alertClass = 'alert-success';
                                requirementController.alertMessage = evResult;
                            }
                        });

                        requirementController.pwdNeeded = false;
                        requirementController.otpNeeded = false;
                        break;

                    default:
                        break;
                }

                if (requirementController.otpNeeded) {
                    otpController.init.call(clickedEl, buildOtpPayload());
                }
                requirementController.alertMessage = errMsg;
                requirementController.alertClass = 'alert-success';
            } else {

            }

        }
        else {
            requirementController.alertMessage = "Due to an Internal error, your request could not be processed. Please try after some time. We regret the inconvenience caused and thank you for your support and patience.";
        }
        $('#autoShowPropertyInterest_Container .otpSubmitForm .error-container').removeClass('hide');
        $('#autoShowPropertyInterest_Container .otpSubmitForm .error-container').addClass(requirementController.alertClass);
        $('#autoShowPropertyInterest_Container .otpSubmitForm .error-container .error-msg').html(requirementController.alertMessage);
    }

    function handleApiErr() {
        var otpData = otpService.getOtpVariable();
        if (requirementController.errorLabel == 'MOBILE_ALREADY_REGISTERED') {
            if (otpData.showVerifiedSuccessSection) {
                showSuccessMessage();
            }
        } else if (requirementController.errorLabel == 'EMAIL_ALREADY_REGISTERED') {
           showSuccessMessage();
        } else {
            if (requirementController.errorLabel == 'EXISTING_USER_EMAIL_VERIFIED_LOGIN_NEEDED') {
                var jsonData = {
                    "numEmail": requirementController.enquiry_email,
                    "password": requirementController.form.password,
                    "otpRequest": 0
                };
            } else {
                if (requirementController.pwdCheck) {
                    var jsonData = {
                        "numEmail": requirementController.enquiry_phone,
                        "password": requirementController.password,
                        "otpRequest": 0
                    };
                } else {
                    var jsonData = {
                        "GreetingName": requirementController.enquiry_phone,
                        "password": '',
                        "otpRequest": 1,
                        "otpNumber" : otpData.otpNumber,
                        "otpId": otpData.otpId,
                        "page": 'REPLY',
                        "source":source()
                    };
                }

            }
            if (isSet(otpData.otpNumber) && !requirementController.login) {
                if (requirementController.projectUnitEnquiry == 'projectUnitEnquiry') {
                    sendMailSmsToCampaignUsers();
                    sendSmsToUser();
                }
                submitReplyAfterLogin(jsonData);
            }
        }
    }
    function source() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ? 'MOBILE' : 'DESK';
    }
    function submitReplyAfterLogin(jsonData) {

        gaService.addEvent('Button-RealestateResponsiveUI', 'Mobile_As_Login', requirementController.pageName + "_submit_OTP");

        var emailMatchInAcc, mobileMatchInAcc;
            login(jsonData).then((result) => {
                if (result.error == false && result.status == 'AUTH_SUCCESS') {
                    $(document).trigger("loginEvent", result.data);
                    $(".modal-header").addClass("animation");
                    requirementController.userdata = result.data.user;
                    requirementController.userdata.errorLabel = requirementController.errorLabel;
                    requirementController.userId = result.data.id;
                    requirementController.login = true;
                    requirementController.otpVerifyLead = 'OTP_VERIFIED';
                    if ('undefined' !== typeof PUB_SUB) {
                        PUB_SUB.emit('do_login', {name: setLoginName(requirementController.userdata)});
                    }
                    if ("userEmail" in result.data.user) {
                        requirementController.emailList = result.data.user.userEmail;
                        for (var i = 0; i < requirementController.emailList.length; i++) {
                            if (requirementController.enquiry_email == requirementController.emailList[i].emailId) {
                                requirementController.enquiry_email = requirementController.emailList[i].emailId;
                                emailMatchInAcc = true;
                            }
                        }
                    }

                    requirementController.addRequirement();

                    $("#thankuEmail").text(requirementController.enquiry_email);

                    if (!emailMatchInAcc) {
                        requirementController.enquiry_email = requirementController.emailList.length > 0 ? requirementController.emailList[0].emailId : requirementController.enquiry_email;
                    }

                    if ("userMobile" in result.data.user) {
                        requirementController.mobileList = result.data.user.userMobile;

                        for (var i = 0; i < requirementController.mobileList.length; i++) {
                            if (requirementController.enquiry_phone == requirementController.mobileList[i].mobileNumber) {
                                requirementController.enquiry_phone = requirementController.mobileList[i].mobileNumber;
                                mobileMatchInAcc = true;
                            }
                        }
                    }

                    $("#thankuPhone").text(requirementController.enquiry_phone);

                    if (!mobileMatchInAcc) {
                        requirementController.enquiry_phone = requirementController.mobileList.length > 0 ? requirementController.mobileList[0].mobileNumber : requirementController.enquiry_phone;
                    }

                    gaService.addEvent('link-RealestateResponsiveUI', 'Mobile_As_Login', requirementController.pageName + "_OTP_Success");
                    if (requirementController.projectUnitEnquiry != 'projectUnitEnquiry') showSuccessMessage();

                }
                else {
                    gaService.addEvent('link-RealestateResponsiveUI', 'Mobile_As_Login', requirementController.pageName + "_OTP_Failure");
                    requirementController.alertMessage = result.data.response;
                    requirementController.alertClass = 'alert-danger';
                    if (requirementController.projectUnitEnquiry != 'projectUnitEnquiry') {
                        $('#autoShowPropertyInterest_Container .otpSubmitForm .error-container').removeClass('hide');
                        $('#autoShowPropertyInterest_Container .otpSubmitForm .error-container').addClass(requirementController.alertClass);
                        $('#autoShowPropertyInterest_Container .otpSubmitForm .error-container .error-msg').html(requirementController.alertMessage);
                    }
                }
            });
    }

    $('#contactModal').on('hidden.bs.modal', function (e) {
        requirementController.thankMessage = false;
        requirementController.otpNeeded = false;
        requirementController.replyerr = false;
        requirementController.alertMessage = '';
        requirementController.alertClass = '';
    });

    function updateSession() {
        var pushEmailData = {
            'emailId': requirementController.enquiry_email,
            'isVerified': 1
        };
        if (requirementController.userdata.userEmail) {
            requirementController.userdata.userEmail.push(pushEmailData);
        } else {
            requirementController.userdata = {
                "userEmail": pushEmailData
            }
        }

        loginService.updateSession(requirementController.userdata).then(function (response) {
        });
    }

    function setData(data) {
        if (data.userEmail.length) {
            // allowmail we are setting when user come from mailer
            if(globals.allowmail){
                requirementController.enquiry_email = atob(globals.allowmail)
            }else{
                requirementController.enquiry_email = data.userEmail[0].emailId;
            }
        }
        if (data.userMobile.length) {
            requirementController.enquiry_phone = data.userMobile[0].mobileNumber;
        }
        requirementController.enquiry_name = setLoginName(data);
    }
    function setLoginName(user){
        if(user.firstName){
            return user.firstName;
        }else if(user.email){
            return user.email.substring(0, user.email.indexOf("@"));
        }else{
            return '';
        }
    }

    function getLoggedInUser() {
        // getLoggedInUser.then((result) => {
        //      if(result && result.data && result.data.user){
        //         getterSetter.set( 'loginData', result.data.user );
        //     }
        // })
        var details = getterSetter.get( 'loginData' );
        if(details && details.data && details.data.user){
            requirementController.userdata = details;
            requirementController.userId = details ? details.id : '';
            requirementController.login = true;
            setData(details.data.user);
            setLoggedInData(details.data.user);
        } else {
            requirementController.login = false;
        }
    }

    function buildRequirementPayload() {
        if (requirementController.page == "builder") {
            if (requirementController.vapProjectSnippet && requirementController.vapProjectSnippet.bhk)
                requirementController.selectBHK(requirementController.vapProjectSnippet.bhk);
            prepopulateFields();
            requirementController.selectUnitTypeTxt(requirementController.vapProjectSnippet.type);
            var unitType = requirementController.selectedUnitType || {};
        } else {
            var unitType = requirementController.selectedUnitTypeTxt || {};
        }
        
        if (window.location.href.indexOf('/qproject/') > 0) {
            requirementController.reqSources = 'REQUIREMENT_POPUP_QPROJECT';
        }

        requirementController.vapProjectSnippet =  (globals.pageType == 'VAP')? JSON.parse(atob(globals.projectSnippet)):globals.projectSnippet;

        var leadPayload = {
            'userName': requirementController.enquiry_name || '',
            'cityId': requirementController.propertySnippet.cityId || requirementController.vapProjectSnippet.address.cityId,
            'phone': requirementController.selectedIsdCode + '-' + requirementController.enquiry_phone,
            'emailId': requirementController.enquiry_email,
            "projectIds": "" + (isSet(requirementController.vapProjectSnippet) ? requirementController.vapProjectSnippet.id : projectId),
            "projectId": "" + (isSet(requirementController.vapProjectSnippet) ? requirementController.vapProjectSnippet.id : projectId),
            "localityIds": (getLocationIds() || "" + (isSet(requirementController.vapProjectSnippet) ? requirementController.vapProjectSnippet.localityId : '')),
            "channel": windowWidthService.channel,
            "furnishings": requirementController.furnishing,
            "bhks": (unitType.type || requirementController.selectedBHK || '').toString().replace(' BHK', ''),
            "areaMin": (unitType.superArea || requirementController.areaMin) || 0,
            "areaMax": (unitType.superArea || requirementController.areaMax) || 0,
            "postedBy": (requirementController.user && requirementController.user.type) ? requirementController.user.type : 'INDIVIDUAL',
            "propertyTypes": requirementController.selectedUnitTypeTxt,
            "isGrabhouse":requirementController.propertySnippet.isGrabhouse,
            'source': requirementController.reqSources,
            "loanRequirement" : loanRequirement,
            "referral":location.hostname,
            "verifiedStatus" : requirementController.otpVerifyLead?requirementController.otpVerifyLead:'NOT_VERIFIED'
        };
        dataService.saveDatalocaStorage({
            "autofill_contactname": requirementController.enquiry_name || '',
            "autofill_email": requirementController.enquiry_email || '',
            "autofill_no": requirementController.enquiry_phone || '',
        });

        leadPayload.priceMin = requirementController.want_ad_tree.about_property.price_range.value.min;
        leadPayload.priceMax = requirementController.want_ad_tree.about_property.price_range.value.max;

        if (unitType.bathRooms) leadPayload.bathrooms = "" + unitType.bathRooms;
        if (requirementController.gaAction == "Vap") {
            if (leadPayload.priceMax < 0 && leadPayload.priceMin >= 0) leadPayload.priceMax = leadPayload.priceMin;
            if (leadPayload.priceMin < 0 && leadPayload.priceMax >= 0) leadPayload.priceMin = leadPayload.priceMax;
            leadPayload.areaMin = requirementController.propertySnippet.area || 0;
            leadPayload.areaMax = requirementController.propertySnippet.area || 0;
            leadPayload.builderId = requirementController.builderId;
        }
        if (leadPayload.priceMin < 0) leadPayload.priceMin = 0
        if (leadPayload.priceMax < 0) leadPayload.priceMax = 0
        //var category = (['Residential', 'Agriculture', 'Commercial'].indexOf(projectSnippet.category) >= 0) ? projectSnippet.category : 'Residential';
        var category = 'Residential';

        switch (requirementController.selectedUnitTypeTxt) {
            case 'Apartment':
            case 'BuilderFloor':
            case 'Villa':
            case 'Flatmates':
            case 'PG':
            case 'Plot':
                category = 'Residential';
                break;
            case 'Land':
                category = 'Agriculture';
                break;
            case 'Shop':
            case 'Complex':
            case 'Office':
                category = 'Commercial';
                break;
        }

        leadPayload.category = category;
        leadPayload.propertyFor = requirementController.adType || "buy"
        switch (leadPayload.propertyFor) {
            case "sale":
                leadPayload.propertyFor = 'buy';
                break;
            case "rent":
                leadPayload.propertyFor = "rent_in";
                break;
            case "pg":
                leadPayload.propertyFor = "pg_in";
                break;
            case "flatmates":
                leadPayload.propertyFor = "flatmate";
                break;
        }
        sendAlertPayload = new inhiretAlertPayLoad(leadPayload);
        leadPayload.projectId = (leadPayload.projectId ==='null' )?'':leadPayload.projectId;
        leadPayload.projectIds = (leadPayload.projectIds ==='null' )?'':leadPayload.projectIds;
        leadPayload.localityIds = (leadPayload.localityIds ==='null' )?'':leadPayload.localityIds;
        return leadPayload;
    }

    requirementController.selectBHK = function (data) {
        requirementController.selectedBHK = data;
    }

    requirementController.selectUnitTypeTxt = function (data) {
        if (['Land', 'Plot', 'Shop', 'Complex', 'Office', 'PG'].indexOf(data) >= 0) {
            requirementController.showBHKDropDown = false;
        } else {
            requirementController.showBHKDropDown = true;
        }

        requirementController.selectedUnitTypeTxt = data;
    }

    requirementController.getTopBanks = function (){
        requirementController.topBanksDetail = getterSetter.get( 'topBankData' );
    }

    requirementController.getArea = function (u) {
        if (!u.plotArea && !u.superArea) {
            var area = u.minArea + " " + u.areaUnit + " - " + u.maxArea + " " + u.areaUnit;
            return area
        } else {
            return (u.subType && (u.subType.toLowerCase() === "plot")) ? u.plotArea : u.superArea;
        }
    }

    requirementController.getPrice = function (u) {
        if (!u.plotArea && !u.superArea) {
            var area = u.minArea + " " + u.areaUnit + " - " + u.maxArea + " " + u.areaUnit;
            return area
        } else {
            return (u.subType && (u.subType.toLowerCase() === "plot")) ? u.plotArea : u.superArea;
        }
    }

    requirementController.getFrmtTextUnits = function (u) {
        if (u) {
            if (u.type && u.subType) {
                return u.type + ' - ' + u.subType;
            } else {
                return u.type || u.subType || "-";
            }
        }
    };

    function buildBuilderSmsPayload(sendTo, senderName) {
        var sms_content = "Hello " + senderName + ",\n\nYou have received an unverified lead for " + (requirementController.vapProjectSnippet.name ? "Project " + requirementController.vapProjectSnippet.name : "Ad " + requirementController.vapProjectSnippet.title) + " from QuikrHomes.\nPlease call +" + requirementController.selectedIsdCode + "-" + requirementController.enquiry_phone + " immediately.";

        var smsPayload = {
            "mobile": ["" + sendTo],
            "sms_content": sms_content,
            "smsFrom": "HighPriorityQueue",
            "notification_engine_sms_context": 23
        };

        return smsPayload;
    };

    function buildBuilderMailPayload(obj) {
        var payload = {
            "to": obj.toEmail,
            "emailFrom": "RegularQueue",
            "track": "yes",
            "notification_engine_email_context": 23,
            "from_address": "service@quikr.com",
            "from_name": "Quikr Service",
            "replyto": "service@quikr.com",
            "subject": "Good news! we have a lead for you",
            "body": mailService.getPropertyCampaignUserContent(obj)
        };

        return payload;
    }

    function enquiryProcess() {
         var smsPayload = {
            "id": requirementController.propertySnippet.id || null,
            "enquiry_mob": requirementController.enquiry_phone || null,
            "enquiryType": "R"
          };
         if (smsPayload) dataService.enquiryProcess(smsPayload);
    }

    function sendSmsToPoster() {
        var id = requirementController.sellerSnippet.id || null;
        var mob = requirementController.sellerSnippet.mobileNo || null;
        var name = requirementController.sellerSnippet.name || "";
        if (!mob || mob.toString().length < 10) return;
        var smsPayload = buildPosterSmsPayload(mob,name);
        if (smsPayload) dataService.sendSms(smsPayload);
    }


    function buildPosterSmsPayload(sendTo, senderName) {
        var sms_content = "Hello " + senderName + ",\n\nSomeone is interested in Ad " + requirementController.propertySnippet.title + " from QuikrHomes.\nPlease call +91" + requirementController.enquiry_phone + " immediately.";
        var smsPayload = {
            "mobile": ["" + sendTo],
            "sms_content": sms_content,
            "smsFrom": "HighPriorityQueue",
            "notification_engine_sms_context": 23
        };

        return smsPayload;
    }

    function deflateObj() {
        var form = getFormObject(clickedEl.closest("form[name='requirementForm']"));
        requirementController.enquiry_phone = form.mobile ? form.mobile.replace(",", "") : requirementController.enquiry_phone;
        requirementController.enquiry_email = form.email ? form.email.replace(",", "") : requirementController.enquiry_email;
        requirementController.enquiry_name = form.name ? form.name.replace(",", "") : requirementController.enquiry_name;
        requirementController.selectedIsdCode = $('.stdcode select').val();
    }
    function averagePriceArea(a,b){
        if(a && b){
          return parseInt(((a + b) / 2).toFixed(0));  
        }else{
            return 0;
        }
        if(a) return parseInt(a);
        if(b) return parseInt(b);

    }
    function getCategory() {
      buildRequirementPayload();
      var localityName = '';
        if (globals.pageType == 'VAP') {
            var catId = getSubCategory.subCatId(requirementController.propertySnippet.propertyFor, requirementController.propertySnippet.type);
            var attributesMap = {"city":[requirementController.propertySnippet.city],"area_Sq_Feet":[parseInt(requirementController.propertySnippet.area)],"Price":[requirementController.propertySnippet.price],"No_of_Rooms":[parseInt(requirementController.propertySnippet.noOfBedrooms)]};
            if(catId == 217){
                attributesMap["Type_of_land"] = [requirementController.propertySnippet.category];
            }
            // if(requirementController.propertySnippet.type == 'flatmate' || requirementController.propertySnippet.type == 'pg' ){
            //     attributesMap["Accommodation_for"] = [requirementController.propertySnippet.category];
            // }

            if(catId == 114 || catId == 138 && requirementController.propertySnippet.attributes.accommodationFor){
                attributesMap["Accommodation_for"] = [requirementController.propertySnippet.attributes.accommodationFor];
            }
            if(requirementController.propertySnippet.locality[0] != undefined){
                localityName = [requirementController.propertySnippet.locality[0]];
            }
        }else {
          var propertType = requirementController.vapProjectSnippet.type;
          var intent = 'sell';
          var catId = getSubCategory.subCatId(intent, sendAlertPayload.buildPaylodDetails().propertyTypes);
          var attributesMap = {"city":[requirementController.vapProjectSnippet.address.city],"Price":[averagePriceArea(sendAlertPayload.buildPaylodDetails().priceMin,sendAlertPayload.buildPaylodDetails().priceMax)]};
            if(requirementController.bedRooms && 'REQUIREMENT_POPUP_PROJECT' != requirementController.reqSources){
               attributesMap["No_of_Rooms"] = [parseInt(requirementController.bedRooms)];
               attributesMap["area_Sq_Feet"] = [parseInt(requirementController.areaAvg)]; 
            }
            if(catId == 217){
                attributesMap["Type_of_land"] = [sendAlertPayload.buildPaylodDetails().category];
            }
            
            if(requirementController.selectedLocations[0] != undefined){
                localityName = [requirementController.selectedLocations[0].title];
            }
        }
      
      var alertPayload =   {
          "email":requirementController.enquiry_email,
          "source":requirementController.reqSources,
          "mobile":requirementController.enquiry_phone,
          "alertEntityType":0,
          "attributesMap":attributesMap,
          "cityId":sendAlertPayload.buildPaylodDetails().cityId,
          "locations": localityName,
          "alertStatus":1,
          "alertCreateType":"implicit",
          "metaCatGlobalId":20,
          "subCatGlobalId":catId,
          "userId":requirementController.userId,
          "alertType":"buy",
          "referrer":windowWidthService.PlatFormChannel,
          "useragent":window.navigator.userAgent.substring(0, 90)
      };
      $.ajax({
          url: "/homes/re2/createAlertNew?aj=1",
          type: "POST",
          data: alertPayload,
          success: function(data){

          }
      });
    }

    requirementController.showHideBank = function (e) {
        if($(e).prop("checked")){
            loanRequirement='YES';
        }else{
            loanRequirement='NO';
        }
    };

    requirementController.submitRequirement = function (obj) {
        clickedEl = $(obj);
        if (validate()) {
            deflateObj();
            var gtmData = {
                'phone': requirementController.enquiry_phone || '',
                'emailId': requirementController.enquiry_email || ''
            };
            //gtmService.gtmContactReq('CONTACT_SUCCESS', gtmData);
            if ('PROJECT_CONTACT_IN_SNB' == requirementController.reqSources) {
                requirementController.want_ad_tree.about_property.price_range.value.min = requirementController.vapProjectSnippet.priceRange.low || 0;
                requirementController.want_ad_tree.about_property.price_range.value.max = requirementController.vapProjectSnippet.priceRange.high || 0;
                requirementController.selectedBHK = requirementController.vapProjectSnippet.bhk;
                requirementController.areaMin = requirementController.vapProjectSnippet.minimumArea || 0;
                requirementController.areaMax = requirementController.vapProjectSnippet.maximumArea || 0;
                requirementController.areaAvg = averagePriceArea(requirementController.vapProjectSnippet.minimumArea,requirementController.vapProjectSnippet.maximumArea);
                requirementController.vapProjectSnippet.localityId = requirementController.vapProjectSnippet.address.localityId || '';
                requirementController.selectedLocations.push({'title':requirementController.vapProjectSnippet.address.locality} || '')
            }
            if('PROJECT_CONTACT_IN_PROJECT' == requirementController.reqSources || 'PROJECT_CONTACT_IN_BUILDER' == requirementController.reqSources){
                if(requirementController.selectedUnitType && requirementController.selectedUnitType.superArea){
                    requirementController.areaMin = requirementController.selectedUnitType.superArea;
                    requirementController.areaMax = requirementController.selectedUnitType.superArea;
                    requirementController.areaAvg = requirementController.selectedUnitType.superArea;
                }
                if(requirementController.vapProjectSnippet && requirementController.vapProjectSnippet.address && requirementController.vapProjectSnippet.address.locality){
                    requirementController.selectedLocations.push({'title':requirementController.vapProjectSnippet.address.locality} || '')
                }
                if(requirementController.selectedUnitType){
                    requirementController.bedRooms = requirementController.selectedUnitType.bedRooms;
                }

            }
            getCategory();
            requirementController.projectUnitEnquiry = 'projectUnitEnquiry';
            if (!requirementController.replyerr) {
                requirementController.detectEmailMobileConflict();
            } else {
                handleApiErr();
            }

        }
    }

    function sendSmsToUser() {

        var smsPayload = {  
            "id": requirementController.vapProjectSnippet.id || null,
            "enquiry_mob": requirementController.enquiry_phone || null,
            "enquiryType": "PROJECT_CONTACT_IN_PROJECT" 
          };

        dataService.enquiryProcess(smsPayload);
        requirementController.thankMessage = true;
    }

    function sendMailSmsToCampaignUsers() {
        var data = dataService.projectCampaignUserDetails;

        dataService.saveAutoFillData({
            "autofill_contactname": requirementController.enquiry_name || '',
            "autofill_email": requirementController.enquiry_email || '',
            "autofill_no": requirementController.enquiry_phone || '',
        });

        if (data) {
            fireMailSmsToCampaignUsers(data.data);
        } else {
            if (projectId) {
                dataService.fetchProjectCampaignUserDetails(projectId).then(function (response) {
                    if (isSet(response) && isSet(response.response)) fireMailSmsToCampaignUsers(JSON.parse(response.response).data);
                });
            }
        }
    }

    function fireMailSmsToCampaignUsers(data) {
        var mailPayload;
        var phone;
        var budget = 0;
        var lookingFor;
        var address = requirementController.vapProjectSnippet.address || {};
        var unitType = requirementController.selectedUnitType ? requirementController.getFrmtTextUnits(requirementController.selectedUnitType) : "";

        lookingFor = unitType ? unitType + " " : "";
        lookingFor += requirementController.vapProjectSnippet.name + " ";
        lookingFor += (address.locality ? address.locality : "") + (address.city ? " " + address.city : "");
        lookingFor = lookingFor.replace(/^\s+|\s+$/g, '');

        if (requirementController.selectedUnitType) {
            budget = requirementController.selectedUnitType.price;
        } else if (requirementController.want_ad_tree.about_property.price_range.value.min > 0) {
            budget = requirementController.want_ad_tree.about_property.price_range.value.min;
        }

        var projectUrl = urlBuilderService.getProjectUrl({
            "data": {
                "projectName": requirementController.vapProjectSnippet.name,
                "locality": requirementController.vapProjectSnippet.address.locality,
                "city": requirementController.vapProjectSnippet.address.city,
                "projectId": projectId
            }
        });

        var mailObj = {
            "title": requirementController.vapProjectSnippet.name,
            "redirect": projectUrl,
            "userName": requirementController.enquiry_name,
            "userMobile": "+" + requirementController.selectedIsdCode + requirementController.enquiry_phone,
            "userEmail": requirementController.enquiry_email,
            "time": (new Date()).toString(),
            "lookingFor": lookingFor,
            "budget": budget
        }

        _.each(data, function (obj) {
            phone = obj.leadPhoneNumber;
            if (phone && phone.length > 10) phone = phone.slice(phone.length - 10);
            mailObj.toEmail = obj.leadEmail;
            mailObj.toName = obj.leadName;
            mailObj.toPhone = phone;
            mailPayload = buildBuilderMailPayload(mailObj);

            if (mailPayload) dataService.sendMail(mailPayload);
            if (phone) dataService.sendSms(buildBuilderSmsPayload(phone, obj.leadName));
        });
    }

    requirementController.addRequirement = function () {
        if (!requirementController.login){
            getLoggedInUser();
        }
        requirementService.addRequirement(buildRequirementPayload());
    }

    function validate() {
        var $form = clickedEl.closest("form[name='requirementForm']");
        var mobileElement = $form.find(":input[name='mobile']");
        var emailElement = $form.find(":input[name='email']");
        var mobileValid = formValidator.isMobileNoValid.call(mobileElement);
        var emailValid = formValidator.isEmailValid.call(emailElement);
        return (mobileValid && emailValid) || (requirementController.enquiry_phone && requirementController.enquiry_email);
    }

    requirementController.submit = function (obj) {
        if (globals.pageType == 'VAP') {
            requirementController.reqSources = 'REQUIREMENT_POPUP_FLP';
            requirementController.builderId = globals.builderSnippet ? globals.builderSnippet.id : '';
        } else {
            requirementController.reqSources = 'REQUIREMENT_POPUP_PROJECT';
        }
        clickedEl = $(obj);
        requirementController.projectUnitEnquiry = '';
        if (validate()) {
            deflateObj();
            if(!requirementController.saveAdcheck || globals.pageType == 'project'){
                getCategory();
            }
            if (!requirementController.replyerr) {
                requirementController.detectEmailMobileConflict();
            } else {
                handleApiErr();
            }

            addEventRequirement(requirementController.gaCategory, requirementController.gaAction, requirementController.gaLabel);
            if (requirementController.gaAction == "Vap") sessionStorage.setItem('count', 3);

        }
    }

    /*    function deflatePropertyInterestObj() {
     var form = Common.getFormObject($("form[name='requirementForm']"));
     requirementController.enquiry_phone = form.mobile;
     requirementController.enquiry_email = form.email;
     requirementController.enquiry_name = form.name;
     }*/


    function addEventRequirement(cat, action, label) {
        gaService.addEvent(cat, "Alert_" + action + "_Real-Estate", label);
    }

    function setGaParams() {
        var projectName = requirementController.vapProjectSnippet ? requirementController.vapProjectSnippet.name : '';
        var action = 'project'
        action = globals.pageType;

        if(action == "VAP"){
            action = 'Vap';
        }
        // var action = projectName ? "Project" : "Vap";
        requirementController.gaAction = action;
        if (!isSet(projectName) && globals.pageType == 'builder') {

            requirementController.gaAction = globals.pageType;
        }

        requirementController.gaCategory = "button-RealestateResponsiveUI";
        requirementController.gaLabel = "click_submit";
        if (requirementController.gaAction == 'project') {
            requirementController.reqSources = 'REQUIREMENT_POPUP_PROJECT';
        } else {
            requirementController.reqSources = 'REQUIREMENT_POPUP_FLP';
            // var builderSnippt = (pScope.listingDetailResponseObj) ? pScope.listingDetailResponseObj.builderSnippet : {};
            // requirementController.builderId = builderSnippt.id || ''
        }
    }

    requirementController.selectUnitType = function (u, el) {
        if (typeof u != 'object') {
            var projectUnits = requirementController.getProjectUnits();
            projectUnits.filter(function(obj) {
                if(obj.id == parseInt(u)){
                    requirementController.selectedUnitType = obj;
                }
            });
        }
        
        if (el) {
            var aText = requirementController.getFrmtTextUnits(requirementController.selectedUnitType) + " " +
                areaFilter(requirementController.getArea(requirementController.selectedUnitType), requirementController.selectedUnitType.areaUnit);
            $(el).closest('.dropdown-menu-open').find('label').text(aText);
            var priceText = '';
            if (!!requirementController.selectedUnitType.price) {
                priceText = currencyFilter(requirementController.selectedUnitType.price);
            }
            else if (!requirementController.selectedUnitType.price && requirementController.selectedUnitType.maxSalePrice) {
                priceText = currencyFilter(requirementController.selectedUnitType.minSalePrice) + "-" +
                    currencyFilter(requirementController.selectedUnitType.maxSalePrice);
            }

            $(el).closest('.input-div').find(".price-add b").text(priceText);
        }
    };

    function setAlert() {
        var $alertbox = $('.alertmsgbox');
        if (alertMessage)
            $alertbox.removeClass('hide').find('.otpalert').addClass(alertClass).find('span').text(alertMessage);
        else
            $alertbox.addClass('hide').find('.otpalert').removeAttr('class').addClass('otpalert').find('span').text('');
    }

    function resetMetaData() {
        counter = 0;
        requirementController.otpNeeded = false;
        requirementController.replyerr = false;
        requirementController.alertMessage = '';
        requirementController.alertClass = '';
        getLoggedInUser();
        requirementController.enquiry_name = requirementController.enquiry_name ? requirementController.enquiry_name : get("autofill_contactname") || '';
        requirementController.enquiry_email = requirementController.enquiry_email ? requirementController.enquiry_email : get("autofill_email") || '';
        requirementController.enquiry_phone = requirementController.enquiry_phone ? requirementController.enquiry_phone : get("autofill_no") || '';
    }

    function initView() {
        requirementController.getTopBanks();
        _tplRender('propertyInterest',function(template){
            var tpl = _.template(template);
            $("#contactModal_container .newmodal").html(tpl({
                'requirementController': requirementController,
                'currencyFilter': currencyFilter,
                'areaFilter': areaFilter
            })); 
        })
    }

    requirementController.userConvert = function (value){
        if(value == 'Individual'){
            return 'Owner'
        }else{
            return 'Broker'
        }

    };

    requirementController.checkSaveAdReply = function (value){
        requirementController.saveAdcheck = !value;
    }

    requirementController.renderPropertyInterest = function (u, reqSources) {
        resetMetaData();
        requirementController.reqSources = reqSources;
        requirementController.selectUnitType(u);
        initView();
    };

    requirementController.renderSNBPropertyInterest = function (u, reqSources) {
        resetMetaData();
        requirementController.reqSources = reqSources;
        if (isSet(u)) {
            requirementController.vapProjectSnippet = JSON.parse(atob(u));
        }
        initView();
    };

    requirementController.renderPropertyInterestForBrochure = function (u, reqSources) {
        counter = 0;
        requirementController.enquiry_email = '';
        requirementController.enquiry_phone = '';
        requirementController.enquiry_name = '';
        requirementController.otpNeeded = false;
        requirementController.replyerr = false;
        requirementController.alertMessage = '';
        requirementController.alertClass = '';
        requirementController.reqSources = reqSources;
        requirementController.selectedUnitType = '';

        getLoggedInUser();
        requirementController.getTopBanks();
        _tplRender('propertyInterest',function(template){
            var tpl = _.template(template);
            $("#contactModal_container .newmodal").html(tpl({
                'requirementController': requirementController,
                'currencyFilter': currencyFilter,
                'areaFilter': areaFilter
            })); 
        })   
    };

    requirementController.setData = function (obj, field) {
        switch (field) {
            case 'adType':
                requirementController.adTypeValue = obj;
                requirementController.adType = obj;
                break;

            case 'priceMin':
                requirementController.want_ad_tree.about_property.price_range.value.min = obj;
                break;

            case 'priceMax':
                requirementController.want_ad_tree.about_property.price_range.value.max = obj;
                requirementController.MinPriceDisable = obj;
                break;

            case 'mobile':
                requirementController.enquiry_phone = (obj.mobile ? obj.mobile : obj) || '';
                requirementController.loginCheck = false;
                $("#selectedEnquiryPhone").text(requirementController.enquiry_phone);
                break;

            case 'email':
                requirementController.enquiry_email = (obj.email ? obj.email : obj) || '';
                requirementController.loginCheck = false;
                $("#selectedEnquiryEmail").text(requirementController.enquiry_email);
                break;

            default:
        }
    };

    requirementController.reRenderAlertTopPanel = function () {
        requirementController.getTopBanks();
        var $createAlertPopup = $('#autoShowPropertyInterest_Container .topPanel');
        var htmlData = _.template($('#autoShowPropertyInterestTopPanel').html());
        $createAlertPopup.html(htmlData({
            'requirementController': requirementController,
            'currencyFilter': currencyFilter
        }));

        var substringMatcher = function () {
            return function findMatches(q, cb, cb2) {
                var matches = [];

                // requirementController.getSuggestedLocality = function () {
                var cityId = requirementController.propertySnippet.cityId || requirementController.vapProjectSnippet.address.cityId;
                if (q && cityId) {
                    dataService.getLocalitySearchNew({
                        'query': q,
                        'cityId': cityId,
                        'limit': 8
                    }).done(function (data) {
                        if (isSet(data)) {
                            cb2(data);
                        }
                    });
                } else {
                    requirementController.selectCityError = true;
                }
                // };
            };
        };

        $('input[name="locality"]').typeahead({
                hint: false,
                highlight: true,
                minLength: 3
            },
            {
                limit: 18,
                name: 'destination1',
                display: 'title',
                source: substringMatcher()
            }).bind('typeahead:select', function (ev, suggestion) {
            requirementController.locationSelection(suggestion, 1, true);
        });
    }

    requirementController.reRenderAlertBottomPanel = function () {
        requirementController.getTopBanks();
        if(requirementController.loginCheck){
            getLoggedInUser();
        }
        var $createAlertPopup = $('#autoShowPropertyInterest_Container .bottomPanel');
        var htmlData = _.template($('#autoShowPropertyInterestBottomPanel').html());
        $createAlertPopup.html(htmlData({'requirementController': requirementController, 'currencyFilter': currencyFilter}))
    }

    function constructUrls(imageUrl) {
        var imageUrls = {};
        if(imageUrl && imageUrl.length){
            var stringLength = imageUrl.length;
            var lastStaticIndex = imageUrl.lastIndexOf('nr');
            var lastDecimalIndex = imageUrl.lastIndexOf('.');
            var largeImageAvailable = imageUrl.lastIndexOf('sm');
            if (-1 !== largeImageAvailable)imageUrls.small = staticImageHost + imageUrl.substr(0,  lastStaticIndex) + '_sm' + imageUrl.substr(lastDecimalIndex, (stringLength - 1));
        } else {
            imageUrls.small = window.CDNURL+'/image/villa.jpg';
        }
        return imageUrls;
    }

    function autoPopulateLocality() {
        var pSnippet = requirementController.vapProjectSnippet.id ? requirementController.vapProjectSnippet : requirementController.vapProjectSnippet;
        if (!pSnippet.localityId) return;
        var locality = pSnippet.address.locality || '';
        requirementController.locality.values = locality;
        requirementController.locationSelection({
            'id': pSnippet.localityId,
            'title': locality
        })
    }

    function autoPopulateLocalityVap() {
        if (isSet(requirementController.propertySnippet) && isSet(requirementController.propertySnippet.localityIds) && requirementController.propertySnippet.localityIds.length > 0 && requirementController.propertySnippet.localityIds[0]){
            var locality = requirementController.propertySnippet.locality[0] ? requirementController.propertySnippet.locality[0] : '';
            requirementController.locality.values = locality;
            requirementController.locationSelection({
                'id': requirementController.propertySnippet.localityIds[0],
                'title': locality
            })
        }


    }

    function prepopulateFields() {

        if (isSet(requirementController.vapProjectSnippet) && typeof requirementController.vapProjectSnippet === 'object') {
            requirementController.selectUnitTypeTxt(requirementController.vapProjectSnippet.type);
        }
        else if (isSet(requirementController.propertySnippet)) {
            requirementController.selectUnitTypeTxt(requirementController.propertySnippet.type);
        }
        else {
            requirementController.selectUnitTypeTxt('Apartment');
        }

        if (isSet(requirementController.vapProjectSnippet) && isSet(requirementController.vapProjectSnippet.projectUnits)) {
            var min = 0;
            var max = 0;

            _.each(requirementController.vapProjectSnippet.projectUnits, function (unit) {
                if (unit.type) requirementController.bhkValues.push(unit.type.slice(0, 1));
                unit.price = parseInt(unit.price);
                if (!min) min = unit.price;
                if (unit.price < min) min = unit.price;
                if (unit.price > max) max = unit.price;
            });

            requirementController.want_ad_tree.about_property.price_range.value.min = min;
            requirementController.want_ad_tree.about_property.price_range.value.max = max;

            //   requirementController.bhkValues = bhkValues.sort();
        } else {
            requirementController.want_ad_tree.about_property.price_range.value.min = requirementController.propertySnippet.price || -1;
            switch (requirementController.propertySnippet.propertyFor) {
                case "sale":
                    requirementController.adType = "buy";
                    requirementController.adTypeValue = "buy";
                    break;
                case "rent":
                    requirementController.adType = "rent_in";
                    requirementController.adTypeValue = 'rent_in';
                    break;
                case "pg":
                    requirementController.adType = "pg_in";
                    requirementController.adTypeValue = 'pg_hostels';
                    break;
                case "flatmate":
                    requirementController.adType = "flatmate";
                    requirementController.adTypeValue = 'pg_hostels';
                    break;
            }
        }

        requirementController.selectBHK(requirementController.propertySnippet.noOfBedrooms || requirementController.bhkValues.slice(-1) || 1);

        /*if (requirementController.gaAction == "Vap") {
            autoPopulateLocalityVap();
            requirementController.setEnquiryBhk = requirementController.selectedBHK ? requirementController.selectedBHK+'BHK':''
            requirementController.setEnquiryUnit = requirementController.selectedUnitTypeTxt ? requirementController.selectedUnitTypeTxt : ''
            if(requirementController.sellerSnippet && requirementController.sellerSnippet.name){
                requirementController.setEnquiryname = requirementController.sellerSnippet.name;
            }else if(requirementController.sellerSnippet && requirementController.sellerSnippet.email){
                requirementController.setEnquiryname = requirementController.sellerSnippet.email.substring(0,requirementController.sellerSnippet.email.indexOf("@"));
            }else{
                requirementController.setEnquiryname = '';
            }
            requirementController.furnishing = requirementController.propertySnippet.furnished || '';
         }
         if(requirementController.gaAction == 'project') {
            autoPopulateLocality();
        }*/
    }

    /*
     requirementController.leadSubmit = function() {
     if (validate()) {
     var url = pScope.leadData.url;
     pScope.closeModal();
     addRequirement();
     if (pScope.leadData.download)
     forceDownload.download(url);
     else
     window.open(url, 'Download');
     }
     return;
     };
     */

    //       setUser();
    prepopulateFields();

    return requirementController;

}();

$(function () {
    //  $(document).on('keyup', "form[name='requirementForm'] input[name='locality']", requirementController.getSuggestedLocality);
    window.addEventListener('loginEvent', function (e) {
        requirementController.login = true;
        requirementController.userId = e.data.user ? e.data.user.id : '';
        requirementController.login = true;
        requirementController.name = isSet(e.data.user.firstName) ? e.data.user.firstName : '';
        requirementController.enquiry_phone = isSet(e.data.user.userMobile) ? e.data.user.userMobile[0].mobileNumber : '';
        requirementController.enquiry_email = isSet(e.data.user.userEmail) ? e.data.user.userEmail[0].emailId : '';
    }, false);
});