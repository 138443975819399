import {elmYPosition, smoothScroll} from "../utils/common";

export const getRating = (entityType,entityId,name)=> {
	let formData = makeFormData({
        'entityType': entityType,
		'entityId': entityId,
		'entityName':name
    });
    var url = '/homes/re2/getEntityRating?aj=1';

    postData(url, formData).then(result => {
    	if(result && result != null && result.trim() !="[]" && result.length >= 1){
			$("#entityRating").html(result);
		} else {
            $("#entityRating").addClass('hide');
             if(document.querySelector('#ratingtab') != null) {
                document.querySelector('#ratingtab').classList.add('hide');
             }
        }
    }).catch(err => console.log(err));
}

export const getOverallRating = (entityType,entityId)=> {

	let formData = makeFormData({
        'entityType': entityType,
		'entityId': entityId
    });

    var url = '/homes/re2/getOverallRating?aj=1';

    postData(url, formData).then(response => {
       if(response && response != null && response.trim() !="[]" && response.length >= 1){
            $("#entityOverRating").html(response);
            document.querySelector('[data-scroll-to=entityRating]')
                .addEventListener('click', () => {
                    const elRatingSection = document.querySelector('#entityRating');
                    if (elRatingSection) smoothScroll(elmYPosition(elRatingSection), 300);
                });
		} else {
            $("#entityOverRating").addClass('hide');
        }
    }).catch(err => console.log(err));
}

function makeFormData(obj = {}) {
    let formData = new FormData();
    for (let key in obj) {
        formData.append(key, obj[key])
    }
    return formData;
}

function postData(url = '', data = '', headers = {}) {
    return fetch(url, {
        method: "POST",
        body: data,
        headers: headers
    }).then(response => response.text() );
}