import lozad from 'lozad'
import '../assets/sass/web/_project.scss'
import {isSet,getterSetter,setCity,loadMapJs,dayDifference,isMobile,connectionCheck, openOwnerConfirmationPopup} from './utils/common'
import './utils/carousel'
import {googleMap} from './controller/googleMap'
import {getLoggedInUser} from './loginService'
import {setCookie,getCookie} from './services/cookieService'
import {set,get} from './services/localStorageService'
import {requirementService} from './services/requirementService'
import {searchActionService} from './services/searchActionService'
import {windowWidthService} from './services/windowWidthService'
import {urlBuilderService} from './services/urlBuilderService'
import {projectGtm,gtmClickButton,eventTriggerQH,eventTriggeranalatyic} from './services/gtmService'
import {adServicesInit} from './services/adServices'
import './reply/projectReplyservice'
import './reply/whatsappReplyService';
import {scrollService,currentYPosition,elmYPosition} from './services/anchorSmoothScrollService.js'
import {searchSuggest} from './services/recentSearchService'
import {getRating,getOverallRating} from './services/rating'
import {upVote, downVote, propertySoldOut} from './services/voting'
import WebPush from 'q-push-notifications';
import {swiping} from './services/swipe'
import showGenericPreRequestModal from 'q-push-notifications/lib/prototypes/optional/showGenericPreRequestModal';
import {initForum,functionalityToUser} from './services/forum';
import appDnlModalController from './controller/appDnlModalController';

getLoggedInUser.then((result) => {
    if(result && result.data && result.data.user){
        getterSetter.set( 'loginData', result);
        getterSetter.set( 'loginstatus', true);
    }else{
        getterSetter.set( 'loginstatus', false);
    }
    Project.getQueryVariables('ampredirect')
})
require('./controller/reviewController');
require('./services/gaService.js')
require('./services/Lightbox.js')
require('./controller/EnquiryController.js')
require('./controller/requirementController.js')
require('./controller/otpController.js')
if(!globals.realityproject){
    require('./controller/header.js')
}else{
    require('./services/analytic.js')
    require('./controller/shortlistController'); 
}
var banner = staticImageHost + 'restatic/image/default-ban.jpg';
var projectAdResponse = {};
var projectSnippet = JSON.parse(atob(globals.projectSnippet));
projectAdResponse = globals;
projectAdResponse.projectSnippet = projectSnippet;
var Project = {
    init: function () {
        // Open owner confirmation pop up
        openOwnerConfirmationPopup();

        setCity(projectSnippet.address.city,projectSnippet.address.cityId)

        $('.scrollNav').on('click', '.scrollWindow', Project.scrollWindowTo);
        $('.mediatabgrid').on('click', '.media-tab', selectTab);
        $('.project-map').on('click', '.map-tab', selectTab);
        $('.buyrentswitch').on('click', '.txn-tab', selectTab);

        $('#projectCarousel').carousel();
        Project.getHashWonbo();
        Project.getOffer();
        Project.openCreateAlert();

        if(!globals.realityproject){
            Project.addRequirement();
        }
        
        Project.initUnitContactModal();
        gaService.addPageView('Project&' + window.location.href.split('/').pop(), 'RealEstate'); 
        
        
        if(typeof js_lightbox_popup!=='undefined'){
            $(".lgtlist").html(js_lightbox_popup);
        }
        if(typeof lght_cnt_btn_html!=='undefined'){
            $(".lightbox-topnav ul").after(lght_cnt_btn_html);
        }
        if(typeof total_lpcount!=='undefined'){
            $(".lgtlist").addClass('lp'+total_lpcount);
        }
        $('.unittab').on('click', '.selectUnit', selectTab);
        
        //Voting Count Start..
        let upvoteclick = document.querySelectorAll('.upvote');
        let downvoteclick = document.querySelectorAll('.downvote');
        let noreasonclick = document.querySelectorAll('.noreason');
        let reasonformsubmit = document.querySelector('#reasonformsubmit');
        let yesPropertyAvlSubmit = document.getElementById('yesPropertyAvl');
        let noPropertyAvlSubmit = document.getElementById('noPropertyAvl');
        if(upvoteclick.length > 0) {
            Array.from(upvoteclick).forEach(vote => {
                vote.addEventListener('click', function(event) {
                    event.preventDefault();
                    event.stopPropagation();
                    let voteType = ($(this).hasClass('active')) ? 'UP_N' : 'UP_Y';
                    gaService.addEvent('RealEstate','QH_Voting_Upvote','QH_Voting_Upvote_click');
                    if(getterSetter.get( 'loginstatus')){
                        let user_Details = getterSetter.get('loginData');
                        let loginUserId = user_Details.data.user.id;
                        let userId = loginUserId;
                        upVote('Project', globals.projectSnippet.id, userId, voteType);
                    } else {
                        PUB_SUB.emit('login_modal');
                    }
                });
            });
        }
        if(downvoteclick.length > 0) {
            Array.from(downvoteclick).forEach(vote => {
                vote.addEventListener('click', function(event) {
                    event.preventDefault();
                    event.stopPropagation();
                    let voteType = ($(this).hasClass('active')) ? 'DOWN_N' : 'DOWN_Y';
                    gaService.addEvent('RealEstate','QH_Voting_Downvote','QH_Voting_Downvote_click');
                    if(getterSetter.get( 'loginstatus')){
                        let user_Details = getterSetter.get('loginData');
                        let userId = user_Details.data.user.id;
                        if(voteType == 'DOWN_Y') {
                            if($("#votingthankyou").hasClass('voting_thanku')) {
                                $("#votingthankyou").removeClass('voting_thanku')
                            }
                            $('#voting_p').modal('show');
                            $('#votingForm').removeClass('hide');
                        } else { 
                            downVote('Project', globals.projectSnippet.id, userId, voteType);
                        }
                    } else {
                        PUB_SUB.emit('login_modal');
                    }    
                });
            });
        }

        //popup reason cancel and close event capture
        if(noreasonclick.length > 0) {
            Array.from(noreasonclick).forEach(vote => {
                vote.addEventListener('click', function(event) {
                    let voteType = ($('.downvote').hasClass('active')) ? 'DOWN_N' : 'DOWN_Y';
                    if(getterSetter.get( 'loginstatus')){
                        let user_Details = getterSetter.get('loginData');
                        let userId = user_Details.data.user.id;
                        downVote('Project', globals.projectSnippet.id, userId, voteType);
                    }
                });
            });
        }

        //popup reason sumbit event capture
        if(reasonformsubmit) {
            reasonformsubmit.addEventListener('click', function(event) {
                let voteType = ($('.downvote').hasClass('active')) ? 'DOWN_N' : 'DOWN_Y';
                gaService.addEvent('RealEstate','QH_Voting_Downvote_Feedback','QH_Voting_Downvote_Feedback_click');
                if(getterSetter.get( 'loginstatus')){
                    let user_Details = getterSetter.get('loginData');
                    let userId = user_Details.data.user.id;
                    downVote('Project', globals.projectSnippet.id, userId, voteType);
                    $("#votingthankyou").addClass('voting_thanku');
                    $('#votingForm').addClass('hide');
                }
            });
        }

        projectGtm(projectAdResponse);
        // Event handler for click on YES property soldout button
        if (yesPropertyAvlSubmit) {
            yesPropertyAvlSubmit.addEventListener('click', function(event) {
                document.getElementById('propertythankyou').classList.add('voting_thanku');
                propertySoldOut(0).then((res) => {
                    document.getElementById('propertySoldOutBody').classList.add('hide');
                }).catch((err) => {
                    document.getElementById('propertySoldOutBody').classList.add('hide');
                });
            });
        }

        // Event handler for click on NO property soldout button
        if (noPropertyAvlSubmit) {
            noPropertyAvlSubmit.addEventListener('click', function(event) {
                document.getElementById('propertythankyou').classList.add('voting_thanku');
                propertySoldOut(1).then((res) => {
                    document.getElementById('propertySoldOutBody').classList.add('hide');
                }).catch((err) => {
                    document.getElementById('propertySoldOutBody').classList.add('hide');
                });
            });
        }
        
    },

    scrollWindowTo: function (e) {
        e.preventDefault();
        var eID = $(this).data('dest');

        $(this).siblings().removeClass('active').end().addClass('active');
        scrollService(eID);
    },

    openCreateAlert: function () {
        var getTimstamp = localStorage.getItem("contactTimeStamp");
        var urlarray = window.location.pathname.split('/');
        var urlLength = urlarray.length;
        var projectId = urlarray[urlLength - 1];
        if (isNaN(projectId)) {
            if (getTimstamp == null || dayDifference(getTimstamp)>=7) {
                setTimeout(function () {
                    if(!$('#commonpopup').hasClass('in')) {
                        document.getElementById('requirement_popup_contact').click();
                    }
                }, 30000); // 30 sec
            }
        }

    },
    getQueryVariables:(variable)=>{
        if(window.location.search){
            var query = window.location.search.substring(1);
            var pair = query.split('=')
            if(pair[0] === variable){
                document.getElementById('projectContact').click()
            }
        }
    },
    addRequirement: function () {
        var enquiry_phone = get("autofill_no") || '';
        var loggedIn = getCookie("_at");
        setTimeout(function () {
            if (enquiry_phone || loggedIn) {
                //	if(1){
                requirementService.addRequirement(buildLeadPayload());
            }
        }, 60000);

    },

    initUnitContactModal: function () {
        $('#contactModal').on('show.bs.modal', function (e) {
            var unitDetails = $(e.relatedTarget).data('unit-detail');
            if (isSet(unitDetails)) {
                requirementController.renderPropertyInterest(unitDetails, "PROJECT_OFFERS_CONTACT_IN_PROJECT");
            }
            else {
                requirementController.renderPropertyInterestForBrochure(unitDetails, "PROJECT_CONTACT_IN_PROJECT");
            }

        });


        $('#contactModal').on('hide.bs.modal', function (e) {
        });
    },


    formStreetVideoUrl: function (uri) {
        var src = $('#streetVisionUrl').attr('src');
        var projectName = src.substr(src.lastIndexOf("_") + 1);
        var newUrl = "http://quikr_street_video.wonobo.com/video/360player.html?path=" + uri + '_' + projectName;
        $('#streetVisionUrl').attr('src', newUrl);

    },
    initializeLazyMapImages: function () {
        //map needs to be loaded at scroll
        // only if user scrolls till map
        lozad('.lozad', {
            load: function(el) {
                el.src = el.dataset.src;
            }
        }).observe()
    },
    getHashWonbo: function () {
        var carauselData = [];
        var imageLightbox = [];
        var isBannerAvlbl = false;
        var image;
        var panoNav;
        globals.imageUrl.projectPhotos.forEach(function (el) {
            if (el.caption == "Banner_Image") {
                isBannerAvlbl = true;
                image = el.imageUrl.replace('700x700', '1300x1300');
            }
            //el.imageUrl.replace('1300x1300', '700x700');
            //globals.imageUrl.projectPhotos.replace('80x80', '700x700')
        });
        if (isBannerAvlbl) {
            var data = {data: image, type: 'projectImages'};
            carauselData.push(data);
        }
        globals.imageUrl.projectPhotos.forEach(function (el) {
            if (el.caption != "Logo_Image" && el.caption !='Site_Plan') {
                var img = {data: el.imageUrl.replace("700x700", "1300x1300"), type: 'projectImages'};
                var images = {data: img.data.replace("1300x1300", "700x700"), type: 'projectImages'};
                imageLightbox.push(images);
                carauselData.push(img);
            }
        });
        if (carauselData.length == 0) {
            var img = {data: banner, type: 'projectImages'};
            carauselData.push(img);
        }
        var projectHtml = $('#photosSection');
        var htmlData = _.template($('#projectCarouselGalary').html());
        projectHtml.html(htmlData({
            'carauselData': carauselData,
            'streetVideos': globals.streetVideos,
            'imageLength': globals.imageUrl.projectPhotos.length,
            'imageLightbox': imageLightbox
        }));

        $('.excontrol .street_video').click(function () {
            $('.tmpl-project .nav-links li:nth-child(3) a').trigger('click');
        });
    },
    getSimilarProject: function (){
        var indexValue = 0;
        if(globals.projectSnippet && globals.projectSnippet.projectUnits.length){
            globals.projectSnippet.projectUnits.forEach(function(data, index){
                if(index < globals.projectSnippet.projectUnits.length-1 && globals.projectSnippet.projectUnits[index].bedRooms == globals.projectSnippet.projectUnits[index+1].bedRooms){
                   indexValue = index+1; 
                }

            });
            let unitId = globals.projectSnippet.projectUnits[indexValue].id;
            let projectName = globals.projectSnippet.name;
            var request = {
                'id':unitId,
                'seedType':'project',
                'size':9,
                'fields':['name','localityName','bannerImage','builderLogo','minPrice','maxPrice','units','cityName','id'],
                'projectName': projectName
            };
            $.ajax({
                url: "/homes/re2/similarProject",
                method : "POST",
                data: request,
                success: function(data) {
                    $("#SimilarSearchProject").html(data);
                    if(document.getElementById('SimilarSearchProject') && data.trim()!= '') {
                        document.getElementById('showSimilar').classList.remove('hide')
                    }  
                }
            });

        }
        
    },
    initializeDropzone: function(){
        var script_tag = document.createElement('script');
        var script_tag_test = document.createElement('script');
        script_tag.setAttribute("type", "text/javascript");
        script_tag.setAttribute("src", dropzoneJS[0]);
        (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
        if (script_tag.readyState) { //IE specific
            script_tag.onreadystatechange = function () {
                if (script_tag.readyState === "loaded" || script_tag.readyState === "complete") {
                    script_tag_test.setAttribute("type", "text/javascript");
                    script_tag_test.setAttribute("src", dropzoneJS[1]);
                    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag_test);
                }
            };
        }
        else {  //Others
            script_tag.onload = function () {
                script_tag_test.setAttribute("type", "text/javascript");
                script_tag_test.setAttribute("src", dropzoneJS[1]);
                (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag_test);
            };
        }
        if(script_tag_test.readyState){
            script_tag_test.onreadystatechange = function () {
                if (script_tag_test.readyState === "loaded" || script_tag_test.readyState === "complete") {
                    $("div#imgupload").dropzone();
                }
            }
        }
        else{
            script_tag_test.onload = function () {
                $("div#imgupload").dropzone();
            };

        }
    },
    getOffer:function(){
        var getOfferFlag = false;
        var html = '';
        let builderDetails = {};
        if(globals.projectSnippet.address.cityId == 23 ){
            builderDetails.image = `${window.CDNURL}/image/design_cafe.jpeg`
            builderDetails.builderName = 'Design Cafe'
            getOfferFlag = true;
            builderDetails.heading = 'Assistance in Home Interiors?'
        }
        if(getOfferFlag){
            $.ajax({
               url: "/homes/re2/getprojectOffer?aj=1",
               method : "POST",
               data : {projectId: globals.projectSnippet.id},
               success: function(res) {
                    if(res && res.data == 'unpaid'){
                        html = `<div class="offercontact">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 width="72px" height="72px" viewBox="0 0 72 72" enable-background="new 0 0 72 72" xml:space="preserve">
                                <path d="M36.8,69.5h-2.6c-2.9,0-5.2-2.3-5.2-5.2v-0.4c0-2.9,2.3-5.2,5.2-5.2h2.6c2.7,0,4.9,2,5.2,4.6h10.1c2.7,0,4.8-2.2,4.8-4.8
                                V25.7C57,14,47.4,4.5,35.7,4.5S14.5,14,14.5,25.7v4.3c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2V46c0,0.6-0.4,1-1,1h-2.5
                                c-3.2,0-5.7-2.6-5.7-5.7V35c0-3.2,2.6-5.7,5.7-5.7h1.5v-3.5c0-12.8,10.4-23.2,23.2-23.2C48.5,2.5,59,12.9,59,25.7v3.5h2.1
                                c3.2,0,5.7,2.6,5.7,5.7v6.2c0,3.2-2.6,5.7-5.7,5.7H59v11.5c0,3.8-3.1,6.8-6.8,6.8H42C41.5,67.7,39.4,69.5,36.8,69.5z M34.3,60.7
                                c-1.8,0-3.2,1.5-3.2,3.2v0.4c0,1.8,1.5,3.2,3.2,3.2h2.6c1.8,0,3.2-1.5,3.2-3.2v-0.4c0-1.8-1.5-3.2-3.2-3.2H34.3z M59,45h2.1
                                c2.1,0,3.7-1.7,3.7-3.7V35c0-2.1-1.7-3.7-3.7-3.7H59V45z M10.9,31.3c-2.1,0-3.7,1.7-3.7,3.7v6.2c0,2.1,1.7,3.7,3.7,3.7h1.5V31.3
                                H10.9z M53.1,47c-0.6,0-1-0.4-1-1V30.3c0-0.6,0.4-1,1-1s1,0.4,1,1V46C54.1,46.5,53.7,47,53.1,47z M18.6,47c-0.6,0-1-0.4-1-1V30.3
                                c0-0.6,0.4-1,1-1s1,0.4,1,1V46C19.6,46.5,19.1,47,18.6,47z M24.8,13.9c-0.3,0-0.6-0.1-0.8-0.3c-0.4-0.4-0.3-1,0.1-1.4
                                c3.2-2.8,7.3-4.3,11.5-4.3c4.5,0,8.6,1.5,11.7,4.3c0.4,0.4,0.5,1,0.1,1.4c-0.4,0.4-1,0.5-1.4,0.1c-2.8-2.5-6.4-3.8-10.4-3.8
                                c-3.8,0-7.4,1.3-10.2,3.8C25.2,13.9,25,13.9,24.8,13.9z"/>
                            </svg>
                            <div class="rwsection">
                                <div class="col-m-100 col-d-25">
                                    <span class="sssihe">${builderDetails.heading}</span>
                                </div>
                                <div class="col-m-100 col-d-50">
                                    ${res.data == `unpaid` && builderDetails.image?`<div class="bralogo">
                                    <div class="imgbx">
                                        <img src="${builderDetails.image}" alt="Project Offer">
                                    </div>
                                    <div class="mynest">${builderDetails.builderName}</div>
                                </div>`:``}
                                    
                                </div>
                                <div class="col-m-100 col-d-25">
                                    <a href="JavaScript:Void(0)" data-toggle="modal" data-target="#commonpopup" data-heading="${builderDetails.heading}" data-name="${builderDetails.builderName}" data-image="${builderDetails.image}" data-entity="PROJECT_CONTACT_IN_PROJECT" data-subtype="OFFER_CONTACT" class="button-secondary-line button-ptable"> Contact Now</a>
                                </div>
                            </div>
                        </div>`;
                        $("#builderOffer").html(html); 
                    }
                }
           });
        }
    },
        
    getProjectSpecialOffer: function() {
        $.ajax({
           url: "/homes/re2/projectSpecialOffer?aj=1",
           method : "POST",
           data : {projectId: globals.projectSnippet.id},
           success: function(res) {
                var projectSplOffer = res;
                if(projectSplOffer != null) {
                    var offerDetail = '';
                    $.each(projectSplOffer.data, function( key, value ) {
                        var cur_date = new Date(); 
                        var cur_date_time = new Date().getTime();
                        if(parseInt(cur_date_time) >= parseInt(value.startDate) && parseInt(cur_date_time) <= parseInt(value.endDate) ){
                            offerDetail += value.offers
                        }
                    });
                    if(offerDetail){
                        var htmlData = `<div class="rwsection mrtp40">
                        <div class="offersection">
                            <img src="${window.CDNURL}/image/special_offer.png" alt="Project Offer">
                            <div class="projectoffer clearfix">
                                <h2 class="tertiary-heading">Special offer</h2>
                                <div class="project-overview-box"><div class="specialoffer">${offerDetail}</div></div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        </div>`;
                        $("#project_spl_offer").html(htmlData); 
                    }
                } 
            }
        });
    }
};

window.scrollSimilar = function (e) {
    var eID = $(e).data('dest');
    scrollService(eID)
    gaService.addEvent('RealEstate','QH_vap_project','QH_vap_similarProjects_click');
}

window.gtmFlorPlan = function(){
    var gtmData = {
        'event': 'view_flor_plan'
    };
    gtmData['user_db_id'] = getCookie('__u');
    if(connectionCheck()){
        eventTriggerQH(gtmData);
    } else {
        eventTriggeranalatyic(gtmData);
    }
}

function selectTab(e){
    e.preventDefault();
    $(this).siblings().removeClass('active').end().addClass('active');
    var eID = $(this).data('dest');
    $(eID).siblings().removeClass('active').end().addClass('active');
    var lID = $(this).data('light');
    $(lID).siblings().removeClass('active').end().addClass('active');
}

function openWonoboModal(elem) {
    $("#wonoboModal").modal('toggle');
    $("#wonoboFrame").attr('src', elem);
}

function buildLeadPayload() {
    var selectedIsdCode = $('.stdcode select').val();
    var enquiry_name = get("autofill_contactname") || '';
    var enquiry_email = get("autofill_email") || '';
    var enquiry_phone = get("autofill_no") || '';
    const isdPhoneNumber = selectedIsdCode ? selectedIsdCode +  enquiry_phone : enquiry_phone;

    var projectSnippet = globals.projectSnippet;

    var leadPayload = {
        'userName': enquiry_name || '',
        'cityId': projectSnippet.address.cityId || getCookie('prefer_city_id'),
        'phone': isdPhoneNumber || '',
        'emailId': enquiry_email || '',
        "projectIds": "" + projectSnippet.id,
        "localityIds": "" + projectSnippet.localityId,
        "channel": windowWidthService.channel,
        "propertyFor": "buy",
        "postedBy": "INDIVIDUAL",
        "category": projectSnippet.category,
        'source': 'REQUIREMENT_POPUP_AUTO_PROJECT',
        'verifiedStatus': 'NOT_VERIFIED'
    };
    return leadPayload;
}

function brochureHeader(value) {
    var imgurl = value.replace('80x80', '700x700');
    $('#brochure-img11').attr('src', imgurl);
}

window.gaEventSimilarProject = (slot) => {
    gaService.addEvent('link-RealestateResponsiveUI','Project_Real-Estate','click_similar_project_'+slot);
}


$(function () {
    Project.init();
    var datepicker = $("#datepicker");

    $('.media-vtype > li:nth-child(1) > a').addClass('active');
    $('.tab-pane1').addClass('active');

    $('.media-vtype > li:nth-child(1) > a').click(function () {
        $(this).addClass('active');

        $('.media-vtype > li:nth-child(2) > a').removeClass('active');
        $('.tab-pane1').addClass('active');
        $('.tab-pane2').removeClass('active');
        $('.icon_date').hide();
        /*datepicker.datepicker('setDate', null);*/
        datepicker.val('SELECT DATE')

    });

    $('.media-vtype > li:nth-child(2) > a').click(function () {
        $('.media-vtype > li:nth-child(2) > a').addClass('active');
        $('.media-vtype > li:nth-child(1) > a').removeClass('active');
        $('.tab-pane1').removeClass('active');
        $('.icon_date').show();
        datepicker.val('SELECT DATE');
        $('#profile > iframe').attr('src', '');
        //datepicker.datepicker({dateFormat: 'yy-mm-dd'}).datepicker("setDate", new Date()).val();
        currentlocation(new Date());

    });

    var projectheight = $("#locdes");
    if (isSet(projectheight) && projectheight.height() > 189) {
        $(".tmpl-project .project-about .read-more-trigger").show();
    }
    else {
        $(".tmpl-project .project-about .read-more-trigger").hide();
    }


    if (isSet(datepicker)) {
        /*datepicker.datepicker({
            dateFormat: 'yy-mm-dd',
            onSelect: function (selected) {
                $('.media-vtype > li:nth-child(2) > a').addClass('active');

                $('.media-vtype > li:nth-child(1) > a').removeClass('active');
                $('.tab-pane2').addClass('active');
                $('.tab-pane1').removeClass('active');

                currentlocation(selected);
            }
        });*/
    }

    function currentlocation(selected) {
        //console.log(event);
        //console.log('http://ipcamlive.com/player/timelapse.php?alias=ahadeuphoria&disablevideofit=1&date=' + formatDate(selected));

        $('#profile > iframe').attr('src', 'http://ipcamlive.com/player/timelapse.php?alias=ahadeuphoria&disablevideofit=1&date=' + formatDate(selected));
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    $('a#pulltolive[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });

    var imgsrc = $("#brochure-img11").attr('src');
    if (isSet(imgsrc)) {
        imgsrc = imgsrc.replace('80x80', '700x700');
        $('#brochure-img11').attr('src', imgsrc);
    }
});

window.intializeGoogleMapInit = ()=> {
    gaService.addEvent('RealEstate','QH_vap_project','google_map_click');
    window.open('https://www.google.com/maps/place/'+sample.latitude+","+sample.longitude,'_blank');
   
    /*loadMapJs().then((result) => {
        gaService.addEvent('RealEstate','QH_vap_project','google_map_click');
        mapimage.classList.add('hide')
        if (typeof window.google == "object") {
            googleMap.init();
        }
        else {
            setTimeout(function () {
                if (typeof window.google == "object") {
                    googleMap.init();
                }
                else {
                    intializeGoogleMapInit();

                }
            }, 500);
        }
    }).catch((error)=> {
        console.log('link failure!');
    });*/
    
}

window.sharelinkGA = ()=> {
    gaService.addEvent('RealEstate','QH_vap_project','QH_vap_project_share_click');
}

$(document).on("click", "#mapimage", function(e) {
    if(isMobile){
        $("#vap-map-m").addClass("mobile_map");
    }
});

$(document).on("click", "#removemobilemap", function(e) {
    $("#vap-map").removeClass("mobile_map");
    mapimage.classList.remove('hide')
});

if(document.querySelector('#viewmorelessProject')) {
    document.querySelector('#viewmorelessProject').addEventListener('click',function(){
        $('.desinfo').toggleClass('openmore')
    })
}

if(document.querySelector('.vap_hint')) {
    document.getElementsByClassName('vap_hint')[0].addEventListener('click',function(event){
        document.getElementsByClassName('vap_hint')[0].classList.add('hide');
        localStorage.setItem("mobileHint",true);
    });
}



window.viewmorelessSpecs = function() {
    var shSpecs = document.getElementById('viewmorelessSpecs').innerText;
    if(shSpecs =='Less'){
        document.getElementById('viewmorelessSpecs').innerText = 'View More'
        showfullspecs.classList.remove('showmore')
    }else{
        showfullspecs.classList.add('showmore')
        document.getElementById('viewmorelessSpecs').innerText = 'Less'
    }
}

$('#tgdlsbtn').click(function () {
    $(this).addClass('active');
    $('#tgdesbtn').removeClass('active');
    $('#infodls').removeClass('mhide');
    $('#desdlsbox').addClass('mhide');
});
$('#tgdesbtn').click(function () {
    $('#tgdlsbtn').removeClass('active');
    $(this).addClass('active');
    $('#desdlsbox').removeClass('mhide');
    $('#infodls').addClass('mhide');
});


function loadJsCSSfile(filename, filetype) {
    function appendChild(filename, filetype) {
        if (filetype == "js") { //if filename is a external JavaScript file
            var fileref = document.createElement('script');
            fileref.setAttribute("type", "text/javascript");
            fileref.setAttribute("src", filename);
        }
        else if (filetype == "css") { //if filename is an external CSS file
            var fileref = document.createElement("link");
            fileref.setAttribute("rel", "stylesheet");
            fileref.setAttribute("type", "text/css");
            fileref.setAttribute("href", filename);
        }
        if (typeof fileref != "undefined")
            document.getElementsByTagName("head")[0].appendChild(fileref);
    }

    if (typeof filename === "string") {
        appendChild(filename, filetype);
    }
    else {
        for (var i = 0; i < filename.length; i++) {
            appendChild(filename[i], filetype);
        }
    }
}

window.addEventListener('scroll', function () { 
    if(window.pageYOffset >= 620) {
        $(".stickytitle").css({
            'transform': 'translateY(0)'
        });
    } else {
        $(".stickytitle").css({
            'transform': 'translateY(-300%)'
        });
    }
});


$(document).ready(function () {
    $(document).on("scroll", onScroll);
    $('#menu-center li a').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");
        
        $('a').each(function () {
            $(this).removeClass('active');
        })
        $(this).addClass('active');
        var target = this.hash;
        var elem = document.getElementById(target.replace("#",""));
        $('html, body').stop().animate({
            'scrollTop': elem.offsetTop + 120
        }, 500, 'swing', function () {
            $(document).on("scroll", onScroll);
        });
    });
});

function onScroll(event){
    var scrollPos = $(document).scrollTop();
    $('#menu-center a').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
            $('#menu-center li a').removeClass("active");
            currLink.addClass("active");
        }
        else{
            currLink.removeClass("active");
        }
    });
}

function triggerHintForMobile(){
    if (!localStorage.getItem('mobileHint')) {
        document.getElementsByClassName('vap_hint')[0].classList.remove('hide');
    }
}

$(window).on('load', function () {
    Project.initializeDropzone();
    Project.initializeLazyMapImages();
    Project.getProjectSpecialOffer();
    searchSuggest();
    if(!globals.realityproject){
        Project.getSimilarProject();
        if(connectionCheck()){
            adServicesInit();
        }
    }
    getRating(globals.pageType,globals.Id,globals.projectSnippet.name);
    getOverallRating(globals.pageType,globals.Id);

    // Unhide the header
    $(".stickytitle").removeClass('hide');
    /**
    //QPUSH NOTOFICATION
    WebPush.prototype.showGenericPreRequestModal = showGenericPreRequestModal;
    const config = { vertical: 'homes', context: 'notification' };
    const x = new WebPush(config);

    // showGenericPreRequestModal prototype function has to be imported. (See above)
    x.initialize()
        .then(subscription => {
        if (!subscription) {
            // You could either choose to display your own permission UX or directly display the native popup, so trigger requestPermission accordingly
            x.canTriggerCustomDialog()
                .then(() => x.showGenericPreRequestModal())
                .catch(() => {});
            }
        })
        .catch(() => {});
        */
        if (isMobile() && sessionStorage.getItem('vapUrls')!=null) {
            swiping();    
            triggerHintForMobile();
        }
        
    // intit forum Module    
    initForum(globals.projectSnippet.id,'project');
    $('#discussionEntryProject').removeClass("hide");    
    appDnlModalController.init();
});
